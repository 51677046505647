import { useState } from "react";
import { useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import useResponsive from "src/hooks/useResponsive";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify/Iconify";
import Form from "src/pages/sandbox/components/Form";
import History from "src/pages/sandbox/components/History";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    width: "370px",
    background: theme?.palette?.colorTheme?.theme1?.bg1,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const StyledHeader = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme?.palette?.colorTheme?.theme4?.primaryText,
  padding: "10px 24px",
  borderBottom: `1px solid ${theme?.palette?.colorTheme?.theme4?.primaryBorder}`,
  ".MuiButtonBase-root": {
    fontWeight: 400,
    color: "#ffffff",
  },
  ".MuiIconButton-root": {
    paddingRight: 0,
  },
}));

const Sidebar = ({
  open,
  name,
  persona,
  personaOptions,
  queryInProgress,
  onChange,
  onClose,
}) => {
  const mdUp = useResponsive("up", "md");
  const { state } = useLocation();

  const [showChatHistory, setShowChatHistory] = useState(false);

  const handleShowChatHistory = () => setShowChatHistory(true);

  const handleCloseChatHistory = () => setShowChatHistory(false);

  return (
    <Box component="nav">
      <StyledDrawer
        open={open}
        onClose={onClose}
        variant={mdUp ? "persistent" : "temporary"}
        ModalProps={{ keepMounted: true }}
      >
        <StyledHeader>
          <Typography fontSize={16} fontWeight={600}>
            {showChatHistory ? "Chat History" : state?.chatType ?? ""}
          </Typography>
          {showChatHistory ? (
            <IconButton onClick={handleCloseChatHistory}>
              <Iconify icon="gg:close-o" width={20} />
            </IconButton>
          ) : (
            <Button
              id="chat-history-btn"
              startIcon={<Iconify icon="lucide:history" width={18} />}
              onClick={handleShowChatHistory}
            >
              History
            </Button>
          )}
        </StyledHeader>
        <Scrollbar>
          {showChatHistory ? (
            <History moduleId="1" />
          ) : (
            <Form
              name={name}
              persona={persona}
              personaOptions={personaOptions}
              queryInProgress={queryInProgress}
              onChange={onChange}
            />
          )}
        </Scrollbar>
      </StyledDrawer>
    </Box>
  );
};

export default Sidebar;

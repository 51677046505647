import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { applicationConfigTableColumn } from "../../config";
import { fDateTime } from "../../../../utils/formatTime";
import {
  useApplicationSelector,
  fetchAllApplicationAction,
  updateApplicationAction,
} from "../../slices";

import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";
import Iconify from "src/components/iconify";
import SearchField from "src/components/SearchField";
import Table from "src/pages/dashboard/components/Table";
import Tooltip from "src/components/elements/Tooltip";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const AddButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const StyledLink = styled(Link)({
  fontSize: 14,
  fontWeight: 600,
  color: "#005544",
  textDecoration: "none",
  letterSpacing: "0.5px",
});

const WrappedText = styled(Typography)({
  maxWidth: 180,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const StyledMenuItem = styled("div")(({ theme }) => ({
  minWidth: "160px",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "400",
  padding: 4,
}));

const ApplicationConfig = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, meta, status } = useApplicationSelector();

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState({});
  const [filteredApplication, setFilteredApplications] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(fetchAllApplicationAction({ page: 0, size: 10 }));
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const draftApplications = data?.map((item, index) => ({
        id: item?.id,
        sno:
          index + (meta?.page ?? 0) * (meta?.size ?? 0) < 9
            ? `0${index + 1 + (meta?.page ?? 0) * (meta?.size ?? 0)}`
            : index + 1 + (meta?.page ?? 0) * (meta?.size ?? 0),
        name: item?.name ?? "",
        ModuleConfig: item?.ModuleConfig?.module_name,
        ProductCategory: item?.ProductCategory?.map(
          (item) => item?.categoryName
        )?.join(" , "),
        Region: item?.Region?.map((item) => item?.region)?.join(" , "),
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
        data: item,
      }));

      setApplications(draftApplications);
    }
  }, [data]);

  useEffect(() => {
    const draftFilteredApplication = applications?.filter(
      (item) =>
        item?.ModuleConfig?.toLowerCase()?.includes(
          searchString?.toLowerCase()
        ) ||
        item?.ProductCategory?.toLowerCase()?.includes(
          searchString?.toLowerCase()
        )
    );

    setFilteredApplications(draftFilteredApplication ?? []);
  }, [applications, searchString]);

  const handleSearchOnChange = (event) => {
    const { value } = event?.target ?? {};

    setSearchString(value);
  };

  const handleRedirectToCreateApplication = () => navigate("create");

  const handleOnPageChange = (event, newPage) => {
    dispatch(
      fetchAllApplicationAction({
        page: newPage,
        size: meta?.size,
      })
    );
  };

  const handleOnRowsPerPageChange = (event) => {
    const { value: size } = event?.target ?? {};
    dispatch(fetchAllApplicationAction({ page: 0, size }));
  };

  const handleOnMenuClick = (index) => {
    if (index === 0) {
      navigate(`${selectedApplication?.id}/edit`);
    } else if (index === 1) {
      handleAppQuestionSuggestions();
    }
  };

  const handleAppQuestionSuggestions = () => {
    const payload = {
      id: selectedApplication?.id,
      productCategory: selectedApplication?.data?.ProductCategory?.map(
        (_i) => _i?.id
      ),
      moduleId: selectedApplication?.data?.ModuleConfig?.id,
      showQuestionSuggestion:
        !selectedApplication?.data?.showQuestionSuggestion,
    };

    dispatch(updateApplicationAction(payload)).then(() =>
      dispatch(fetchAllApplicationAction({ page: 0, size: 10 }))
    );
  };

  const handleOpenMenu = (event, application) => {
    setSelectedApplication(application);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMoreOptions = (value) => (
    <IconButton
      icon={<Iconify icon="entypo:dots-three-vertical" />}
      onClick={(event) => handleOpenMenu(event, value)}
    />
  );

  const renderProductCategoryLink = (productCategory) => (
    <Tooltip title={productCategory} placement="left-start">
      <WrappedText>{productCategory}</WrappedText>
    </Tooltip>
  );

  const renderRegions = (regions) => (
    <Tooltip title={regions} placement="left-start">
      <WrappedText>{regions}</WrappedText>
    </Tooltip>
  );

  const renderApplication = (module, item) => (
    <StyledLink>{`${module}`}</StyledLink>
  );

  applicationConfigTableColumn?.forEach((column) => {
    if (isEqual(column?.id, "ProductCategory")) {
      column["value"] = renderProductCategoryLink;
    } else if (isEqual(column?.id, "Region")) {
      column["value"] = renderRegions;
    } else if (isEqual(column?.id, "name")) {
      column["value"] = renderApplication;
    } else if (["createdAt", "updatedAt"]?.includes(column?.id)) {
      column["value"] = (value) => fDateTime(value);
    } else if (isEqual("moreOption", column?.id)) {
      column["value"] = (value, item) => renderMoreOptions(item);
    }
  });

  const moreOptions = [
    { label: "EDIT" },
    {
      label: selectedApplication?.data?.showQuestionSuggestion
        ? "HIDE QUESTION SUGGESTIONS"
        : "SHOW QUESTION SUGGESTIONS",
    },
  ];

  return (
    <StyledRoot>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography fontSize={32} fontWeight={600} color="#0000008a">
            Apps
          </Typography>
          <Tooltip
            arrow={true}
            placement="right-start"
            title="Create apps which are combinations of File Categories & Modules. Apps are what is available to the end user. [Example: Category_Product A + Module_Sales Query]"
          >
            <IconButton
              icon={
                <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
              }
            />
          </Tooltip>
        </Stack>
        <AddButton
          variant="contained"
          content="Add Apps"
          onClick={handleRedirectToCreateApplication}
        />
      </Stack>
      <TableContainer>
        <Stack alignItems="flex-end" p={2}>
          <SearchField
            name="searchApplication"
            placeholder="Search by Module Name, File Category Name"
            type="text"
            value={searchString}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Table
          loading={isEqual(status, "loading")}
          data={filteredApplication}
          columns={applicationConfigTableColumn}
          pagination={meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {moreOptions?.map((option, index) => (
          <MenuItem key={option?.label ?? index} onClick={handleClose}>
            <StyledMenuItem onClick={() => handleOnMenuClick(index, option)}>
              {option?.label ?? ""}
            </StyledMenuItem>
          </MenuItem>
        ))}
      </Menu>
    </StyledRoot>
  );
};

export default ApplicationConfig;

import { styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import Iconify from "src/components/iconify/Iconify";
import useResponsive from "src/hooks/useResponsive";

const StyledContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "calc(100% - 32px)",
  height: "calc(100% - 200px)",
  margin: "20px 16px",
  padding: "24px 40px",
  borderRadius: 20,
  backgroundColor: "#000000",
  zIndex: 1,
  overflowY: "scroll",
  ".MuiButtonBase-root": {
    position: "absolute",
    right: 24,
  },
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 40px)",
    height: "calc(100% - 200px)",
    margin: 20,
    padding: "40px 24px 24px",
    ".MuiButtonBase-root": {
      position: "absolute",
      top: 16,
      right: 16,
      padding: 0,
    },
  },
}));

const SandboxInstructions = ({ instructionTemplate, onClose, style }) => {
  const mdUp = useResponsive("up", "md");

  return (
    <StyledContainer style={style}>
      <IconButton id="close-instruction-btn" onClick={onClose}>
        <Iconify
          icon="carbon:close-filled"
          width={mdUp ? 28 : 24}
          height={mdUp ? 28 : 24}
        />
      </IconButton>
      <div dangerouslySetInnerHTML={{ __html: instructionTemplate }} />
    </StyledContainer>
  );
};

export default SandboxInstructions;

import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import { argusQueryVideoThubnail } from "src/constants/constants";
import searchServices from "../../services/search";
import useResponsive from "src/hooks/useResponsive";
import Popup from "src/components/Popup";
import Iconify from "src/components/iconify/Iconify";
import Header from "src/pages/sandbox/components/Header";
import HelpSidebar from "src/pages/sandbox/components/HelpSidebar";

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  height: "100%",
  overflow: "hidden",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.primaryBackground,
}));


const Container = styled("div")(({ theme, open }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 32,
  backgroundColor: "#101010",
  [theme.breakpoints.down("md")]: {
    display: "block",
    overflowY: "scroll",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
    },
  }),
}));



const StyledCardContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: 24,
  padding: "100px 32px",
  marginTop: 64,
  overflowX: "scroll",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    margin: "100px 0 40px 0",
    padding: 0,
  },
}));

const StyledCard = styled(Stack)(({ theme }) => ({
  width: 220,
  minWidth: 220,
  minHeight: 325,
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 8,
  borderRadius: 20,
  cursor: "pointer",
  transition: "background 100ms ease-in-out",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.primaryBackground,
  "& svg": { color: theme?.palette?.colorTheme?.theme4?.primaryText },
  "& span": { color: theme?.palette?.colorTheme?.theme4?.primaryText },
  "& p": { color: theme?.palette?.colorTheme?.theme4?.secondaryText },
  "&:hover": {
    backgroundColor: theme?.palette?.colorTheme?.theme4?.primaryBackground,
  },
  [theme.breakpoints.down("md")]: {
    width: 310,
    minHeight: 195,
  },
}));

const ModeSelectPage = () => {
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    handleFetchModules();
  }, []);

  const handleFetchModules = () => {
    setIsLoading(true);
    searchServices
      .getModules()
      .then((response) => {
        setModules(response?.modules ?? []);
      })
      .catch((error) =>
        setErrorMessage(error?.message ?? "Something went wrong.")
      )
      .finally(() => setIsLoading(false));
  };

  const handleModeOnClick = (module) => {
    if (module?.isActive) {
      navigate("/sandbox/chat", {
        state: { chatType: module.module_name, id: module.id },
      });
      localStorage.setItem("selectedModule", JSON.stringify(module));
    }
  };

  return (
    <>
      <Helmet>
        <title> Argus - Sandbox</title>
      </Helmet>
      <Snackbar
        open={!isEmpty(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert severity="error">{errorMessage ?? ""}</Alert>
      </Snackbar>
      <StyledRoot>
        <Header openNav={openNav} onToggleNav={() => setOpenNav(!openNav)} />
        <HelpSidebar
          open={openNav}
          modules={modules}
          onClick={() => setOpenVideoPlayer(true)}
          onClose={() => setOpenNav(false)}
        />
        <Container open={openNav}>
          <StyledCardContainer
            justifyContent={
              modules?.length > 4 || openNav ? "flex-start" : "center"
            }
          >
            {isLoading ? (
              <Skeleton
                variant="rounded"
                sx={{ bgcolor: "#202225", borderRadius: 2.5 }}
                width={mdUp ? 220 : 310}
                height={mdUp ? 344 : 195}
              />
            ) : (
              modules?.map((module, index) => (
                <StyledCard key={index} id="module-card">
                  <Stack
                    flexDirection="column"
                    pt={4}
                    px={3}
                    gap={1}
                    onClick={() => handleModeOnClick(module)}
                  >
                    <ArrowForwardRoundedIcon />
                    <Typography
                      component="span"
                      fontSize={15}
                      fontWeight={700}
                      color="#D5F2FF"
                    >
                      {module?.module_name ?? ""}
                    </Typography>
                    <Typography
                      component="p"
                      fontSize={13}
                      fontWeight={400}
                      color="#72909E"
                      mt={0.5}
                    >
                      {module?.modeule_desc ?? ""}
                    </Typography>
                  </Stack>
                </StyledCard>
              ))
            )}
          </StyledCardContainer>
        </Container>
      </StyledRoot>
      <Popup open={openVideoPlayer} onClose={() => setOpenVideoPlayer(false)}>
        <iframe
          src="https://player.vimeo.com/video/898215148?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="ArgusQuery_MedtronicSandbox"
          width="100%"
          height="100%"
        />
      </Popup>
    </>
  );
};

export default ModeSelectPage;

import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class SearchService {
  getResources() {
    const option = {
      url: `${baseUrl}/appStartup`,
    };
    return axios.get(option);
  }

  getApplications() {
    const option = {
      url: `${baseUrl}/applications`,
    };

    return axios.get(option);
  }

  getApplicationFiles(applicationId) {
    const option = {
      url: `${baseUrl}/application/files/${applicationId}`,
    };

    return axios.get(option);
  }

  getModules() {
    const option = {
      url: `${baseUrl}/modules`,
    };

    return axios.get(option);
  }

  getModulePersona({ moduleId }) {
    const option = {
      url: `${baseUrl}/module/persona/${moduleId}`,
    };

    return axios.get(option);
  }

  getModuleSuggestions({ moduleId, persona }) {
    const option = {
      url: `${baseUrl}/module/suggestions/${moduleId}?persona=${persona}`,
    };

    return axios.get(option);
  }

  getApplicationSuggestion({ applicationId }) {
    const option = {
      url: `${baseUrl}/application/suggestions/${applicationId}`,
    };

    return axios.get(option);
  }

  getChatHistory(id) {
    const option = {
      url: `${baseUrl}/conversation/getConversationByModule/${id}`,
    };

    return axios.post(option);
  }

  getChatDetails(convoId) {
    const option = {
      url: `${baseUrl}/conversation/getConversation/${convoId}`,
    };

    return axios.post(option);
  }

  getSearchResult(data) {
    const option = {
      url: `${baseUrl}/conversation/search`,
      data,
    };

    return axios.post(option);
  }
  rateResponse(data) {
    const option = {
      url: `${baseUrl}/conversation/addReview`,
      data,
    };

    return axios.put(option);
  }
  addComment(data) {
    const option = {
      url: `${baseUrl}/conversation/addComment`,
      data,
    };

    return axios.put(option);
  }
  getResults(data) {
    const option = {
      url: `${baseUrl}/search`,
      data,
    };

    return axios.post(option);
  }
  deleteChat(convoId) {
    const option = {
      url: `${baseUrl}/conversation/deleteConvo/${convoId}`,
    };

    return axios.delete(option);
  }
  updateChatTitle(convoId, data) {
    const option = {
      url: `${baseUrl}/conversation/updateConvo/${convoId}`,
      data,
    };

    return axios.put(option);
  }
}

const searchService = new SearchService();
export default searchService;

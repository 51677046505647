import { createSlice } from "@reduxjs/toolkit";
import { fetchFilesAction, uploadMultipleFilesAction } from "./filesAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
  meta: {},
};

const loadingActionState = [
  fetchFilesAction.pending,
  uploadMultipleFilesAction.pending,
];
const rejectedActionState = [
  fetchFilesAction.rejected,
  uploadMultipleFilesAction.rejected,
];

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    resetFiles: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFilesAction.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload?.data ?? [];
        state.meta = action?.payload?.meta ?? {};
      })
      .addCase(uploadMultipleFilesAction.fulfilled, (state, action) => {
        state.status = "success";
      });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetFiles } = filesSlice.actions;
export { fetchFilesAction, uploadMultipleFilesAction };
export default filesSlice.reducer;

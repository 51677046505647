import { createSlice } from "@reduxjs/toolkit";
import { loginUserAction } from "./AuthAction";
import { useAuthSelector } from "./AuthSelector";

const initialState = {
  status: "idle",
  error: null,
  data: [],
};

const loadingActionState = [loginUserAction.pending];
const rejectedActionState = [loginUserAction.rejected];

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(loginUserAction.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload?.data ?? {};
    });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetAuth } = authSlice.actions;
export { loginUserAction, useAuthSelector };
export default authSlice.reducer;

import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import Cookies from "universal-cookie";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

import { AppStartupContext } from "src/context/AppStartup";
import { BASEURL } from "src/constants/constants";
import useResponsive from "../../../../hooks/useResponsive";
import dashboardService from "src/services/dashboard";

import Iconify from "src/components/iconify/Iconify";
import IconButton from "src/components/elements/IconButton";
import Button from "src/components/elements/Button";
import TextField from "src/components/elements/Textfield";
import Tooltip from "src/components/elements/Tooltip";
import NavSection from "src/components/nav-section";

const cookies = new Cookies();

const StyledStack = styled(Stack)(({ theme, themename }) => ({
  position: "relative",
  overflowY: "scroll",
  "& .MuiListItemButton-root": {
    color: `${theme.palette.colorTheme[themename]?.primaryTextColor} !important`,
    padding: "10px",
    background: "none !important",
    marginBottom: "8px",
    "&:hover, &.active": {
      background: "rgba(145, 158, 171, 0.25) !important",
    },
  },
  ".section-heading": {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
  },
}));

const ChatButton = styled(Button)(({ theme, themename }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  background: "#3C872D",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "none",
  "&:hover": {
    cursor: "pointer",
    background: "gray",
    color: "#fff",
  },
}));

const StyledSelect = styled(Select)(({ theme, themename }) => ({
  ".MuiSelect-outlined": { fontSize: 14 },
  ".MuiTypography-root": {
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#212B36!important",
  },
}));

const StyledTextField = styled(TextField)(({ theme, themename }) => ({
  ".MuiInputBase-root": {
    fontSize: 14,
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
  },
  ".MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#3c872d!important",
  },
}));

const StyledBox = styled("div")(({ theme, themename }) => ({
  flex: 1,
  fontSize: 14,
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  padding: "4px 8px",
  textAlign: "center",
  borderRadius: 6,
  border: `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
  cursor: "pointer",
  "&:hover , &.active": {
    borderColor: "#3c872d",
    backgroundColor: "#20d13914",
  },
}));

let messageId = 1;
const SideNavV2 = ({
  openNav,
  chatHistory,
  setNavData,
  setInitialPitch,
  onCloseNav,
}) => {
  const aboveTablet = useResponsive("up", "md");
  const location = useLocation();
  const { state } = location;
  const { theme: themename } = useContext(AppStartupContext);

  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState({});
  const [expanded, setExpanded] = useState(-1);
  const [content, setContent] = useState("");
  const [tone, setTone] = useState("");
  const [length, setLength] = useState("");

  useEffect(() => {
    handleFetchTemplates();
  }, []);

  useEffect(() => {
    setInitialPitch([]);
  }, [state?.id]);

  const handleFetchTemplates = async () => {
    try {
      const params = { applicationId: state?.id, moduleId: state?.moduleId };
      const response = await dashboardService?.getTemplates(params);
      setTemplates(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTemplateOnChange = (event, index, template) => {
    if (expanded === index) {
      setTemplate(template);
    } else {
      event?.stopPropagation();
      setExpanded(index);
    }
  };

  const handleOnChange = (event) => {
    const { value } = event?.target ?? {};
    setContent(value);
  };

  const handleGenerateDraft = async () => {
    try {
      setLoading(true);
      let updatedChatConfig = [
        {
          id: messageId++,
          text: content,
          sender: "user",
        },
        {
          sender: "bot",
          text: "",
          question: content,
          isDummy: true,
        },
      ];
      setInitialPitch(updatedChatConfig);

      fetchEventSource(`${BASEURL}/generate-sales-pitch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
        },
        body: JSON.stringify({
          questions: template?.questons?.map((item) => ({
            question: item?.question ?? "",
            applicationId: item?.applicationId,
          })),
          answerTone: tone?.toLowerCase(),
          answerLength: length?.toLowerCase(),
          content,
          moduleId: state?.moduleId,
          applicationId: state?.id,
          templateId: template?.id,
        }),
        openWhenHidden: true,
        onmessage(event) {
          const data = JSON.parse(event?.data ?? "") || undefined;

          if (data && data?.text) {
            updatedChatConfig.splice(updatedChatConfig.length - 1, 1);
            updatedChatConfig = [
              ...updatedChatConfig,
              {
                id: data?.answerId,
                question: content,
                text: data?.text,
                sender: data?.sender,
                rating: data?.rating,
                source: data?.source,
                commentAdded: data?.commentAdded,
                comment: "",
                convoId: data?.convoId,
              },
            ];

            setInitialPitch(updatedChatConfig);
          }
        },
        onclose() {
          setLoading(false);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowHistory = async () => {
    try {
      setShowHistory(true);
      setNavData([]);
      const history = await dashboardService.getTemplateHistory(state?.id);
      setNavData(history?.data ?? []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewChatOnClick = () => {
    setShowHistory(false);
    setInitialPitch([]);
  };

  const width = location.pathname === "/search_user/chat_type" ? 324 : 280;

  return (
    <Box component="nav" sx={{ flexShrink: { lg: 0 } }}>
      <Drawer
        open={openNav}
        onClose={onCloseNav}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: drawerPaperProps(themename, width) }}
        variant={aboveTablet ? "persistent" : "temporary"}
      >
        {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        )}
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          sx={{
            py: 1.09,
            px: 2,
            borderBottom: (theme) =>
              `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <Tooltip title="History">
              <IconButton
                icon={
                  <Iconify
                    icon="lucide:history"
                    width={22}
                    color={themename === "dark" ? "#d9d9d9" : "#343434"}
                  />
                }
                onClick={handleShowHistory}
              />
            </Tooltip>
          </Stack>
          <ChatButton
            content="New Chat"
            endIcon={<Iconify icon="lucide:edit" width={16} />}
            onClick={handleNewChatOnClick}
          />
        </Stack>
        {showHistory ? (
          <Stack>
            {/* <StyledStack flex={1} themename={themename}>
              <Typography
                fontSize={12}
                fontWeight={400}
                py={1}
                sx={{
                  paddingTop: 3,
                  paddingLeft: 2,
                  paddingRight: 2,
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.secondaryTextColor,
                }}
              >
                Template History
              </Typography>
              <NavSection
                themename={themename}
                isSearchUser={true}
                data={chatHistory}
                setNavData={setNavData}
              />
            </StyledStack> */}
            {chatHistory?.length > 0 && (
              <StyledStack flex={1} themename={themename}>
                <Typography
                  fontSize={12}
                  fontWeight={400}
                  py={1}
                  sx={{
                    paddingTop: 3,
                    paddingLeft: 2,
                    paddingRight: 2,
                    color: (theme) =>
                      theme.palette.colorTheme[themename]?.secondaryTextColor,
                  }}
                >
                  Chat History
                </Typography>
                <NavSection
                  themename={themename}
                  isSearchUser={true}
                  data={chatHistory}
                  setNavData={setNavData}
                />
              </StyledStack>
            )}
          </Stack>
        ) : (
          <Stack maxHeight="calc(100% - 58px)">
            <Stack sx={{ m: 2 }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                py={1}
                sx={{
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.secondaryTextColor,
                }}
              >
                Template
              </Typography>
              <StyledSelect
                size="small"
                themename={themename}
                name="template"
                displayEmpty
                renderValue={(selected) => (
                  <Typography fontSize={14} fontWeight={400}>
                    {isEmpty(selected)
                      ? "Select Templates"
                      : selected?.name ?? ""}
                  </Typography>
                )}
                value={template}
              >
                {templates?.map((template, index) => (
                  <MenuItem key={template?.id ?? index} sx={{ padding: 0 }}>
                    <Accordion
                      sx={{ width: "100%" }}
                      expanded={expanded === index}
                      onClick={(event) =>
                        handleTemplateOnChange(event, index, template)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          width: "100%",
                          "& .MuiAccordionSummary-content": { m: 0 },
                        }}
                      >
                        <Typography fontSize={14}>
                          {template?.name ?? ""}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "0 24px" }}>
                        <span className="flex flex-column">
                          {template?.questons?.map((question, index) => (
                            <Typography
                              fontSize={13}
                              mb={2}
                              maxWidth="200px"
                              sx={{ whiteSpace: "normal" }}
                            >
                              {question?.question ?? ""}
                            </Typography>
                          ))}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  </MenuItem>
                ))}
              </StyledSelect>
            </Stack>
            <Stack sx={{ m: 2 }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                py={1}
                sx={{
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.secondaryTextColor,
                }}
              >
                Write about
              </Typography>
              <StyledTextField
                themename={themename}
                size="small"
                name="prompt"
                placeholder="Sales pitch for 500 beds in India....."
                multiline={true}
                rows={6}
                value={content}
                onChange={handleOnChange}
              />
            </Stack>
            <Stack sx={{ m: 2 }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                py={1}
                sx={{
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.secondaryTextColor,
                }}
              >
                Tone
              </Typography>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mt={0.5}
                gap={1.5}
              >
                {["Professional", "Casual"]?.map((item) => (
                  <StyledBox
                    className={tone === item ? "active" : ""}
                    themename={themename}
                    onClick={() => setTone(item)}
                  >
                    {item}
                  </StyledBox>
                ))}
              </Stack>
            </Stack>
            <Stack sx={{ m: 2 }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                py={1}
                sx={{
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.secondaryTextColor,
                }}
              >
                Length
              </Typography>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                mt={0.5}
                gap={1.5}
              >
                {["Short", "Medium", "Long"]?.map((item) => (
                  <StyledBox
                    className={length === item ? "active" : ""}
                    themename={themename}
                    onClick={() => setLength(item)}
                  >
                    {item}
                  </StyledBox>
                ))}
              </Stack>
            </Stack>
            <Stack justifyContent="flex-end" flex={1} px={2} py={3}>
              <Button
                variant="outlined"
                content="Generate Draft"
                sx={{
                  fontWeight: 400,
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.secondaryTextColor,
                  border: (theme) =>
                    `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
                  borderRadius: "50px",
                  "&:hover": {
                    borderColor: "#3c872d",
                    backgroundColor: "#20d13914",
                  },
                }}
                disabled={loading || isEmpty(tone) || isEmpty(length)}
                onClick={handleGenerateDraft}
              />
            </Stack>
          </Stack>
        )}
      </Drawer>
    </Box>
  );
};

export default SideNavV2;

const drawerPaperProps = (themeName, width) => ({
  width,
  background: (theme) => theme.palette.colorTheme[themeName]?.secondaryBgColor,
});

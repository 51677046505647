import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "src/services/dashboard";

export const createApplicationAction = createAsyncThunk(
  "dashboard/application/create",
  async (payload) => await dashboardService.createApplication(payload)
);

export const fetchAllApplicationAction = createAsyncThunk(
  "dashboard/application/fetch",
  async (params) => await dashboardService.getApplication(params)
);

export const updateApplicationAction = createAsyncThunk(
  "dashboard/application/update",
  async (payload) => await dashboardService.updateApplication(payload)
);

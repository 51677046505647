import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import rootReducer from "src/slices";

const persistConfig = {
  key: "app",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const rootStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }), // Disable serializable check for Thunk
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default rootStore();

import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
// @mui
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//helpers
import useResponsive from "../../../../hooks/useResponsive";
import searchService from "../../../../services/search";
import { convertFullNameToAbbrevate, convertKiloBytes } from "src/utils/helper";
import { AppStartupContext } from "src/context/AppStartup";
// components
import Iconify from "src/components/iconify/Iconify";
import NavSection from "../../../../components/nav-section";
import Tooltip from "src/components/elements/Tooltip";
import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";

const StyledStack = styled(Stack)(({ theme, themename }) => ({
  position: "relative",
  overflowY: "scroll",
  "& .MuiListItemButton-root": {
    color: `${theme.palette.colorTheme[themename]?.primaryTextColor} !important`,
    padding: "10px",
    background: "none !important",
    marginBottom: "8px",
    "&:hover, &.active": {
      background: "rgba(145, 158, 171, 0.25) !important",
    },
  },
  ".section-heading": {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
  },
}));

const StyledFileItem = styled("div")(({ theme, themename }) => ({
  padding: "10px 16px",
  cursor: "pointer",
  ".name": {
    fontSize: 12,
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
  },
  ".size": {
    fontSize: 10,
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
    whiteSpace: "nowrap",
  },
}));

const ChatButton = styled(Button)(({ theme, themename }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  background: "#3C872D",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "none",
  "&:hover": {
    cursor: "pointer",
    background: "gray",
    color: "#fff",
  },
}));

const Divider = styled("div")(({ theme, themename }) => ({
  width: "100%",
  height: 1,
  background: theme.palette.colorTheme[themename]?.borderColor,
}));

const ModuleCard = styled("div")(({ theme, themename }) => ({
  minWidth: 260,
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  letterSpacing: 1,
  padding: "10px 24px",
  borderRadius: 8,
  border: `2px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
  background: theme.palette.colorTheme[themename]?.primaryBgColor,
  cursor: "pointer",
  transition: "all 300ms ease-in-out",
  "&.active,&:hover": {
    fontWeight: 600,
    color: theme.palette.colorTheme[themename]?.secondaryTextColor,
    background: theme.palette.colorTheme[themename]?.secondaryBgColor,
    borderColor: "#3C872D",
  },
}));

const SideNavV1 = ({
  openNav,
  onCloseNav,
  chatHistory,
  moduleList,
  selectedModule,
  setSelectedModule,
  setNavData,
  chatClicked,
}) => {
  const aboveTablet = useResponsive("up", "md");
  const location = useLocation();
  const { state } = location;
  const { theme: themename } = useContext(AppStartupContext);

  const [showHistory, setShowHistory] = useState(true);
  const [applicationId, setApplicationId] = useState(state?.id || 1);
  const [applicationFiles, setApplicationFiles] = useState([]);

  useEffect(() => {
    if (state?.id) {
      setApplicationId(state?.id);
    }
  }, [state]);

  useEffect(() => {
    if (!isEmpty(moduleList) && !selectedModule) {
      setSelectedModule(moduleList?.find((_i) => _i)?.id);
    }
  }, [moduleList]);

  useEffect(() => {
    if (applicationId && !showHistory) {
      handleFetchApplicationFile();
    }
  }, [applicationId, showHistory]);

  const handleFetchApplicationFile = () => {
    searchService
      ?.getApplicationFiles(applicationId)
      .then((response) => {
        const draftFiles = [];
        response?.forEach((item) => {
          const categoryIndex = draftFiles?.findIndex(
            (file) => file?.category === item?.ProductCategory?.categoryName
          );

          if (categoryIndex < 0) {
            draftFiles?.push({
              category: item?.ProductCategory?.categoryName,
              files: [item],
            });
          } else {
            draftFiles?.[categoryIndex]?.files?.push(item);
          }
        });
        setApplicationFiles(draftFiles ?? []);
      })
      .catch((errorResponse) => console.log(errorResponse));
  };

  const handleOnExpandFile = () => {
    const url = `${window.location.origin}/files/${applicationId}`;

    window.open(url, "_blank");
  };

  const width = location.pathname === "/search_user/chat_type" ? 324 : 280;
  const isModuleView = location.pathname === "/search_user/chat_type";
  return (
    <Box component="nav" sx={{ flexShrink: { lg: 0 } }}>
      <Drawer
        open={openNav}
        onClose={onCloseNav}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: drawerPaperProps(themename, width) }}
        variant={aboveTablet ? "persistent" : "temporary"}
      >
        {isModuleView ? (
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              padding: 1,
              minHeight: 56,
              borderBottom: (theme) =>
                `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
            }}
          >
            <Typography
              fontSize={20}
              fontWeight={600}
              sx={{
                color: (theme) =>
                  theme.palette.colorTheme[themename]?.secondaryTextColor,
              }}
            >
              MODULES
            </Typography>
          </Stack>
        ) : (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            sx={{
              py: 1.09,
              px: 2,
              borderBottom: (theme) =>
                `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Tooltip title="Files">
                <IconButton
                  icon={
                    <Iconify
                      icon="ph:files"
                      width={22}
                      color={themename === "dark" ? "#d9d9d9" : "#343434"}
                    />
                  }
                  onClick={() => setShowHistory(false)}
                />
              </Tooltip>
              <Tooltip title="History">
                <IconButton
                  icon={
                    <Iconify
                      icon="lucide:history"
                      width={22}
                      color={themename === "dark" ? "#d9d9d9" : "#343434"}
                    />
                  }
                  onClick={() => setShowHistory(true)}
                />
              </Tooltip>
            </Stack>
            <ChatButton
              content="New Chat"
              endIcon={<Iconify icon="lucide:edit" width={16} />}
              onClick={chatClicked}
            />
          </Stack>
        )}
        {isModuleView ? (
          <Stack pt={9} px={4} gap={2}>
            {moduleList?.map((module, index) => (
              <ModuleCard
                key={module?.id ?? index}
                themename={themename}
                className={module?.id === selectedModule ? "active" : ""}
                onClick={() => setSelectedModule(module?.id)}
              >
                {module?.module_name ?? ""}
              </ModuleCard>
            ))}
          </Stack>
        ) : showHistory ? (
          <StyledStack flex={1} themename={themename}>
            <NavSection
              themename={themename}
              isSearchUser={true}
              data={chatHistory}
              setNavData={setNavData}
            />
          </StyledStack>
        ) : (
          <Stack flexDirection="column">
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              px={1}
              py={2}
            >
              <Typography
                sx={{
                  pl: 1,
                  fontSize: 16,
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.primaryTextColor,
                }}
              >
                Files:
              </Typography>
              <IconButton
                icon={
                  <Iconify
                    icon="akar-icons:link-out"
                    color={themename === "dark" ? "#d9d9d9" : "#343434"}
                    width={14}
                  />
                }
                onClick={handleOnExpandFile}
              />
            </Stack>
            {applicationFiles?.length > 0 && (
              <StyledStack flex={1} flexDirection="column">
                {applicationFiles?.map((categoryFiles, index) => (
                  <Accordion
                    sx={(themes) => ({
                      background:
                        themes.palette.colorTheme[themename]?.primaryBgColor,
                    })}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        my: 0,
                        minHeight: "auto",
                        "&.Mui-expanded": { minHeight: "auto" },
                      }}
                    >
                      <Typography
                        fontSize={14}
                        sx={{
                          color: (theme) =>
                            theme.palette.colorTheme[themename]
                              ?.secondaryTextColor,
                        }}
                      >
                        {categoryFiles?.category ?? ""}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 0 }}>
                      {categoryFiles?.files?.map((file) => (
                        <StyledFileItem themename={themename}>
                          <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                            gap={1}
                          >
                            <Stack
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Iconify
                                icon="mage:file-2-fill"
                                color={
                                  themename === "dark" ? "#d9d9d9" : "#343434"
                                }
                                width={14}
                              />
                              <span className="name">
                                <Tooltip title={file?.docName ?? ""}>
                                  {convertFullNameToAbbrevate(
                                    file?.docName ?? ""
                                  )}
                                </Tooltip>
                              </span>
                            </Stack>
                            <span className="size">
                              {convertKiloBytes(file?.size ?? 0)}
                            </span>
                          </Stack>
                        </StyledFileItem>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </StyledStack>
            )}
          </Stack>
        )}
      </Drawer>
    </Box>
  );
};

export default SideNavV1;

const drawerPaperProps = (themeName, width) => ({
  width,
  background: (theme) => theme.palette.colorTheme[themeName]?.secondaryBgColor,
});

import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "src/services/dashboard";

export const fetchAllCategoryAction = createAsyncThunk(
  "dashboard/category/fetch",
  async (params) => await dashboardService.getCategory(params)
);

export const createNewCategoryAction = createAsyncThunk(
  "dashboard/category/create",
  async (payload) => await dashboardService.createCategory(payload)
);

export const updateCategoryAction = createAsyncThunk(
  "dashboard/category/update",
  async (payload) => await dashboardService.updateCategory(payload)
);

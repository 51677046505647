import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import MaterialRadio from "@mui/material/Radio";

const RadioButton = ({
  name,
  size,
  label,
  labelPlacement,
  disabled,
  value,
  checked,
  onChange,
  ...props
}) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={
        <MaterialRadio
          name={name}
          size={size}
          disabled={disabled}
          checked={checked}
          value={value}
          onChange={onChange}
          {...props}
        />
      }
    />
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
};

RadioButton.defaultProps = {
  name: "",
  size: "medium",
  label: "",
  labelPlacement: "right",
  disabled: false,
  onChange: () => null,
};

export default RadioButton;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  createNewRegionAction,
  useRegionsSelector,
  updateRegionAction,
} from "../../slices";

import Iconify from "src/components/iconify/Iconify";
import Button from "src/components/elements/Button";
import TextField from "src/components/elements/Textfield";
import Loader from "src/pages/dashboard/components/Loader";

const StyledRoot = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "40px 32px 32px 32px",
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: "#005544",
  lineHeight: "22px",
  padding: "12px",
  borderBottom: "4px solid #005544",
}));

const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": { fontSize: 14, width: 300 },
});

const BackButton = styled(Button)({
  fontWeight: 400,
  color: "#40414E",
});

const SubmitButton = styled(Button)({
  minWidth: 130,
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const Container = styled(Stack)(({theme})=>({
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
[theme.breakpoints.down("sm")]:{
  flexDirection:"column",
  alignItems:"flex-start",
},
}))

const CreateRegion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const regions = useRegionsSelector();

  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!!id && regions?.data?.length === 0) {
      navigate("/dashboard/region");
    } else if (!!id && regions?.data?.length > 0) {
      const selectedRegion = regions?.data?.find(
        (item) => parseInt(item?.id) === parseInt(id)
      );
      setRegion(selectedRegion?.region ?? "");
    }
  }, []);

  const handleRegionOnChange = (event) => {
    const { value } = event?.target ?? {};
    setRegion(value);
  };

  const handleOnSubmit = async () => {
    try {
      if (isEmpty(region)) {
        setError("Please enter region");
        return;
      }
      setLoading(true);
      if (!id) {
        await dispatch(createNewRegionAction({ region }));
      } else {
        const selectedRegion = regions?.data?.find(
          (item) => parseInt(item?.id) === parseInt(id)
        );
        const payload = {
          regionId: id,
          isActive: selectedRegion?.isActive,
          region,
        };
        await dispatch(updateRegionAction(payload));
      }
      navigate("/dashboard/region");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => navigate("/dashboard/region");

  return (
    <StyledRoot>
      {loading && <Loader />}
      <Stack flexDirection="column" alignItems="flex-start" gap={1} mb={5}>
        <BackButton
          variant="text"
          startIcon={<Iconify icon="ep:back" />}
          content="Go back"
          onClick={handleGoBack}
        />
        <StyledTitle>Create Teams</StyledTitle>
      </Stack>
      <Stack
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        px={3.75}
        gap={6}
      >
        <Stack flexDirection="column" alignItems="center">
          <Container  gap={3}>
            <Typography fontSize={14} fontWeight={400}>
              Team Name
            </Typography>
            <StyledTextField
              id="region"
              type="text"
              name="region"
              size="small"
              placeholder="Type Team Name"
              value={region}
              error={!isEmpty(error)}
              helperText={error}
              onChange={handleRegionOnChange}
            />
          </Container>
        </Stack>
        <Stack alignItems="flex-end">
          <SubmitButton
            size="medium"
            content={!!id ? "Update" : "Submit"}
            onClick={handleOnSubmit}
          />
        </Stack>
      </Stack>
    </StyledRoot>
  );
};

export default CreateRegion;

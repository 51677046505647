import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
// import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar } from "@mui/material";
// mock
// import account from '../../../_mock/account';
// hooks
// import loginService from '../../../services/loginService';
import useResponsive from "../../../hooks/useResponsive";
// components
// import Logo from '../../../components/logo';
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// const StyledAccount = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: Number(theme.shape.borderRadius) * 1.5,
//   backgroundColor: alpha(theme.palette.grey[500], 0.12),
// }));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  chatHistory: PropTypes.array,
};

export default function Nav({ openNav, onCloseNav, chatHistory, children }) {
  // const { pathname } = useLocation();
  // const [account, setAccount] = useState([]);
  // const isDesktop = useResponsive('up', 'lg');
  const location = useLocation();
  const { state } = location;
  const aboveTablet = useResponsive("up", "md");
  const [moduleId, setModuleId] = useState(state?.id || 1);

  // useEffect(() => {
  //   loadData();
  //   if (openNav) {
  //     onCloseNav();
  //   }
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);
  // const loadData = () => {
  //   const data = loginService.getparamsFromCookie('jwt_session');
  //   if (data) {
  //     const accDetails = {};
  //     accDetails.username = loginService.getparamsFromCookie('username');
  //     accDetails.photoURL = loginService.getparamsFromCookie('avatar');
  //     setAccount(accDetails);
  //   }
  // };

  useEffect(() => {
    // console.log('here', state, moduleId);
    setModuleId(state?.id || 1);
    if (state?.chatModuleChanged) {
      setModuleId(state?.id);
    }
  }, [state]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        "& .MuiListItemButton-root": {
          color: "#FFF !important",
          padding: "10px",
          background: "none !important",
          marginBottom: "8px",
          "&:hover, &.active": {
            background: "rgba(145, 158, 171, 0.25) !important",
          },
        },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box> */}

      <Box sx={{ mb: 3, mx: 2.5, pt: 1 }}>
        {/* <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account.username}
              </Typography>
            </Box>
          </StyledAccount>
        </Link> */}
        {children}
      </Box>
      <NavSection data={chatHistory} isSearchUser={true} />
      <Box sx={{ flexGrow: 1 }} />
      {/* <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '28px',
          fontWeight: '600',
        }}
      >
        <span
          style={{
            width: '99%',
            textAlign: 'center',
            background: '#FFF',
          }}
        >
          Coming Soon !
        </span>
      </div> */}
    </Scrollbar>
  );

  const themeName = `theme${moduleId > 13 ? moduleId % 13 : moduleId}`;

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        // width: { lg: NAV_WIDTH },
      }}
    >
      {/* {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : ( */}
      <Drawer
        open={openNav}
        onClose={onCloseNav}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: NAV_WIDTH,
            background: (theme) => theme.palette.colorTheme[themeName]?.bg1,
          },
        }}
        variant={aboveTablet ? "persistent" : "temporary"}
      >
        {renderContent}
      </Drawer>
      {/* )}*/}
    </Box>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "src/services/dashboard";

export const fetchAllRegionsAction = createAsyncThunk(
  "dashboard/regions/fetch",
  async (params) => await dashboardService.getRegion(params)
);

export const createNewRegionAction = createAsyncThunk(
  "dashboard/regions/create",
  async (payload) => await dashboardService.createRegion(payload)
);

export const updateRegionAction = createAsyncThunk(
  "dashboard/regions/update",
  async (payload) => await dashboardService.updateRegion(payload)
);

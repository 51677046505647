import { useEffect, useState } from "react";
import {
  NavLink as RouterLink,
  useLocation,
  useParams,
} from "react-router-dom";
import { isEmpty, isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import ConfirmationPopup from "src/pages/sandbox/components/ConfirmationPopup";

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  padding: 10,
  ".MuiIconButton-root": {
    color: "#fff",
  },
  "&:hover,&.active": {
    background: "rgba(145, 158, 171, 0.25)",
  },
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  minWidth: "36px",
  marginRight: "8px",
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledNavItemText = styled(ListItemText)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const CSSTextField = styled(TextField)({
  flex: 1,
  "& input": {
    fontSize: 14,
    color: "#FFFFFF",
    padding: "4px 8px",
  },
  "& fieldset": {
    border: "2px solid #FFF !important",
  },
});

const StyledSkeleton = styled(Skeleton)(() => ({
  background: "rgba(145, 158, 171, 0.25)",
}));

const ChatHistoryItem = ({ chat, onUpdate, onDelete }) => {
  const location = useLocation();
  const params = useParams();
  const path = `/sandbox/chat/${chat?.id}`;
  const chatType = location?.state?.chatType;
  const moduleId = location?.state?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [convoName, setConvoName] = useState(
    chat?.convoName ?? chat?.Questions[0]?.question ?? ""
  );

  useEffect(() => {
    setEditMode(false);
  }, [params?.convoId]);

  const handleToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleResetEdit = () => {
    setConvoName(chat?.convoName ?? chat?.Questions[0]?.question ?? "");
    setEditMode(false);
  };

  const handleOnUpdate = () => {
    if (!isEmpty(convoName)) {
      setIsLoading(true);
      onUpdate(chat?.id, convoName).then((response) => {
        if (response?.status === 200) {
          setEditMode(false);
        }
        setIsLoading(false);
      });
    }
  };

  const handleOnSubmit = () => {
    setOpenConfirmation(false);
    onDelete(chat?.id);
  };

  return (
    <>
      {isLoading ? (
        <StyledSkeleton
          animation="wave"
          variant="rounded"
          width="100%"
          height="48px"
        />
      ) : (
        <StyledNavItem
          key={chat?.id}
          id="historical-chat-link"
          component={RouterLink}
          to={path}
          state={{ chatType, id: moduleId }}
        >
          <StyledNavItemIcon>
            <SmsOutlinedIcon fontSize="small" />
          </StyledNavItemIcon>
          {editMode ? (
            <CSSTextField
              value={convoName}
              onChange={(event) => setConvoName(event?.target?.value)}
            />
          ) : (
            <StyledNavItemText disableTypography primary={convoName} />
          )}
          {isEqual(location?.pathname, path) && (
            <Stack flexDirection="row">
              {!editMode ? (
                <>
                  <IconButton onClick={handleToggleEditMode}>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => setOpenConfirmation(true)}>
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton onClick={handleOnUpdate}>
                    <CheckOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={handleResetEdit}>
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Stack>
          )}
        </StyledNavItem>
      )}
      <ConfirmationPopup
        open={openConfirmation}
        title="Delete Chat"
        description=" Do you really want to delete this chat?"
        onClose={() => setOpenConfirmation(false)}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

export default ChatHistoryItem;

import { createSlice } from "@reduxjs/toolkit";
import {
  createApplicationAction,
  updateApplicationAction,
  fetchAllApplicationAction,
} from "./applicationAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
  meta: {},
};

const loadingActionState = [
  createApplicationAction.pending,
  updateApplicationAction.pending,
  fetchAllApplicationAction.pending,
];
const rejectedActionState = [
  createApplicationAction.rejected,
  updateApplicationAction.rejected,
  fetchAllApplicationAction.rejected,
];

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    resetApplication: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createApplicationAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(updateApplicationAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(fetchAllApplicationAction.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload?.data ?? [];
        state.meta = action?.payload?.meta ?? {};
      });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetApplication } = applicationSlice.actions;
export {
  fetchAllApplicationAction,
  createApplicationAction,
  updateApplicationAction,
};
export default applicationSlice.reducer;

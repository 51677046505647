import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { convertKiloBytes } from "../../utils/helper";
import { fDateTime } from "../../utils/formatTime";
import searchService from "../../services/search";
import Table from "../../pages/dashboard/components/Table";
import Loader from "../../pages/dashboard/components/Loader";

const ApplicationFiles = () => {
  const [loading, setLoading] = useState(true);
  const { applicationId } = useParams();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (applicationId) {
      handleFetchApplicationFile();
    }
  }, [applicationId]);

  const handleFetchApplicationFile = () => {
    searchService
      ?.getApplicationFiles(applicationId)
      .then((response) => {
        const draftFiles = [];
        response?.forEach((item) => {
          const categoryIndex = draftFiles?.findIndex(
            (file) => file?.category === item?.ProductCategory?.categoryName
          );

          if (categoryIndex < 0) {
            draftFiles?.push({
              category: item?.ProductCategory?.categoryName,
              files: [item],
            });
          } else {
            draftFiles?.[categoryIndex]?.files?.push(item);
          }
        });
        setFiles(draftFiles ?? []);
      })
      .catch((errorResponse) => console.log(errorResponse))
      .finally(() => setLoading(false));
  };

  const column = [
    { id: "sNo", label: "Sr. No.", sort: true },
    { id: "docName", label: "File Name", sort: true },
    { id: "size", label: "Size", sort: true, value: convertKiloBytes },
    { id: "status", label: "Status" },
    {
      id: "createdAt",
      label: "Uploaded At",
      sort: true,
      value: (value) => fDateTime(value),
    },
  ];

  const renderSequencedData = (files) =>
    files?.map((file, index) => ({ sNo: index + 1, ...file }));

  return (
    <>
      {loading && <Loader />}
      <Stack flex={1} flexDirection="column">
        {files?.map((categoryFiles, index) => (
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                my: 0,
                minHeight: "auto",
                "&.Mui-expanded": { minHeight: "auto" },
              }}
            >
              <Typography fontSize={24}>
                {categoryFiles?.category ?? ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <Table
                columns={column}
                data={renderSequencedData(categoryFiles?.files ?? [])}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </>
  );
};

export default ApplicationFiles;

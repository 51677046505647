import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { isEmpty, isEqual } from "lodash";
// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// helpers
import { acceptableFileFormat, uploadedFileTableColumn } from "../../config";
import {
  convertFullNameToAbbrevate,
  convertKiloBytes,
} from "../../../../utils/helper";
import { fDateTime } from "src/utils/formatTime";
import dashboardService from "../../../../services/dashboard";
import {
  fetchAllCategoryAction,
  useCategorySelector,
  useResourceSelector,
} from "../../slices";
// component
import Iconify from "src/components/iconify/Iconify";
import Table from "../../components/Table";
import Button from "../../../../components/elements/Button";
import IconButton from "../../../../components/elements/IconButton";
import Select from "../../../../components/elements/Select";
import Loader from "../../components/Loader";
import Tooltip from "src/components/elements/Tooltip";
import CircularProgress from "src/components/elements/Progress/CircularProgress";
import FileLabelPopup from "../../components/FileLabelPopup";

const StyledRoot = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "40px 32px 32px",
  overflowY: "scroll",
}));

const FileUploadSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 24,
  marginBottom: 32,
  borderRadius: 6,
  gap: 16,
  boxShadow: "0px 4px 9px 0px #171A1F1C",
});

const UploadSection = styled("div")(() => ({
  flex: 1.3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 6,
  padding: "32px 24px 24px",
  border: "2px dashed #BCC1CA",
  backgroundColor: "#FAFAFB",
}));

const UploadedSection = styled("div")(() => ({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxHeight: 262,
  overflowY: "scroll",
  padding: "32px 24px 24px",
  gap: 24,
  borderRadius: 6,
  backgroundColor: "#FAFAFB",
}));

const StyledFileInput = styled("input")(() => ({
  display: "none",
}));

const BrowseButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: "#005544",
  "&:hover": { background: "transparent" },
}));

const CategorySelect = styled(Select)({
  minWidth: 260,
});

const PrimaryButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  padding: "6px 32px",
  background: "#005544",
  boxShadow: "none",
  "&:hover": { background: "#005544" },
});

const CancelButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  padding: "6px 32px",
  color: "#005544",
  boxShadow: "none",
  "&:hover": { background: "transparent" },
});

const StatusText = styled(Typography)({
  fontSize: 14,
  letterSpacing: 0.5,
  textAlign: "center",
  textTransform: "uppercase",
  "&.INIT": {
    color: "#0078FF",
  },
  "&.PROCESSING": {
    color: "#d6b542",
  },
  "&.DONE": {
    color: "green",
  },
});

const TableWrapper = styled("div")({
  minHeight: 400,
});

const StyledCircularProgress = styled(CircularProgress)({});

const FileUploadPage = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const categories = useCategorySelector();
  const resources = useResourceSelector();

  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("INIT");
  const [openFileLabelPopup, setOpenFileLabelPopup] = useState(false);

  useEffect(() => {
    dispatch(fetchAllCategoryAction());
  }, []);

  const handleOnDrop = (event) => {
    event?.preventDefault();
    const { files: droppedFiles } = event?.dataTransfer ?? {};

    if (droppedFiles?.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleFileChange = (event) => {
    const { files: selectedFiles } = event?.target ?? {};

    if (selectedFiles?.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleResetState = () => {
    fileInputRef.current["value"] = null;
    setFiles([]);
    setUploadStatus("INIT");
  };

  const handleRemoveFile = (file) => {
    setFiles((prevFiles) =>
      prevFiles?.filter((prevFile) => !isEqual(file?.name, prevFile?.name))
    );
  };

  const handleCategoryOnChange = (event) => setCategoryId(event?.target?.value);

  const handleOnSubmit = (tags = "") => {
    if (isEqual(uploadStatus, "DONE")) {
      handleResetState();
      return;
    }
    if (!categoryId) return;
    if (!tags) return setOpenFileLabelPopup(true);

    const uploadFiles = [...files];

    setUploadStatus("PROGRESS");
    setOpenFileLabelPopup(false);
    handleUploadFiles(uploadFiles, categoryId, tags);
  };

  const handleUploadFiles = async (uploadFiles = [], categoryId, tags = "") => {
    if (uploadFiles?.length === 0) {
      setUploadStatus("DONE");
      return;
    }
    const file = uploadFiles?.shift();
    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("tags", tags);
      formData.append("productCategoryId", categoryId);

      const payload = { formData, onUploadProgress };
      const { data } = await dashboardService.uploadFile(payload);

      setUploadedFiles((prevFile) => [...prevFile, { ...(data ?? {}) }]);
    } catch (error) {
      const { data = "" } = error?.response ?? {};
      const draftFiles = [...files];
      const index = files?.findIndex((item) => isEqual(file?.name, item?.name));
      draftFiles[index]["error"] = data;

      setFiles(draftFiles);
    } finally {
      handleUploadFiles(uploadFiles, categoryId);
    }
  };

  const onUploadProgress = (event, formData) => {
    const { loaded = 0, total = 0 } = event ?? {};
    let draftFiles = [...files];

    for (const [key, value] of formData?.entries()) {
      if (isEqual(key, "file")) {
        const { name = "" } = value ?? {};
        const index = files?.findIndex((file) => isEqual(file?.name, name));
        draftFiles[index]["loaded"] =
          loaded === total ? draftFiles?.[index]?.size : loaded;
        draftFiles[index]["isUploaded"] = loaded === total;

        setFiles(draftFiles);
      }
    }
  };

  const handleProcessOnClick = async (documentId) => {
    try {
      setIsLoading(true);
      const { data } = await dashboardService.processFile(documentId);
      const draftUploadedFiles = uploadedFiles?.map((file) => ({
        ...file,
        status:
          file?.id === documentId ? data?.document?.status ?? "" : file?.status,
      }));

      setUploadedFiles(draftUploadedFiles);
    } catch (error) {
      console.log(error?.response?.data ?? "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const calculatePercentage = ({ loaded = 0, size = 0 }) =>
    Math.round((loaded * 100) / size) ?? 0;

  const renderProductCategory = (productCategory) =>
    productCategory?.map((item) => ({
      label: item?.categoryName,
      value: item?.id,
    }));

  const renderStatus = (value) => (
    <StatusText className={value?.toUpperCase()}>{value}</StatusText>
  );

  const renderAction = (value, item) => (
    <PrimaryButton
      size="small"
      variant="contained"
      content="Process"
      onClick={() => handleProcessOnClick(item?.id)}
    />
  );

  uploadedFileTableColumn?.forEach((column) => {
    if (isEqual(column?.id, "name")) {
      column["value"] = (val) => (
        <Typography fontWeight={600} color="#005544">
          {val}
        </Typography>
      );
    } else if (isEqual(column?.id, "extension")) {
      column["value"] = (val, item) => item?.docName?.split(".")?.pop() ?? "";
    } else if (isEqual(column?.id, "productCategoryId")) {
      column["value"] = (val) =>
        categories?.data?.find((cat) => cat?.id === val)?.categoryName ?? "";
    } else if (isEqual(column?.id, "uploadedAt")) {
      column["value"] = () => fDateTime(new Date());
    } else if (isEqual(column?.id, "status")) {
      column["value"] = (val, item) => renderStatus(val, item);
    } else if (isEqual(column?.id, "action")) {
      column["value"] = (val, item) => renderAction(val, item);
    } else if (isEqual(column?.id, "size")) {
      column["value"] = (value) => convertKiloBytes(value);
    }
  });

  const catDetails =
    categories?.data?.find((cat) => cat?.id === categoryId) ?? {};

  const sequencedTableData = uploadedFiles?.map((item, index) => ({
    ...item,
    sno: index < 10 ? `0${index + 1}` : index,
  }));

  return (
    <>
      {(isEqual(categories?.status, "loading") || isLoading) && <Loader />}
      <StyledRoot>
        <Stack flexDirection="row" alignItems="center" gap={1} mb={3}>
          <Typography fontSize={24}>
            {`File Upload${
              !isEmpty(catDetails) ? ` - ${catDetails?.categoryName ?? ""}` : ""
            }`}
          </Typography>
          <Tooltip
            arrow={true}
            placement="right-start"
            title="Upload data files and assign to created categories. Categories have to be created before file uploads."
          >
            <IconButton
              icon={
                <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
              }
            />
          </Tooltip>
        </Stack>
        <FileUploadSection>
          <Stack flexDirection={{ base: "column", md: "row" }} gap={4}>
            <UploadSection
              onDragOver={(event) => event?.preventDefault()}
              onDrop={isEqual(uploadStatus, "INIT") && handleOnDrop}
            >
              <StyledFileInput
                ref={fileInputRef}
                type="file"
                multiple={true}
                accept={acceptableFileFormat}
                onChange={isEqual(uploadStatus, "INIT") && handleFileChange}
              />
              <img
                src="/assets/icons/upload-icon.svg"
                alt="file-upload-icon"
                width="60px"
                height="60px"
              />
              <Typography fontSize={20} color="#171A1F" my={1}>
                Drop Files Here
              </Typography>
              <Typography fontSize={14} color="#6E7787">
                Support pdf, doc, xlsx, pptx files.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#C8C8C8"
                my={0.5}
              >
                or
              </Typography>
              <BrowseButton
                variant="text"
                content="Browse files"
                onClick={() =>
                  isEqual(uploadStatus, "INIT") &&
                  fileInputRef?.current?.click()
                }
              />
            </UploadSection>
            <UploadedSection>
              <CategorySelect
                id="file-product-category"
                name="fileProductCategory"
                placeholder="Select Files Category"
                options={renderProductCategory(categories?.data ?? [])}
                value={categoryId}
                onChange={handleCategoryOnChange}
              />
              {files?.map((file, index) => (
                <Stack
                  key={`${file?.name}-${index}`}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <StyledCircularProgress
                      value={calculatePercentage(file)}
                      label={`${calculatePercentage(file)}%`}
                    />
                    <Stack flexDirection="column">
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color="#40414E"
                      >
                        {convertFullNameToAbbrevate(file?.name ?? "")}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color="#B8B8BF"
                      >
                        {`${convertKiloBytes(
                          file?.loaded ?? 0
                        )} / ${convertKiloBytes(file?.size)}`}
                      </Typography>
                      {file?.error && (
                        <Typography fontSize={10} color="red">
                          {file?.error ?? ""}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  {!file?.isUploaded ? (
                    <Iconify
                      icon="ic:round-clear"
                      width={18}
                      color="#DADADA"
                      onClick={() => handleRemoveFile(file)}
                    />
                  ) : !file?.error ? (
                    <Iconify icon="ic:round-check" width={20} color="#0078FF" />
                  ) : (
                    <Iconify icon="ic:round-warning" width={20} color="red" />
                  )}
                </Stack>
              ))}
            </UploadedSection>
          </Stack>

          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            <CancelButton
              variant="text"
              content="Cancel"
              disabled={isEqual(uploadStatus, "PROGRESS")}
              onClick={handleResetState}
            />
            <PrimaryButton
              variant="contained"
              content={isEqual(uploadStatus, "DONE") ? "Done" : "Upload"}
              disabled={isEqual(uploadStatus, "PROGRESS")}
              onClick={() => handleOnSubmit()}
            />
          </Stack>
        </FileUploadSection>
        {uploadedFiles?.length > 0 && (
          <TableWrapper>
            <Table
              data={sequencedTableData}
              columns={uploadedFileTableColumn}
            />
          </TableWrapper>
        )}
        <FileLabelPopup
          open={openFileLabelPopup}
          title="Please Enter File Label"
          onClose={() => setOpenFileLabelPopup(false)}
          onConfirm={handleOnSubmit}
        />
      </StyledRoot>
    </>
  );
};

export default FileUploadPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { TextField, Button, Grid, Typography, Snackbar, FormControl } from "@mui/material";
import userService from "src/services/userService";

const UserForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    console.log(formData);
    try {
      const data = await userService.createUser(formData);
      console.log(data);
      navigate("/users/list", { replace: true });
      setMsg("User added successfully");
      setOpen(true);
    } catch (error) {
      setDisable(false);
      console.log(error);
      setMsg(error?.message);
      setOpen(true);
    }
  };

  return (
    <>
      <Helmet>
        <title> Add User | Connect </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Add User
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                disabled={disable}
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default UserForm;

import Cookies from "universal-cookie";
import { Navigate } from "react-router-dom";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

const cookies = new Cookies();

const PrivateRoute = ({ redirect, children }) => {
  const authToken = cookies.get("jwt_session");

  return !isEmpty(authToken) ? children : <Navigate to={redirect} />;
};

PrivateRoute.propTypes = {
  redirect: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirect: "/login",
};

export default PrivateRoute;

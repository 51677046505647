import { combineReducers } from "@reduxjs/toolkit";

import dashboardReducer from "src/pages/dashboard/slices";
import authReducer from "src/slices/Authentication/AuthSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
});

export * from "./Authentication/AuthSlice";
export default rootReducer;

import { useMemo, useState } from "react";
import { isEqual } from "lodash";
import Proptypes from "prop-types";
//@mui
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import Stack from "@mui/material/Stack";

import Iconify from "../../iconify";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) =>
  isEqual(order, "desc")
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
};

const StyledTableContainer = styled(TableContainer)(() => ({
  height: "100%",
  position: "relative",
  overflow: "scroll",
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  fontWeight: 600,
  color: "#757575",
  padding: "8px 0",
  whiteSpace: "nowrap",
  backgroundColor: "#FFFFFF",
  borderBottom: "1px solid #bdbdbd",
}));

const StyledTableBodyCell = styled(TableCell)(() => ({
  fontWeight: 400,
  color: "#000000",
  borderBottom: "1px solid #E4E4E4",
  backgroundColor: "transparent",
  whiteSpace: "nowrap",
  padding: "8px 24px",
  height: 54,
}));

const LoaderContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const MaterialTable = ({
  loading,
  data,
  columns,
  stickyHeader,
  defaultEmptyValue,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const onRequestSort = (event, property) => {
    const isAsc = isEqual(orderBy, property) && isEqual(order, "asc");
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getDefaultValue = (value, defaultEmptyValue) => {
    if (!value) return defaultEmptyValue;
    else return value;
  };

  const getValue = (column, row) => {
    const { id, value } = column;
    const val = row?.[id];
    return value ? value(val, row) : val;
  };

  const sortedRows = useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data]
  );

  return (
    <StyledTableContainer>
      {loading && (
        <LoaderContainer>
          <Iconify icon="eos-icons:bubble-loading" width={32} />
        </LoaderContainer>
      )}
      <Table stickyHeader={stickyHeader}>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <StyledTableHeadCell
                key={column?.id ?? index}
                align={column?.numeric ? "right" : column?.align ?? "left"}
                sortDirection={isEqual(orderBy, column?.id) ? order : false}
                sx={{ width: column?.width }}
              >
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ borderRight: "1px solid #dcdcdc", px: 3 }}
                >
                  <TableSortLabel
                    hideSortIcon={!column?.sort}
                    active={isEqual(orderBy, column?.id)}
                    direction={isEqual(orderBy, column?.id) ? order : "asc"}
                    onClick={column?.sort && createSortHandler(column?.id)}
                  >
                    {column?.label ?? ""}
                  </TableSortLabel>
                </Stack>
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows?.length > 0 || loading ? (
            sortedRows?.map((row, index) => (
              <TableRow
                sx={{
                  backgroundColor: index % 2 === 0 ? "#ffffff" : "#00000008",
                }}
              >
                {columns?.map((column) => (
                  <StyledTableBodyCell
                    align={column?.numeric ? "right" : column?.align ?? "left"}
                  >
                    {getDefaultValue(getValue(column, row), defaultEmptyValue)}
                  </StyledTableBodyCell>
                ))}
              </TableRow>
            ))
          ) : (
            <LoaderContainer>No Records</LoaderContainer>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

MaterialTable.propTypes = {
  stickyHeader: Proptypes.bool,
  defaultEmptyValue: Proptypes.string,
};

MaterialTable.defaultProps = {
  stickyHeader: true,
  defaultEmptyValue: "",
};

export default MaterialTable;

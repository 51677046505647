import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Select,
  FormControl,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { appTypes } from "src/constants/constants";
import { AppStartupContext } from "src/context/AppStartup";
import useResponsive from "src/hooks/useResponsive";
import loginService from "src/services/loginService";
import searchService from "src/services/search";

import Iconify from "src/components/iconify";
import Button from "src/components/elements/Button";

const HEADER_MOBILE = 48;
const HEADER_DESKTOP = 56;
const cookies = new Cookies();

const StyledRoot = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, themename, width }) => ({
  background: theme.palette.colorTheme[themename]?.primaryBgColor,
  boxShadow: "none",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${width}px)`,
      marginLeft: `280px`,
    },
  }),
}));

const StyledToolbar = styled(Toolbar)(({ theme, themename }) => ({
  minHeight: HEADER_MOBILE,
  borderBottom: `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
  boxShadow: `0px 2px 16px 6px ${theme.palette.colorTheme[themename]?.shadowColor}`,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 4.5),
  },
}));

const HeadlineText = styled("div")(({ theme, themename }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  fontFamily: '"Helvetica-Regular", Helvetica',
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  fontSize: "22px",
  fontWeight: 400,
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
  },
}));

const MainLogo = styled("img")(({ theme }) => ({
  height: "33px",
  width: "33.5px",
  objectFit: "contain",
  marginRight: "12px",
}));

const TextWrapper = styled("p")(({ theme }) => ({
  margin: 0,
  letterSpacing: 0,
  lineHeight: "normal",
  whiteSpace: "nowrap",
  fontFamily: '"Helvetica-Regular", Helvetica',
  color: "#3C872D",
  fontSize: 16,
  fontWeight: 600,
}));

const StyledSelect = styled(Select)(({ theme, themename }) => ({
  minWidth: "148px",
  height: "32px",
  borderRadius: "9px",
  border: `1px solid ${theme.palette.colorTheme[themename]?.successColor}`,
  background: theme.palette.colorTheme[themename]?.primaryBgColor,
  color: theme.palette.colorTheme[themename]?.successColor,
  fontFamily: "Helvetica",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "0.4px",
  "& .MuiSelect-select": {
    lineHeight: "20px",
  },
  "& .MuiSelect-icon": {
    color: theme.palette.colorTheme[themename]?.successColor,
  },
}));

const StyledMenuItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "400",
  minWidth: "160px",
  "& svg": {
    color: "#F60808",
    width: "24px",
    height: "24px",
    marginRight: "16px",
  },
}));

const AccountUser = styled(Stack)(({ theme }) => ({
  alignSelf: "flex-start",
}));

const Header = ({ openNav, resources, onOpenNav, setOpenInstructions }) => {
  const mdUp = useResponsive("up", "md");
  const location = useLocation();
  const navigate = useNavigate();
  const { theme: themename } = useContext(AppStartupContext);
  const { state } = location;
  console.log("localStorage", localStorage?.getItem("auth"));
  const authDetails = localStorage?.getItem("auth")
    ? JSON.parse(localStorage?.getItem("auth") ?? "")
    : {};

  const [applicationId, setApplicationId] = useState(state?.id ?? 1);
  const [allApplications, setAllApplications] = useState([]);
  const [applications, setApplications] = useState([]);
  const [open, setOpen] = useState(
    location.pathname === "/search_user/chat_type" ? false : true
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setApplicationId(state?.id ?? 1);
  }, [state]);

  useEffect(() => {
    getApplications();
  }, []);

  useEffect(() => {
    if (!!state?.moduleId && allApplications?.length > 0) {
      const draftApplications = allApplications?.filter(
        (app) => app?.ModuleConfig?.id === state?.moduleId
      );
      setApplications(draftApplications);
    }
  }, [state?.moduleId, allApplications]);

  const handleChange = (event) => {
    const selectedApplication = applications?.find(
      (item) => item?.id === event?.target?.value
    );

    setApplicationId(selectedApplication?.id ?? 1);
    cookies.set("chat_type", selectedApplication?.ModuleConfig?.module_name);
    navigate("/search_user/chat", {
      state: {
        id: selectedApplication?.id ?? 1,
        chatType: `${selectedApplication?.ProductCategory?.categoryName}: ${selectedApplication?.ModuleConfig?.module_name}`,
        moduleId: selectedApplication?.ModuleConfig?.id,
        chatModuleChanged: true,
      },
    });
  };

  const getApplications = async () => {
    try {
      const applicationResponse = await searchService.getApplications();
      setAllApplications(applicationResponse);
    } catch (error) {
      console.log(error);
    }
  };

  const logOutUser = (e) => {
    cookies.remove("chat_data");
    loginService.dologout();
    setTimeout(() => {
      navigate("/login");
    }, 500);
  };

  const navigateToHome = () => {
    toggleNav();
    navigate("/search_user");
  };

  const toggleNav = () => {
    setOpen(!open);
    onOpenNav();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const width = location.pathname === "/search_user/chat_type" ? 324 : 280;
  const brandLogos = resources?.brandLogos?.[authDetails?.accountId];
  const brandLogo = mdUp ? brandLogos?.["l"] : brandLogos?.["sm"];
  return (
    <StyledRoot
      position="fixed"
      open={openNav}
      width={width}
      themename={themename}
    >
      <StyledToolbar themename={themename}>
        <IconButton onClick={toggleNav} sx={{ mr: mdUp ? 1 : 1 }}>
          <Iconify icon="mingcute:menu-fill" width={27} />
        </IconButton>
        <HeadlineText themename={themename} onClick={navigateToHome}>
          <MainLogo src="/assets/logo.png" alt="argus logo" />
          {mdUp && <TextWrapper>ArgusQuery.ai</TextWrapper>}
        </HeadlineText>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          mr={2}
          spacing={{
            xs: 0.5,
            sm: 2,
            md: 2,
            lg: 2,
          }}
        >
          {location.pathname !== "/search_user/chat_type" && (
            <>
              <IconButton onClick={() => setOpenInstructions(true)}>
                <Iconify
                  icon="octicon:question-24"
                  color={themename === "dark" ? "#fff" : "#000"}
                  width={20}
                />
              </IconButton>
              <FormControl sx={{ m: 1, minWidth: mdUp ? 120 : 100 }}>
                <StyledSelect
                  themename={themename}
                  sx={{
                    fontSize: !mdUp ? "13px" : "14px",
                    height: !mdUp ? "24px" : "32px",
                    ...(!mdUp && {
                      "&.MuiInputBase-root": {
                        maxWidth: "80px",
                        ...(!mdUp && { minWidth: 100 }),
                      },
                      "& .MuiSelect-select": {
                        paddingRight: "0px !important",
                        marginRight: 4,
                      },
                    }),
                  }}
                  value={applicationId}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {applications.map((item, index) => {
                    return (
                      <MenuItem
                        disabled={!item?.ModuleConfig?.isActive}
                        id={item.id}
                        value={item?.id}
                        sx={{ fontSize: 14 }}
                      >
                        {item?.name ?? ""}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </FormControl>
            </>
          )}
        </Stack>
        <AccountUser flexDirection="row" alignItems="stretch">
          <Button
            sx={{
              background: themename === "dark" ? "#fff" : "grey",
              borderRadius: 0,
              borderBottomLeftRadius: "12px",
              "&:hover": {
                background: "#fff",
              },
              ...(!mdUp && { padding: "2px 0", minWidth: 50 }),
            }}
            variant="text"
            startIcon={
              <Iconify
                color={themename === "dark" ? "grey" : "white"}
                icon="carbon:user-avatar-filled"
                width={30}
                height={30}
              />
            }
            content={
              mdUp && (
                <Stack flexDirection="column" alignItems="flex-start">
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    sx={{ color: themename === "dark" ? "#1e1e1e" : "#fff" }}
                  >
                    {authDetails?.name}
                  </Typography>
                </Stack>
              )
            }
            endIcon={
              mdUp && (
                <Iconify
                  icon="formkit:down"
                  color={themename === "dark" ? "grey" : "white"}
                />
              )
            }
            onClick={handleOpenMenu}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: mdUp ? "0 28px" : "0 12px",
              background: "#3C872D",
              borderBottomRightRadius: "12px",
            }}
          >
            {!isEmpty(brandLogo) ? (
              <img
                src={brandLogo}
                alt="schneider-logo"
                width={mdUp ? 80 : 24}
              />
            ) : (
              <Typography fontSize={16} fontWeight={600}>
                {authDetails?.Account?.account_name ?? ""}
              </Typography>
            )}
          </div>
        </AccountUser>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <StyledMenuItem onClick={(ev) => logOutUser(ev)}>
              <PowerSettingsNewIcon fontSize="28" />
              <span>Sign Out</span>
            </StyledMenuItem>
          </MenuItem>
        </Menu>
      </StyledToolbar>
    </StyledRoot>
  );
};

Header.propTypes = {
  openNav: PropTypes.bool,
  onOpenNav: PropTypes.func,
};

export default Header;

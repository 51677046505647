// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'User Display Configurations',
    path: '/configurations',
    icon: icon('ic_lock'),
  },
  {
    title: 'Prompt Configurations',
    path: '/prompt_config',
    icon: icon('ic_lock'),
  },

  // {
  //   title: 'dashboard',
  //   path: '/dashboard',
  //   icon: icon('ic_analytics'),
  // },
  // {
  //   title: 'report',
  //   path: '/reports',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'user',
    path: '/users',
    icon: icon('ic_user'),
  },
  {
    title: 'File Upload',
    path: '/upload/file',
    icon: icon('ic_cart'),
  },
  
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;

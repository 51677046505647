import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

export default class Axios {
  get(options) {
    options.method = "get";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            cookies.remove("jwt_session");
            window.location.replace(`${window.location.origin}/login`);
          }
          reject(err);
        });
    });
  }

  post(options) {
    console.log("options", options);
    options.method = "POST";
    options.headers = options?.headers ? options.headers : this.getHeaders();
    options.timeout = 1000 * 500;
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            cookies.remove("jwt_session");
            window.location.replace(`${window.location.origin}/login`);
          }
          reject(err);
        });
    });
  }

  upload(options) {
    options.method = "POST";
    options.headers = this.getHeaders();
    options.headers["content-type"] = "multipart/form-data";
    return new Promise((resolve, reject) => {
      axios
        .post(options?.url, options?.formData, {
          ...options,
          onUploadProgress: (event) => {
            options?.onUploadProgress(event, options?.formData);
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            cookies.remove("jwt_session");
            window.location.replace(`${window.location.origin}/login`);
          }
          reject(err);
        });
    });
  }

  patch(options) {
    options.method = "PATCH";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            cookies.remove("jwt_session");
            window.location.replace(`${window.location.origin}/login`);
          }
          reject(err);
        });
    });
  }

  put(options) {
    options.method = "PUT";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            cookies.remove("jwt_session");
            window.location.replace(`${window.location.origin}/login`);
          }
          reject(err);
        });
    });
  }

  delete(options) {
    options.method = "DELETE";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            cookies.remove("jwt_session");
            window.location.replace(`${window.location.origin}/login`);
          }
          reject(err);
        });
    });
  }

  getHeaders() {
    console.log(this);
    return {
      Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
    };
  }
}

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { regionTableColumn } from "../../config";
import { fDateTime } from "../../../../utils/formatTime";
import {
  useRegionsSelector,
  fetchAllRegionsAction,
  updateRegionAction,
  resetRegions,
} from "../../slices";

import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";
import RadioButton from "src/components/elements/RadioButton";
import Iconify from "src/components/iconify";
import SearchField from "src/components/SearchField";
import Table from "src/pages/dashboard/components/Table";
import Tooltip from "src/components/elements/Tooltip";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const AddButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const StyledMenuItem = styled("div")(({ theme }) => ({
  minWidth: "160px",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "400",
  padding: 4,
}));

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const Region = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const regions = useRegionsSelector();

  const [searchString, setSearchString] = useState("");
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(fetchAllRegionsAction({ page: 0, size: 10 }));

    return () => dispatch(resetRegions());
  }, []);

  useEffect(() => {
    const draftFilteredRegions = regions?.data?.filter((item) =>
      item?.region?.toLowerCase()?.includes(searchString?.toLowerCase())
    );

    setFilteredRegions(draftFilteredRegions ?? []);
  }, [regions, searchString]);

  const handleSearchOnChange = (event) => {
    const { value } = event?.target ?? {};

    setSearchString(value);
  };

  const handleRedirectToCreateRegion = () => navigate("create");

  const handleUpdateRegionStatus = async (item) => {
    const payload = { regionId: item?.id, isActive: !item?.isActive };
    await dispatch(updateRegionAction(payload));
    await dispatch(fetchAllRegionsAction());
  };

  const handleOpenMenu = (event, region) => {
    setSelectedRegion(region);
    setAnchorEl(event.currentTarget);
  };

  const handleOnMenuClick = (index) => {
    if (index === 0) {
      navigate(`${selectedRegion?.id}/edit`);
    }
  };

  const handleOnPageChange = (event, newPage) => {
    dispatch(
      fetchAllRegionsAction({ page: newPage, size: regions?.meta?.size })
    );
  };

  const handleOnRowsPerPageChange = (event) => {
    dispatch(
      fetchAllRegionsAction({ page: 0, size: parseInt(event.target.value, 10) })
    );
  };

  const renderMoreOptions = (value) => (
    <IconButton
      icon={<Iconify icon="ph:dots-three-outline-thin" />}
      onClick={(event) => handleOpenMenu(event, value)}
    />
  );

  const renderRadioButton = (value, item) => (
    <RadioButton
      sx={{ margin: 0 }}
      size="small"
      name="region"
      value={item?.id}
      checked={value}
      onClick={() => handleUpdateRegionStatus(item)}
    />
  );

  regionTableColumn?.forEach((column) => {
    if (isEqual(column?.id, "isActive")) {
      column["value"] = renderRadioButton;
    } else if (["createdAt"]?.includes(column?.id)) {
      column["value"] = (value) => fDateTime(value);
    } else if (isEqual("moreOption", column?.id)) {
      column["value"] = (value, item) => renderMoreOptions(item);
    }
  });

  const sequencedTableData = filteredRegions?.map((item, index) => ({
    ...item,
    sno:
      index + (regions?.meta?.page ?? 0) * (regions?.meta?.size ?? 0) < 9
        ? `0${
            index + 1 + (regions?.meta?.page ?? 0) * (regions?.meta?.size ?? 0)
          }`
        : index + 1 + (regions?.meta?.page ?? 0) * (regions?.meta?.size ?? 0),
  }));

  const moreOptions = [{ label: "EDIT" }];

  return (
    <StyledRoot>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack flexDirection="row" alignItems="center">
          <Typography fontSize={32} fontWeight={600} color="#0000008a">
            Teams
          </Typography>
          <Tooltip
            arrow={true}
            placement="right-start"
            title="Create teams to control access to Apps. [example: By functional area – Executive Team gets access to all Apps while the Sales Team gets access only to the Sales related Apps]."
          >
            <IconButton
              icon={
                <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
              }
            />
          </Tooltip>
        </Stack>
        <AddButton
          variant="contained"
          content="Add Team"
          onClick={handleRedirectToCreateRegion}
        />
      </Stack>
      <TableContainer>
        <Stack alignItems="flex-end" p={2}>
          <SearchField
            name="searchRegion"
            type="text"
            placeholder="Search by Team Name"
            value={searchString}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Table
          loading={isEqual(regions?.status, "loading")}
          data={sequencedTableData}
          columns={regionTableColumn}
          pagination={regions?.meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {moreOptions?.map((option, index) => (
          <MenuItem
            key={option?.label ?? index}
            onClick={() => setAnchorEl(null)}
          >
            <StyledMenuItem onClick={() => handleOnMenuClick(index, option)}>
              {option?.label ?? ""}
            </StyledMenuItem>
          </MenuItem>
        ))}
      </Menu>
    </StyledRoot>
  );
};

export default Region;

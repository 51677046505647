import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { productCategoryTableColumn } from "../../config";
import {
  useCategorySelector,
  fetchAllCategoryAction,
  resetCategory,
} from "../../slices";

import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";
import Iconify from "src/components/iconify";
import SearchField from "src/components/SearchField";
import Table from "src/pages/dashboard/components/Table";
import Tooltip from "src/components/elements/Tooltip";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const AddButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const StyledLink = styled(Link)({
  fontSize: 14,
  fontWeight: 600,
  color: "#005544",
  textDecoration: "none",
  letterSpacing: "0.5px",
  padding: "0 8px",
});

const StyledMenuItem = styled("div")(({ theme }) => ({
  minWidth: "160px",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "400",
  padding: 4,
}));

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const ProductCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const category = useCategorySelector();

  const [searchString, setSearchString] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(fetchAllCategoryAction({ page: 0, size: 10 }));

    return () => dispatch(resetCategory());
  }, []);

  useEffect(() => {
    const draftFilteredCategory = category?.data?.filter((item) =>
      item?.categoryName?.toLowerCase()?.includes(searchString?.toLowerCase())
    );

    setFilteredCategory(draftFilteredCategory ?? []);
  }, [category, searchString]);

  const handleSearchOnChange = (event) => {
    const { value } = event?.target ?? {};

    setSearchString(value);
  };

  const handleRedirectToCreateCategory = () => navigate("create");

  const handleOnPageChange = (event, newPage) => {
    dispatch(
      fetchAllCategoryAction({ page: newPage, size: category?.meta?.size })
    );
  };

  const handleOnRowsPerPageChange = (event) => {
    dispatch(
      fetchAllCategoryAction({
        page: 0,
        size: parseInt(event.target.value, 10),
      })
    );
  };

  const handleOnMenuClick = (index) => {
    if (index === 0) {
      navigate(`${selectedCategory?.id}/edit`);
    }
  };

  const handleOpenMenu = (event, region) => {
    setSelectedCategory(region);
    setAnchorEl(event.currentTarget);
  };

  const renderMoreOptions = (value) => (
    <IconButton
      icon={<Iconify icon="ph:dots-three-outline-thin" />}
      onClick={(event) => handleOpenMenu(event, value)}
    />
  );

  const renderProductCategoryLink = (value) => <StyledLink>{value}</StyledLink>;

  const renderRegions = (regions) => (
    <Tooltip
      title={regions?.map((region) => region?.region)?.join(" ,")}
      placement="left-start"
    >
      {regions?.map((region) => region?.region)?.join(" ,")}
    </Tooltip>
  );

  productCategoryTableColumn?.forEach((column) => {
    if (isEqual(column?.id, "categoryName")) {
      column["value"] = renderProductCategoryLink;
    } else if (isEqual(column?.id, "Region")) {
      column["value"] = renderRegions;
    } else if (isEqual("moreOption", column?.id)) {
      column["value"] = (value, item) => renderMoreOptions(item);
    }
  });

  const sequencedTableData = filteredCategory?.map((item, index) => ({
    ...item,
    sno:
      index + (category?.meta?.page ?? 0) * (category?.meta?.size ?? 0) < 9
        ? `0${
            index +
            1 +
            (category?.meta?.page ?? 0) * (category?.meta?.size ?? 0)
          }`
        : index + 1 + (category?.meta?.page ?? 0) * (category?.meta?.size ?? 0),
  }));

  const moreOptions = [{ label: "EDIT" }];

  return (
    <StyledRoot>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography fontSize={32} fontWeight={600} color="#0000008a">
            File Categories
          </Typography>
          <Tooltip
            arrow={true}
            placement="right-start"
            title="Create file categories to collate your data files in uniquely labeled buckets. [example 1: Category labeled “Product A” will contain all relevant documents to product A.  Example 2: Category labeled “Confidential” will have all highly confidential documents – you can limit this access to only few select users ]"
          >
            <IconButton
              icon={
                <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
              }
            />
          </Tooltip>
        </Stack>
        <AddButton
          variant="contained"
          content="Add File Category"
          onClick={handleRedirectToCreateCategory}
        />
      </Stack>
      <TableContainer>
        <Stack alignItems="flex-end" p={2}>
          <SearchField
            name="searchCategory"
            placeholder="Search by File Category Name, Team Name"
            value={searchString}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Table
          loading={isEqual(category?.status, "loading")}
          data={sequencedTableData}
          columns={productCategoryTableColumn}
          pagination={category?.meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {moreOptions?.map((option, index) => (
          <MenuItem
            key={option?.label ?? index}
            onClick={() => setAnchorEl(null)}
          >
            <StyledMenuItem onClick={() => handleOnMenuClick(index, option)}>
              {option?.label ?? ""}
            </StyledMenuItem>
          </MenuItem>
        ))}
      </Menu>
    </StyledRoot>
  );
};

export default ProductCategory;

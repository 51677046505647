import { useState } from "react";
//@mui
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Button from "src/components/elements/Button";
import TextField from "src/components/elements/Textfield";

const StyledContainer = styled(Box)(() => ({
  minWidth: 400,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 24,
  paddingTop: 32,
  borderRadius: 12,
  backgroundColor: "#ffffff",
  transform: "translate(-50%, -50%)",
  border: "none",
}));

const FileLabelPopup = ({ open, title, onClose, onConfirm }) => {
  const [label, setLabel] = useState("");

  const handleOnChange = (event) => {
    const { name, value } = event?.target ?? {};

    switch (name) {
      case "label":
        setLabel(value);
        break;
      default:
        break;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledContainer>
        <Typography fontSize={14} fontWeight={600} mb={2}>
          {title ?? ""}
        </Typography>
        <TextField
          size="small"
          name="label"
          placeholder="Enter file label"
          type="text"
          value={label}
          onChange={handleOnChange}
        />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          pt={2}
        >
          {onClose && (
            <Button variant="outlined" content="Cancel" onClick={onClose} />
          )}
          {onConfirm && (
            <Button content="Confirm" onClick={() => onConfirm(label)} />
          )}
        </Stack>
      </StyledContainer>
    </Modal>
  );
};

export default FileLabelPopup;

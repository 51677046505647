import { isEqual, isEmpty } from "lodash";
import MuiAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "../Textfield";

const filter = createFilterOptions();

const Autocomplete = ({
  id,
  name,
  placeholder,
  value,
  options,
  allowAddNewOption,
  onChange,
  ...props
}) => {
  const handleOnChange = (event, newValue) => {
    let value = "";

    if (typeof newValue === "string") value = newValue;
    else if (newValue?.inputValue) value = newValue?.inputValue;
    else value = newValue;

    onChange({ target: { name, value } });
  };

  const renderOption = (props, option) => (
    <li {...props}>{option?.label ?? ""}</li>
  );

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options?.some((option) =>
      isEqual(inputValue, option?.label)
    );

    if (!isEmpty(inputValue) && !isExisting && allowAddNewOption) {
      filtered.push({ inputValue, label: `Add "${inputValue}"` });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    if (typeof option === "string") return option;
    if (option?.inputValue) return option?.inputValue;
    return option?.label;
  };

  return (
    <MuiAutocomplete
      id={id}
      value={value}
      options={options}
      renderOption={renderOption}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
      {...props}
    />
  );
};

export default Autocomplete;

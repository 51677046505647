import { useLocation, useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { useAuthSelector } from "../../../../slices";
import useResponsive from "src/hooks/useResponsive";

import Iconify from "src/components/iconify/Iconify";

const StyledListItem = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  padding: "8px 24px",
  cursor: "pointer",
  transition: "background 200ms ease-in-out",
  "&.selected": { background: "#005544", p: { fontWeight: 600 } },
  "&:hover": { background: "#343540" },
}));

const StyledHeader = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme?.palette?.colorTheme?.theme4?.primaryText,

  padding: "15px 24px",
  borderBottom: `1px solid ${theme?.palette?.colorTheme?.theme4?.primaryBorder}`,
  ".MuiButtonBase-root": {
    fontWeight: 400,
    color: "#ffffff",
  },
  ".MuiIconButton-root": {
    paddingRight: 0,
  },
}));

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "32px 16px",
  gap: 16,
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    width: "270px",
    background: theme?.palette?.colorTheme?.theme1?.bg1,
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
}));

const SideNav = ({ open, onClose }) => {
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = pathname?.split("/");

  const { data: userDetails } = useAuthSelector();

  const handleNavItemOnClick = (navItem) => {
    if (isEqual(navItem?.id, "grafana")) {
      const url =
        "https://g-11562a57de.grafana-workspace.us-west-2.amazonaws.com/login";
      window.open(url, "_blank");
    } else {
      navigate(navItem?.id);
    }
  };

  const navConfig = [
    {
      id: "analytics",
      label: "Dashboard View",
      iconUrl: "/assets/icons/dashboard-icon.svg",
    },
    {
      id: "region",
      label: "Teams",
      icon: (
        <Iconify
          icon="material-symbols:person-pin"
          style={{
            color: "#ffffff",
            opacity: currentPath?.includes("region") ? 1 : 0.4,
          }}
        />
      ),
    },
    {
      id: "product-category",
      label: "File Categories",
      iconUrl: "/assets/icons/modules-icon.svg",
    },
    {
      id: "modules",
      label: "Modules",
      iconUrl: "/assets/icons/modules-icon.svg",
    },
    {
      id: "application-config",
      label: "Apps",
      iconUrl: "/assets/icons/applications-icon.svg",
    },
    {
      id: "users",
      label: "Users",
      iconUrl: "/assets/icons/users-icon.svg",
    },
    {
      id: "file-upload",
      label: "File Upload",
      iconUrl: "/assets/icons/file-upload-icon.svg",
    },
    {
      id: "all-files",
      label: "All Files",
      iconUrl: "/assets/icons/all-file-icon.svg",
    },
    {
      id: "grafana",
      label: "Grafana",
      icon: (
        <Iconify
          icon="cib:grafana"
          style={{
            color: "#ffffff",
            opacity: currentPath?.includes("region") ? 1 : 0.4,
          }}
        />
      ),
      rightIcon: (
        <Iconify icon="akar-icons:link-out" width={14} color="#959EAF" />
      ),
    },
  ];

  const validNavConfig = navConfig?.filter(
    (nav) =>
      !(
        isEqual(userDetails?.userType, "regionAdmin") &&
        isEqual(nav?.id, "modules")
      )
  );

  return (
    <Box component="nav">
      <StyledDrawer
        open={open}
        variant={mdUp ? "persistent" : "temporary"}
        ModalProps={{ keepMounted: true }}
        onClose={onClose}
      >
        <StyledHeader>
          <Typography fontSize={16} fontWeight={600}>
            Dashboard
          </Typography>
        </StyledHeader>
        <StyledContainer>
          {validNavConfig?.map((nav) => (
            <StyledListItem
              key={nav?.id}
              className={currentPath?.includes(nav?.id) ? "selected" : ""}
              onClick={() => handleNavItemOnClick(nav)}
            >
              {nav?.iconUrl && (
                <img
                  style={{
                    opacity: currentPath?.includes(nav?.id) ? 1 : 0.4,
                  }}
                  src={nav?.iconUrl ?? ""}
                  alt="link-icon"
                  width={20}
                />
              )}
              {nav?.icon && nav.icon}
              <Typography
                fontSize={14}
                fontWeight={400}
                color={currentPath?.includes(nav?.id) ? "#f1f1f1" : "#959EAF"}
              >
                {nav?.label}
              </Typography>
              {nav?.rightIcon && nav?.rightIcon}
            </StyledListItem>
          ))}
        </StyledContainer>
      </StyledDrawer>
    </Box>
  );
};

export default SideNav;

import MaterialChip from "@mui/material/Chip";

const Chip = ({ variant, label, size, onClick, onDelete, ...props }) => {
  return (
    <MaterialChip
      variant={variant}
      label={label}
      size={size}
      onClick={onClick}
      onDelete={onDelete}
      {...props}
    />
  );
};

export default Chip;

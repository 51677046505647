import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class ConfigService {
  getConfigs() {
    const option = {
      url: `${baseUrl}/admin/display_config`,
    };

    return axios.get(option);
  }
  createConfig(data) {
    const option = {
      url: `${baseUrl}/admin/display_config`,
      data,
    };

    return axios.post(option);
  }

  deleteConfig(id) {
    const option = {
      url: `${baseUrl}/admin/display_config`,
      data: { id },
    };

    return axios.delete(option);
  }

  getPromptConfigsById(id) {
    const option = {
      url: `${baseUrl}/admin/prompt_config/${id}`,
    };

    return axios.get(option);
  }

  getPromptConfigs() {
    const option = {
      url: `${baseUrl}/admin/prompt_config`,
    };

    return axios.get(option);
  }

  createPromptConfig(data) {
    const option = {
      url: `${baseUrl}/admin/prompt_config`,
      data,
    };

    return axios.post(option);
  }

  deletePromptConfig(id) {
    const option = {
      url: `${baseUrl}/admin/prompt_config`,
      data: { id },
    };

    return axios.delete(option);
  }

  updatePromptConfig(data) {
    const option = {
      url: `${baseUrl}/admin/prompt_config`,
      data,
    };

    return axios.put(option);
  }

  generatePrompt(story) {
    const option = {
      url: `${baseUrl}/admin/promptGenerate`,
      data: { story },
    };

    return axios.post(option);
  }
}

const configService = new ConfigService();
export default configService;

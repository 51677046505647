import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import useResponsive from "src/hooks/useResponsive";
import { AppStartupContext } from "src/context/AppStartup";
import searchService from "../../services/search";

const StyledRoot = styled("div")(({ theme, themename }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  background: theme.palette.colorTheme[themename]?.primaryBgColor,
}));

const Grid = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 32,
  margin: "80px auto",
  padding: "0 24px",
  maxHeight: "70vh",
  overflowY: "scroll",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    maxHeight: "80vh",
    padding: "0 16px",
  },
}));

const ApplicationCard = styled(Stack)(({ theme, themename }) => ({
  width: 350,
  minHeight: 100,
  flexDirection: "column",
  gap: 1,
  color: theme.palette.colorTheme[themename]?.secondaryTextColor,
  padding: 24,
  borderRadius: 16,
  background: "transparent",
  textTransform: "capitalize",
  overflowY: "scroll",
  border: `2px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.colorTheme[themename]?.secondaryTextColor,
    background: theme.palette.colorTheme[themename]?.secondaryBgColor,
    borderColor: "#3C862D",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ApplicationSelection = () => {
  const navigate = useNavigate();
  const aboveTablet = useResponsive("up", "md");
  const { selectedModule, setModuleList } = useOutletContext();
  const { theme: themename } = useContext(AppStartupContext);

  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    handleFetchApplication();
  }, []);

  const handleFetchApplication = async () => {
    try {
      const draftApplications = await searchService.getApplications();
      const draftModules = [];

      draftApplications?.forEach(({ ModuleConfig = {}, moduleId }) => {
        const isExist = !draftModules?.some((_i) => moduleId === _i?.id);
        if (moduleId && isExist) {
          draftModules?.push(ModuleConfig);
        }
      });

      setModuleList(draftModules);
      setApplications(draftApplications);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectApplication = (application) => {
    navigate("/search_user/chat", {
      state: {
        chatType: application?.name ?? "",
        id: application?.id,
        moduleId: selectedModule,
      },
    });
  };

  const convertToPairs = (array) => {
    const pairs = [];
    for (let i = 0; i < array.length; i += 2) {
      pairs.push([array[i], array[i + 1]]);
    }
    return pairs;
  };

  const moduleApplications =
    applications?.filter((app) => app?.moduleId === selectedModule) ?? [];

  const groupedApplications = convertToPairs(moduleApplications);

  return (
    <StyledRoot themename={themename}>
      <Grid>
        {isLoading
          ? [1, 2, 3]?.map((index) => (
              <Stack
                key={index}
                flexDirection={aboveTablet ? "row" : "column"}
                gap={4}
              >
                <Skeleton
                  variant="rounded"
                  sx={{ width: 350, height: 100, bgcolor: "#202123" }}
                />
                <Skeleton
                  variant="rounded"
                  sx={{ width: 350, height: 100, bgcolor: "#202123" }}
                />
              </Stack>
            ))
          : groupedApplications?.map((pair, index) => (
              <Stack
                key={index}
                flexDirection={aboveTablet ? "row" : "column"}
                gap={4}
              >
                {pair?.map((app, index) =>
                  app ? (
                    <ApplicationCard
                      key={app?.id ?? index}
                      themename={themename}
                      onClick={() => handleSelectApplication(app)}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        color={themename === "dark" ? "#d9d9d9" : "#1e1e1e"}
                      >
                        {app?.name?.split("_")?.join(" : ") ?? ""}
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color={themename === "dark" ? "#ececec" : "#343434"}
                      >
                        {app?.description ?? ""}
                      </Typography>
                    </ApplicationCard>
                  ) : (
                    <div style={{ width: aboveTablet ? "350px" : "100%" }} />
                  )
                )}
              </Stack>
            ))}
      </Grid>
    </StyledRoot>
  );
};

export default ApplicationSelection;

import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
// @mui
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";

import { BASEURL } from "../../../../constants/constants";
import { fileTableColumn } from "../../config";
import {
  convertKiloBytes,
  convertFullNameToAbbrevate,
} from "../../../../utils/helper";
import { fDateTime } from "../../../../utils/formatTime";
import dashboardService from "src/services/dashboard";
import {
  fetchFilesAction,
  useFilesSelector,
  resetFiles,
  setSnackbar,
} from "../../slices";

import Iconify from "src/components/iconify/Iconify";
import SearchField from "src/components/SearchField";
import IconButton from "src/components/elements/IconButton";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import Table from "../../components/Table";
import Button from "../../../../components/elements/Button";
import Tooltip from "src/components/elements/Tooltip";
import Loader from "../../components/Loader";
import DeleteFilePopup from "../../components/DeleteFilePopup";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const PrimaryButton = styled(Button)({
  minWidth: 100,
  fontSize: 14,
  fontWeight: 400,
  background: "#005544",
  boxShadow: "none",
  "&:hover": { background: "#005544" },
});

const BackButton = styled(Button)({
  fontWeight: 400,
  color: "#40414E",
});

const StyledLink = styled(Link)({
  fontSize: 14,
  fontWeight: 600,
  color: "#005544",
  textDecoration: "none",
  letterSpacing: "0.5px",
});

const StyledMenuItem = styled("div")(({ theme }) => ({
  minWidth: "160px",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "400",
  padding: 4,
}));

const StatusText = styled(Typography)({
  fontSize: 14,
  letterSpacing: 0.5,
  textAlign: "center",
  textTransform: "uppercase",
  "&.INIT": {
    color: "#0078FF",
  },
  "&.PROCESSING": {
    color: "#d6b542",
  },
  "&.DONE": {
    color: "green",
  },
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const Files = () => {
  const eventSourceRef = useRef({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productCategoryId } = useParams();
  const files = useFilesSelector();

  const [isLoading, setIsLoading] = useState(false);
  const [processingFiles, setProcessingFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState({});
  const [openFileLogs, setOpenFileLogs] = useState(false);
  const [openDeleteFilePopup, setOpenDeleteFilePopup] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(fetchFilesAction({ page: 0, size: 10, productCategoryId }));

    return () => {
      dispatch(resetFiles());
      Object.keys(eventSourceRef.current).forEach((fileId) =>
        eventSourceRef.current[fileId].close()
      );
      eventSourceRef.current = {};
    };
  }, []);

  useEffect(() => {
    const draftFilteredFiles = files?.data?.filter((item) =>
      item?.docName?.toLowerCase()?.includes(searchString?.toLowerCase())
    );

    setFilteredFiles(draftFilteredFiles ?? []);
  }, [files, searchString]);

  const handleSearchOnChange = (event) => {
    const { value } = event?.target ?? {};

    setSearchString(value);
  };

  const handleOnPageChange = (event, newPage) => {
    dispatch(
      fetchFilesAction({
        page: newPage,
        size: files?.meta?.size,
        productCategoryId,
      })
    );
  };

  const handleOnRowsPerPageChange = (event) => {
    dispatch(
      fetchFilesAction({
        page: 0,
        size: parseInt(event.target.value, 10),
        productCategoryId,
      })
    );
  };

  const handleOpenMenu = (event, file) => {
    setSelectedFile(file);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnMenuClick = (index) => {
    if (index === 0) {
      setOpenDeleteFilePopup(true);
    }
  };

  const handleDeleteFile = (deleteEmbeddings) => {
    setOpenDeleteFilePopup(false);
    setIsLoading(true);

    dashboardService
      .deleteFile(selectedFile?.id, deleteEmbeddings)
      .then(() =>
        dispatch(fetchFilesAction({ page: 0, size: 5, productCategoryId }))
      )
      .catch((error) => console.log(error))
      .finally(() => {
        setSelectedFile({});
        setIsLoading(false);
      });
  };

  const handleProcessOnClick = async (documentId) => {
    try {
      const url = `${BASEURL}/admin/file/process/${documentId}`;
      const eventSource = new EventSource(url);

      eventSourceRef.current[documentId] = eventSource;
      eventSource.onmessage = handleOnMessage;
      eventSource.onerror = () => handleOnError(documentId);

      setProcessingFiles((prevState) => [...prevState, documentId]);
    } catch (error) {
      console.log(error?.response?.data ?? "Something went wrong.");
      eventSourceRef?.current[documentId]?.close();
    }
  };

  const handleOnMessage = (event) => {
    const data = JSON.parse(event?.data);

    if (data?.status === "DONE") {
      setProcessingFiles((prevState) =>
        prevState?.filter((_i) => _i !== data?.id)
      );
      eventSourceRef?.current[data?.id]?.close();
      delete eventSourceRef?.current[data?.id];
    }

    if (data?.error) {
      dispatch(setSnackbar({ type: "error", message: data?.error }));
    }

    setFilteredFiles((prevState) =>
      prevState?.map((_i) => (_i?.id === data?.id ? { ..._i, ...data } : _i))
    );
  };

  const handleOnError = (documentId) => {
    setProcessingFiles((prev) => prev?.filter((_i) => _i !== documentId));
    eventSourceRef?.current[documentId]?.close();
    delete eventSourceRef?.current[documentId];
  };

  const handleOpenFileLogs = () => {
    setOpenFileLogs(true);
  };

  const handleGoBack = () => navigate("/dashboard/all-files");

  const renderAction = (value, item) => (
    <PrimaryButton
      className={processingFiles?.includes(item?.id) ? "progress" : ""}
      size="small"
      variant="contained"
      content={
        <span style={{ zIndex: 1 }}>
          {processingFiles?.includes(item?.id)
            ? `${item?.per ?? 0} %`
            : "Process"}
        </span>
      }
      disabled={processingFiles?.includes(item?.id)}
      startIcon={
        processingFiles?.includes(item?.id) && (
          <Iconify
            icon="eos-icons:bubble-loading"
            width={14}
            color={item?.per > 30 ? "#fff" : "#000"}
            zIndex={1}
          />
        )
      }
      sx={{
        "&.progress": {
          color: item?.per > 40 ? "#fff" : "#000",
          "&:before": {
            content: '" "',
            position: "absolute",
            left: 0,
            width: `${item?.per ?? 0}%`,
            height: "100%",
            borderRadius: "inherit",
            background: "#005544",
            transition: "width 0.3s ease-out",
          },
        },
      }}
      onClick={() => handleProcessOnClick(item?.id)}
    />
  );

  const renderMoreOptions = (value) => (
    <IconButton
      icon={<Iconify icon="entypo:dots-three-vertical" />}
      onClick={(event) => handleOpenMenu(event, value)}
    />
  );

  const renderStatus = (value) => (
    <StatusText
      className={(["INIT", "DONE", "Done"]?.includes(value)
        ? value
        : "PROCESSING"
      )?.toUpperCase()}
    >
      {value}
    </StatusText>
  );

  fileTableColumn?.forEach((column) => {
    if (isEqual(column?.id, "docName")) {
      column["value"] = (value) => (
        <Tooltip title={value}>
          <StyledLink>{convertFullNameToAbbrevate(value, 30)}</StyledLink>
        </Tooltip>
      );
    } else if (isEqual(column?.id, "extenstion")) {
      column["value"] = (value, item) => item?.docName?.split(".")?.pop() ?? "";
    } else if (isEqual(column?.id, "ProductCategory")) {
      column["value"] = (value) => value?.categoryName ?? "";
    } else if (isEqual(column?.id, "size")) {
      column["value"] = (value) => convertKiloBytes(value);
    } else if (isEqual(column?.id, "updatedAt")) {
      column["value"] = (value) => fDateTime(value);
    } else if (isEqual(column?.id, "status")) {
      column["value"] = (val, item) => renderStatus(val, item);
    } else if (isEqual(column?.id, "action")) {
      column["value"] = (value, item) => renderAction(value, item);
    } else if (isEqual("moreOption", column?.id)) {
      column["value"] = (value, item) => renderMoreOptions(item);
    }
  });

  const sequencedTableData = filteredFiles?.map((item, index) => ({
    ...item,
    sno:
      index + (files?.meta?.page ?? 0) * (files?.meta?.size ?? 0) < 9
        ? `0${index + 1 + (files?.meta?.page ?? 0) * (files?.meta?.size ?? 0)}`
        : index + 1 + (files?.meta?.page ?? 0) * (files?.meta?.size ?? 0),
  }));

  const categoryName =
    files?.data?.find((item) => item)?.ProductCategory?.categoryName ?? "Files";

  const moreOptions = [{ label: "DELETE" }];
  return (
    <StyledRoot>
      <Stack flexDirection="column" alignItems="flex-start" gap={1} mb={4}>
        <BackButton
          variant="text"
          startIcon={<Iconify icon="ep:back" />}
          content="Go back"
          onClick={handleGoBack}
        />
        <Typography fontSize={32} fontWeight={600} color="#0000008a">
          {categoryName}
        </Typography>
      </Stack>
      <TableContainer>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          p={2}
          gap={2}
        >
          <Button
            variant="text"
            color="primary"
            content="File Logs"
            onClick={handleOpenFileLogs}
          />
          <SearchField
            name="searchCategory"
            placeholder="Search by File Name"
            value={searchString}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Table
          loading={isEqual(files?.status, "loading") || isLoading}
          data={sequencedTableData}
          columns={fileTableColumn}
          pagination={files?.meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {moreOptions?.map((option, index) => (
          <MenuItem key={option?.label ?? index} onClick={handleClose}>
            <StyledMenuItem onClick={() => handleOnMenuClick(index, option)}>
              {option?.label ?? ""}
            </StyledMenuItem>
          </MenuItem>
        ))}
      </Menu>
      {openFileLogs && (
        <FileLogsDrawer
          open={openFileLogs}
          productCategoryId={productCategoryId}
          onClose={() => setOpenFileLogs(false)}
        />
      )}
      {openDeleteFilePopup && (
        <DeleteFilePopup
          open={openDeleteFilePopup}
          onClose={() => setOpenDeleteFilePopup(false)}
          onConfirm={handleDeleteFile}
        />
      )}
    </StyledRoot>
  );
};

export default Files;

const FileLogsDrawer = ({ open, productCategoryId, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fileLogs, setFileLogs] = useState([]);

  useEffect(() => {
    handleFetchFileLogs();
  }, []);

  const handleFetchFileLogs = () => {
    dashboardService
      .getFileLogs(productCategoryId)
      .then((response) => {
        const draftFileLogs = [];

        response.forEach((item) => {
          const index = draftFileLogs?.findIndex(
            (cat) => cat?.docId === item?.docId
          );

          if (index >= 0) {
            draftFileLogs?.[index]?.["logs"]?.push({ ...item });
          } else {
            draftFileLogs?.push({
              docId: item?.docId,
              docName: item?.DocumentConfig?.docName,
              logs: [{ ...item }],
            });
          }
        });

        setFileLogs(draftFileLogs);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const handleOpenFileSegment = async (docId, segment) => {
    try {
      const urlResponse = await dashboardService?.getFileUrl(docId, segment);
      window.open(urlResponse?.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      {isLoading ? (
        <Stack minWidth={400}>
          <Loader />
        </Stack>
      ) : (
        <Stack p={4} width={450} gap={3}>
          {fileLogs?.length > 0 ? (
            fileLogs?.map((fileLog, index) => (
              <Stack key={index} flexDirection="column">
                <Typography fontSize={18} fontWeight={600} color="#1e1e1e">
                  {convertFullNameToAbbrevate(fileLog?.docName ?? "", 40)}
                </Typography>
                <Stack flexDirection="column" gap={1} mt={2}>
                  {fileLog?.logs?.map((log, index) => (
                    <Stack
                      key={index}
                      sx={
                        index < fileLog?.logs?.length - 1 && {
                          paddingBottom: 2,
                          paddingTop: 2,
                          borderBottom: "1px solid #0000008a",
                        }
                      }
                    >
                      <Typography fontSize={16} fontWeight={400}>
                        {log?.log ?? ""}
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color="#0000008a"
                      >
                        {fDateTime(log?.createdAt) ?? ""}
                      </Typography>
                      {log?.segment && (
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography fontSize={14}>
                            {`${convertFullNameToAbbrevate(
                              fileLog?.docName ?? "",
                              25
                            )} | ${log?.segment}`}
                          </Typography>
                          <IconButton
                            icon={
                              <Iconify icon="material-symbols-light:download" />
                            }
                            onClick={() =>
                              handleOpenFileSegment(
                                fileLog?.docId,
                                log?.segment
                              )
                            }
                          />
                        </Stack>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))
          ) : (
            <Stack alignItems="center" justifyContent="center">
              <Typography fontSize={14}>No File Logs Found.</Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Drawer>
  );
};

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import userService from "src/services/userService";
import loginService from "src/services/loginService";
import {
  resetRegions,
  resetCategory,
  resetApplication,
  resetModule,
  resetFiles,
  resetUsers,
} from "src/pages/dashboard/slices";
import { fDateTime } from "../../../../utils/formatTime";

import Loader from "src/pages/dashboard/components/Loader";
import Button from "src/components/elements/Button";
import ConfirmationPopup from "src/pages/dashboard/components/ConfirmationPopup";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  overflowY: "scroll",
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const Divider = styled("div")({
  width: "100%",
  height: 1,
  background: "#dcdcdc",
  margin: "32px 0",
});

const AccountDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("localStorageAcc", localStorage?.getItem("auth"));
  const authDetails = localStorage?.getItem("auth")
    ? JSON.parse(localStorage?.getItem("auth") ?? "")
    : {};

  const [isLoading, setIsLoading] = useState(true);
  const [customerAdmins, setCustomerAdmins] = useState([]);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  useEffect(() => {
    handleFetchUsers();
  }, []);

  const handleFetchUsers = async () => {
    try {
      const userResponse = await userService?.getUsers();
      const customerAdminUsers = userResponse?.data?.filter(
        (item) => item?.Role?.role_key === "CA"
      );
      setCustomerAdmins(customerAdminUsers);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setIsLoading(true);
      setOpenConfirmationPopup(false);

      await userService?.deleteUser(authDetails?.id);
      loginService.dologout();

      dispatch(resetRegions());
      dispatch(resetCategory());
      dispatch(resetApplication());
      dispatch(resetModule());
      dispatch(resetFiles());
      dispatch(resetUsers());

      navigate("/login");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <StyledRoot>
        <Typography fontSize={32} fontWeight={600} color="#0000008a">
          Account details
        </Typography>
        <Stack flexDirection="row" mt={4} ml={2} gap={6}>
          <Stack flexDirection="column" gap={2}>
            <Typography fontSize={14} fontWeight={600} color="#abacb9">
              Account Name :
            </Typography>
            <Typography fontSize={14} fontWeight={600} color="#abacb9">
              User Name :
            </Typography>
            <Typography fontSize={14} fontWeight={600} color="#abacb9">
              Email Id :
            </Typography>
            <Typography fontSize={14} fontWeight={600} color="#abacb9">
              Phone Number :
            </Typography>
            <Typography fontSize={14} fontWeight={600} color="#abacb9">
              Member Since :
            </Typography>
          </Stack>
          <Stack flexDirection="column" gap={2}>
            <Typography
              fontSize={14}
              fontWeight={400}
              color="#000000"
              letterSpacing={0.5}
            >
              {authDetails?.Account?.account_name ?? "N/A"}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={400}
              color="#000000"
              letterSpacing={0.5}
            >
              {authDetails?.name ?? "N/A"}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={400}
              color="#000000"
              letterSpacing={0.5}
            >
              {authDetails?.email ?? "N/A"}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={400}
              color="#000000"
              letterSpacing={0.5}
            >
              {authDetails?.mobile ?? "N/A"}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={400}
              color="#000000"
              letterSpacing={0.5}
            >
              {fDateTime(authDetails?.createdAt) ?? "N/A"}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection="column" gap={2}>
          <Typography fontSize={16} fontWeight={600} color="#0000008a">
            Customer Admin's
          </Typography>
          <Stack flexDirection="row" ml={2} mb={4} gap={6}>
            <Stack flexDirection="column" gap={1.5}>
              <Typography fontSize={14} fontWeight={600} color="#abacb9">
                Name
              </Typography>
              {customerAdmins?.map((user, index) => (
                <Typography
                  key={user?.id ?? index}
                  fontSize={12}
                  letterSpacing={0.5}
                >
                  {user?.name ?? ""}
                </Typography>
              ))}
            </Stack>
            <Stack flexDirection="column" gap={1.5}>
              <Typography fontSize={14} fontWeight={600} color="#abacb9">
                Email
              </Typography>
              {customerAdmins?.map((user, index) => (
                <Typography
                  key={user?.id ?? index}
                  fontSize={12}
                  letterSpacing={0.5}
                >
                  {user?.email ?? ""}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Stack alignItems="flex-start">
          <Button
            color="error"
            content="Delete Account"
            onClick={() => setOpenConfirmationPopup(true)}
          />
        </Stack>
      </StyledRoot>
      {openConfirmationPopup && (
        <ConfirmationPopup
          open={openConfirmationPopup}
          message="Are you sure you want to delete your account? This action cannot be undone"
          onClose={() => setOpenConfirmationPopup(false)}
          onConfirm={handleDeleteAccount}
        />
      )}
    </>
  );
};

export default AccountDetails;

import IconButton from "@mui/material/IconButton";
import Iconify from "src/components/iconify/Iconify";

export const acceptableFileFormat = "xlsx, pptx, pdf";

export const regex = {
  nameValidator: `^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$`,
  emailValidator:
    "^[a-zA-Z0-9](?!.*?\\.\\.)[a-zA-Z0-9-_\\.]+@([a-zA-Z0-9_\\-.]+){2}\\.([a-zA-Z]{2,5})$",
};

export const roleOptions = [
  { label: "Search User", value: "searchUser" },
  { label: "Customer Admin", value: "customerAdmin" },
  // { label: "Super Admin", value: "superAdmin" },
];

export const regionTableColumn = [
  { id: "sno", label: "Sr. No.", sort: true },
  { id: "isActive", label: "Active" },
  { id: "region", label: "Teams", sort: true },
  { id: "createdAt", label: "Created At", sort: true },
  { id: "moreOption" },
];

export const productCategoryTableColumn = [
  { id: "sno", label: "Sr. No.", sort: true, width: 100 },
  { id: "categoryName", label: "File Category", sort: true, width: 100 },
  { id: "Region", label: "Teams", width: 100 },
  { id: "moreOption", width: 100 },
];

export const moduleTableColumn = [
  { id: "sno", label: "Sr. No.", sort: true },
  { id: "isActive", label: "Active" },
  { id: "module_name", label: "Module", sort: true },
  { id: "modeule_desc", label: "Description", sort: true },
  { id: "createdAt", label: "Created At", sort: true },
  { id: "updated_at", label: "Updated At", sort: true },
];

export const applicationConfigTableColumn = [
  { id: "sno", label: "Sr. No.", sort: true },
  { id: "name", label: "Name", sort: true },
  { id: "ModuleConfig", label: "Module Name", sort: true },
  { id: "ProductCategory", label: "File Category", sort: true },
  { id: "Region", label: "Teams" },
  { id: "createdAt", label: "Created At", sort: true },
  { id: "moreOption" },
];

export const fileTableColumn = [
  { id: "sno", label: "Sr. No." },
  { id: "docName", label: "File Name" },
  { id: "extenstion", label: "Extenstion" },
  { id: "tags", label: "Tag" },
  { id: "ProductCategory", label: "File Category" },
  { id: "size", label: "File Size" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
  { id: "moreOption" },
];

export const uploadedFileTableColumn = [
  { id: "sno", label: "Sr. No." },
  { id: "docName", label: "File Name" },
  { id: "extension", label: "Extension" },
  { id: "productCategoryId", label: "File Category" },
  { id: "size", label: "File Size" },
  { id: "uploadedAt", label: "Uploaded At" },
  { id: "status", label: "Status" },
];

export const userTableColumns = [
  { id: "sno", label: "Sr. No.", sort: true },
  { id: "name", label: "Name", sort: true },
  { id: "Region", label: "Teams" },
  { id: "mobile", label: "Phone no" },
  { id: "email", label: "Email Address", sort: true },
  { id: "Role", label: "Role" },
  { id: "createdAt", label: "Created At", sort: true },
  { id: "moreOption" },
];

export const allFileTableColumns = [
  { id: "sno", label: "Sr. No.", sort: true },
  { id: "categoryName", label: "File Category", sort: true },
  { id: "count", label: "Total files", sort: true },
  { id: "size", label: "Size", sort: true },
];

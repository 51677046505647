import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual, isEmpty } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  useRegionsSelector,
  useCategorySelector,
  createNewCategoryAction,
  updateCategoryAction,
  fetchAllRegionsAction,
} from "../../slices";

import Iconify from "src/components/iconify/Iconify";
import Loader from "src/pages/dashboard/components/Loader";
import Button from "src/components/elements/Button";
import TextField from "src/components/elements/Textfield";
import Select from "src/components/elements/Select";

const StyledRoot = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "40px 32px 32px 32px",
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: "#005544",
  lineHeight: "22px",
  padding: "12px",
  borderBottom: "4px solid #005544",
}));

const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": { fontSize: 14, width: 300 },
});

const StyledSelect = styled(Select)({
  ".MuiSelect-outlined": { fontSize: 14, width: 252 },
});

const BackButton = styled(Button)({
  fontWeight: 400,
  color: "#40414E",
});

const SubmitButton = styled(Button)({
  minWidth: 130,
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const initialFormState = {
  categoryName: "",
  regions: [],
};
const ProductCategoryConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const regions = useRegionsSelector();
  const category = useCategorySelector();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    if (!!id && category?.data?.length === 0) {
      handleGoBack();
    } else if (!!id && category?.data?.length > 0) {
      const selectedCategory = category?.data?.find(
        (category) => parseInt(category?.id) === parseInt(id)
      );
      const draftFormState = {
        id,
        categoryName: selectedCategory?.categoryName ?? "",
        regions: selectedCategory?.Region?.map((item) => item?.id) ?? [],
      };
      setFormState(draftFormState ?? {});
    }
    dispatch(fetchAllRegionsAction());
  }, []);

  const handleOnChange = (event) => {
    const { name, value } = event?.target ?? {};

    const draftFormState = { ...formState };
    draftFormState[name] = value;

    setFormState(draftFormState);
  };

  const handleOnSubmit = async () => {
    try {
      if (isEmpty(formState?.categoryName)) return;
      setLoading(true);
      if (!!id) {
        await dispatch(updateCategoryAction(formState));
      } else {
        await dispatch(createNewCategoryAction(formState));
      }
      navigate("/dashboard/product-category");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => navigate("/dashboard/product-category");

  const renderOptions = (regions) =>
    regions?.map((region) => ({ label: region?.region, value: region?.id }));

  const inputFieldProps = [
    {
      id: "category",
      type: "textfield",
      labelText: "File Category Name",
      name: "categoryName",
      placeholder: "Type File Category Name",
    },
    {
      id: "regions",
      type: "select",
      labelText: "Select Teams",
      name: "regions",
      placeholder: "Select Teams",
      options: renderOptions(regions?.data ?? []),
      multiple: true,
    },
  ];

  return (
    <StyledRoot>
      {(loading || isEqual(regions?.status, "loading")) && <Loader />}
      <Stack flexDirection="column" alignItems="flex-start" gap={1} mb={5}>
        <BackButton
          variant="text"
          startIcon={<Iconify icon="ep:back" />}
          content="Go back"
          onClick={handleGoBack}
        />
        <StyledTitle>
          {!id ? "Create File Category" : "Update File Category"}
        </StyledTitle>
      </Stack>
      <Stack
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        px={3.75}
        gap={6}
      >
        <Stack width={500} flexDirection="column" alignItems="flex-end" gap={4}>
          {inputFieldProps?.map((props) => (
            <Stack
              key={props?.id}
              flexDirection="row"
              alignItems="center"
              gap={3}
            >
              <Typography fontSize={14} fontWeight={400}>
                {props?.labelText}
              </Typography>
              {isEqual(props?.type, "textfield") && (
                <StyledTextField
                  id={props?.id}
                  name={props?.name}
                  size="small"
                  placeholder={props?.placeholder}
                  value={formState?.[props?.name] ?? ""}
                  onChange={handleOnChange}
                />
              )}
              {isEqual(props?.type, "select") && (
                <StyledSelect
                  {...props}
                  value={formState?.[props?.name] ?? ""}
                  onChange={handleOnChange}
                />
              )}
            </Stack>
          ))}
        </Stack>
        <Stack width="100%" alignItems="flex-end">
          <SubmitButton
            size="medium"
            content={!id ? "Submit" : "Update"}
            onClick={handleOnSubmit}
          />
        </Stack>
      </Stack>
    </StyledRoot>
  );
};

export default ProductCategoryConfig;

import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "src/services/userService";

export const fetchAllUsersAction = createAsyncThunk(
  "dashboard/users/fetch",
  async (params) => await userService.getUsers(params)
);

export const createUserAction = createAsyncThunk(
  "dashboard/users/create",
  async (payload) => await userService.createUser(payload)
);

export const updateUserAction = createAsyncThunk(
  "dashboard/users/update",
  async (payload) => await userService.updateUser(payload)
);

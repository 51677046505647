import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { userTableColumns } from "../../config";
import loginService from "src/services/loginService";
import userService from "src/services/userService";
import {
  fetchAllUsersAction,
  useUserSelector,
  setSnackbar,
  useResourceSelector,
} from "../../slices";
import { fDateTime } from "../../../../utils/formatTime";

import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";
import Iconify from "src/components/iconify";
import SearchField from "src/components/SearchField";
import Chip from "src/components/elements/Chip";
import Tooltip from "src/components/elements/Tooltip";
import Table from "../../components/Table";
import AddUserPopup from "../../components/AddUserPopup";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import ChangePasswordPopup from "../../components/ChangePasswordPopup";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const AddButton = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const StyledMenuItem = styled("div")(({ theme }) => ({
  minWidth: "160px",
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "400",
  padding: 4,
}));

const StyledChip = styled(Chip)({
  "&.superAdmin": {
    color: "#ffffff",
    backgroundColor: "#4169E1",
  },
  "&.customerAdmin": {},
  "&.regionAdmin": {},
  "&.searchUser": {
    color: "#8353E2",
    backgroundColor: "#F5F2FD",
  },
});

const WrappedText = styled(Typography)({
  maxWidth: 180,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage?.getItem("auth") ?? "") || {};
  const users = useUserSelector();
  const resources = useResourceSelector();

  const [searchStr, setSearchStr] = useState("");
  const [openAddUserPopup, setOpenAddUserPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(fetchAllUsersAction({ page: 0, size: 10, searchStr }));
  }, []);

  const handleSearchOnChange = (event) => {
    const draftSearchString = event?.target?.value ?? "";
    setSearchStr(draftSearchString);
  };

  const handleSearchOnClick = () => {
    dispatch(fetchAllUsersAction({ page: 0, size: 10, searchStr }));
  };

  const handleOnMenuClick = (index) => {
    if (index === 0) {
      navigate(`${selectedUser?.id}/edit`);
    } else if (index === 1) {
      setOpenConfirmationModal(true);
    } else if (index === 2) {
      const payload = { email: selectedUser?.email ?? "" };
      loginService.resetPassword(payload).then((response) => {
        if (response?.status === 200) {
          const snackbar = {
            message: "Reset link sent successfully.",
            type: "success",
          };

          dispatch(setSnackbar(snackbar));
        }
      });
    } else if (index === 3) {
      setOpenChangePasswordModal(true);
    }
  };

  const handleDeleteUser = () => {
    setOpenConfirmationModal(false);

    userService
      .deleteUser(selectedUser?.id)
      .then(() => dispatch(fetchAllUsersAction({ page: 0, size: 5 }))``)
      .catch((error) => console.log(error));
  };

  const handleRedirectToCreateCategory = () => navigate("create");

  const handleOpenMenu = (event, user) => {
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnPageChange = (event, newPage) => {
    dispatch(
      fetchAllUsersAction({ page: newPage, size: users?.meta?.size, searchStr })
    );
  };

  const handleOnRowsPerPageChange = (event) => {
    const { value: size } = event?.target ?? {};
    dispatch(fetchAllUsersAction({ page: 0, size, searchStr }));
  };

  const handleCloseConfirmationModla = () => setOpenConfirmationModal(false);

  const renderMoreOptions = (value) => (
    <IconButton
      icon={<Iconify icon="entypo:dots-three-vertical" />}
      onClick={(event) => handleOpenMenu(event, value)}
    />
  );

  const renderUserType = (value) =>
    ({
      SU: <StyledChip className="searchUser" label="Search User" />,
      CA: <StyledChip className="customerAdmin" label="Customer Admin" />,
      RA: <StyledChip className="regionAdmin" label="Region Admin" />,
      SA: <StyledChip className="superAdmin" label="Super Admin" />,
    }[value?.role_key]);

  const renderRegions = (regions) => (
    <Tooltip
      title={regions?.map((item) => item?.region)?.join(" , ")}
      placement="left-start"
    >
      <WrappedText>
        {regions?.map((item) => item?.region)?.join(" , ")}
      </WrappedText>
    </Tooltip>
  );

  userTableColumns?.forEach((column) => {
    if (isEqual(column?.id, "Role")) {
      column["value"] = renderUserType;
    } else if (isEqual(column?.id, "Region")) {
      column["value"] = renderRegions;
    } else if (["createdAt", "updatedAt"]?.includes(column?.id)) {
      column["value"] = (value) => fDateTime(value);
    } else if (isEqual("moreOption", column?.id)) {
      column["value"] = (value, item) => renderMoreOptions(item);
    }
  });

  const sequencedTableData = users?.data?.map((item, index) => ({
    ...item,
    sno:
      index + (users?.meta?.page ?? 0) * (users?.meta?.size ?? 0) < 9
        ? `0${index + 1 + (users?.meta?.page ?? 0) * (users?.meta?.size ?? 0)}`
        : index + 1 + (users?.meta?.page ?? 0) * (users?.meta?.size ?? 0),
  }));

  const moreOptions = [
    { label: "EDIT" },
    { label: "DELETE" },
    { label: "RESET PASSWORD" },
    auth?.Role?.role_key === "CA" && { label: "CHANGE PASSWORD" },
  ];

  return (
    <StyledRoot>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <Typography fontSize={32} fontWeight={600} color="#0000008a">
            Users
          </Typography>
          <Tooltip
            arrow={true}
            placement="right-start"
            title="User permissions and access."
          >
            <IconButton
              icon={
                <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
              }
            />
          </Tooltip>
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          {resources?.addUserLimit ? (
            <AddButton
              variant="contained"
              content={`Add User (${Math.max(
                0,
                resources?.addUserLimit - users?.meta?.totalSize
              )})`}
              disabled={resources?.addUserLimit < users?.meta?.totalSize}
              onClick={handleRedirectToCreateCategory}
            />
          ) : (
            <AddButton
              variant="contained"
              content="Add User"
              onClick={handleRedirectToCreateCategory}
            />
          )}
        </Stack>
      </Stack>
      <TableContainer>
        <Stack alignItems="flex-end" p={2}>
          <SearchField
            name="searchUser"
            type="text"
            placeholder="Search by User Name"
            value={searchStr}
            onChange={handleSearchOnChange}
            onSearch={handleSearchOnClick}
          />
        </Stack>
        <Table
          loading={isEqual(users?.status, "loading")}
          data={sequencedTableData}
          columns={userTableColumns}
          pagination={users?.meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
      <AddUserPopup
        open={openAddUserPopup}
        onSubmit={() => dispatch(fetchAllUsersAction({ page: 0, size: 5 }))}
        onClose={() => setOpenAddUserPopup(false)}
      />
      <ConfirmationPopup
        open={openConfirmationModal}
        message="Are you sure? Once user deleted user will no longer be able to login to application."
        onClose={handleCloseConfirmationModla}
        onConfirm={handleDeleteUser}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {moreOptions?.map((option, index) => (
          <MenuItem key={option?.label ?? index} onClick={handleClose}>
            <StyledMenuItem onClick={() => handleOnMenuClick(index, option)}>
              {option?.label ?? ""}
            </StyledMenuItem>
          </MenuItem>
        ))}
      </Menu>
      <ChangePasswordPopup
        open={openChangePasswordModal}
        user={selectedUser}
        onClose={() => setOpenChangePasswordModal(false)}
      />
    </StyledRoot>
  );
};
export default Users;

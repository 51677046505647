import { useState, useEffect } from "react";
import { isEmpty, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
  useResourceSelector,
  useCategorySelector,
  useModuleSelector,
  useApplicationSelector,
  createApplicationAction,
  updateApplicationAction,
  fetchAllModuleAction,
  fetchAllCategoryAction,
} from "../../slices";

import Iconify from "src/components/iconify/Iconify";
import Loader from "src/pages/dashboard/components/Loader";
import Button from "src/components/elements/Button";
import TextField from "src/components/elements/Textfield";
import Select from "src/components/elements/Select";
import Chip from "src/components/elements/Chip";
import MultipleTextField from "src/pages/dashboard/components/MultipleTextField";
import Autocomplete from "src/components/elements/Autocomplete";

const StyledRoot = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "40px 32px 32px 32px",
  overflow: "scroll",
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: "#005544",
  lineHeight: "22px",
  padding: "12px",
  borderBottom: "4px solid #005544",
}));

const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": { fontSize: 14, width: 300 },
});

const StyledSelect = styled(Select)({
  ".MuiSelect-outlined": { fontSize: 14, width: 252 },
});

const StyledAutocomplete = styled(Autocomplete)({ width: 300 });

const BackButton = styled(Button)({
  fontWeight: 400,
  color: "#40414E",
});

const SubmitButton = styled(Button)({
  minWidth: 130,
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const initialFormState = {
  name: "",
  productCategory: [],
  regions: [],
  moduleId: "",
  description: "",
  keyword: "",
  theme: "",
  suggestions: [],
};
const ModuleConfig = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { applicationId: appId } = useParams();
  const resources = useResourceSelector();
  const module = useModuleSelector();
  const applications = useApplicationSelector();
  const productCategory = useCategorySelector();

  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    const promises = [
      dispatch(fetchAllModuleAction()),
      dispatch(fetchAllCategoryAction()),
    ];

    Promise.allSettled(promises).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    const application =
      applications?.data?.find((item) => item?.id === parseInt(appId)) ?? {};

    const draftFormState = {
      name: application?.name ?? "",
      productCategory:
        application?.ProductCategory?.map((category) => category?.id) ?? [],
      regions: application?.Region?.map((region) => region?.id) ?? [],
      moduleId: application?.ModuleConfig?.id,
      description: application?.description,
      theme: application?.theme,
      suggestions: application.Suggestion?.map((item) => ({
        id: item?.id,
        value: item?.suggestion,
      })),
    };

    setFormState(draftFormState);
  }, [appId]);

  const handleOnChange = (event) => {
    const { name, value } = event?.target ?? {};

    const draftFormState = { ...formState };
    draftFormState[name] = value;

    setFormState(draftFormState);
  };

  const handleOnSubmit = async () => {
    try {
      const payload = { ...formState };
      payload["suggestions"] =
        payload?.suggestions?.map((item) => item?.value) ?? [];

      setLoading(true);
      await dispatch(createApplicationAction(payload));
      navigate(`/dashboard/application-config`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnUpdate = async () => {
    try {
      const payload = { ...formState, id: appId };
      payload.suggestions?.forEach((suggestion) => {
        if (`${suggestion?.id}`?.includes("input")) suggestion["id"] = "";
      });
      setLoading(true);
      await dispatch(updateApplicationAction(payload));
      navigate(`/dashboard/application-config`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => navigate("/dashboard/application-config");

  const renderProductCategory = (productCategory) =>
    productCategory?.map((item) => ({
      label: item?.categoryName,
      value: item?.id,
    }));

  const renderRegions = (productCategory) => {
    const regions =
      productCategory?.find((item) =>
        formState?.productCategory?.includes(item?.id)
      )?.Region ?? [];

    return regions?.map((item) => ({ label: item?.region, value: item?.id }));
  };

  const renderModule = (modules) =>
    modules?.map((item) => ({ label: item?.module_name, value: item?.id }));

  const inputFieldProps = [
    {
      id: "moduleId",
      field: "select",
      labelText: "Select Module",
      name: "moduleId",
      placeholder: "Select Module",
      options: renderModule(module?.data ?? []),
    },
    {
      id: "productCategory",
      field: "select",
      labelText: "Select File Category",
      name: "productCategory",
      placeholder: "Select File Category",
      multiple: true,
      options: renderProductCategory(productCategory?.data ?? []),
    },
    {
      id: "name",
      labelText: "App Name",
      field: "input",
      name: "name",
      placeholder: "Enter Name",
    },
    {
      id: "region",
      field: "select",
      labelText: "Select Teams",
      name: "regions",
      placeholder: "Select Teams",
      multiple: true,
      disabled: isEmpty(formState?.productCategory),
      options: renderRegions(productCategory?.data ?? []),
    },
    {
      id: "description",
      labelText: "App Body Text",
      field: "input",
      name: "description",
      placeholder: "Enter Description",
      multiline: true,
      rows: 3,
    },
    {
      id: "keyword",
      field: "autocomplete",
      labelText: "Select Keyword",
      name: "keyword",
      placeholder: "Select keyword",
      size: "small",
      allowAddNewOption: true,
      options: resources?.promptKeywordOptions ?? [],
    },
    {
      id: "suggestions",
      field: "multiple",
      labelText: "Suggestions",
      name: "suggestions",
      placeholder: "Enter Suggestion",
    },
  ];

  return (
    <StyledRoot>
      {loading && <Loader />}
      <Stack flexDirection="column" alignItems="flex-start" gap={1} mb={5}>
        <BackButton
          variant="text"
          startIcon={<Iconify icon="ep:back" />}
          content="Go back"
          onClick={handleGoBack}
        />
        <StyledTitle>
          {appId ? "Update Application" : "Create Application"}
        </StyledTitle>
      </Stack>
      <Stack
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        px={3.75}
        gap={5}
      >
        <Stack width={500} flexDirection="column" alignItems="flex-end" gap={4}>
          {inputFieldProps?.map((props) => (
            <Stack
              key={props?.id}
              flexDirection="row"
              alignItems="flex-start"
              gap={3}
            >
              <Typography fontSize={14} fontWeight={400} py={1}>
                {props?.labelText}
              </Typography>
              {isEqual(props?.field, "input") && (
                <StyledTextField
                  {...props}
                  size="small"
                  value={formState?.[props?.name] ?? ""}
                  onChange={handleOnChange}
                />
              )}
              {isEqual(props?.field, "select") && (
                <StyledSelect
                  {...props}
                  value={formState?.[props?.name]}
                  onChange={handleOnChange}
                />
              )}
              {isEqual(props?.field, "multiple") && (
                <MultipleTextField
                  {...props}
                  value={formState?.[props?.name]}
                  onChange={handleOnChange}
                />
              )}
              {isEqual(props?.field, "autocomplete") && (
                <StyledAutocomplete
                  {...props}
                  value={formState?.[props?.name]}
                  onChange={handleOnChange}
                />
              )}
            </Stack>
          ))}
        </Stack>
        <Stack width="100%" alignItems="flex-end">
          {appId ? (
            <SubmitButton
              size="medium"
              content="Update"
              onClick={handleOnUpdate}
            />
          ) : (
            <SubmitButton
              size="medium"
              content="Submit"
              onClick={handleOnSubmit}
            />
          )}
        </Stack>
      </Stack>
    </StyledRoot>
  );
};

export default ModuleConfig;

import PropTypes from "prop-types";
import { useEffect, useState, useRef, useContext } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import Cookies from "universal-cookie";
// import Cookies from "universal-cookie";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import useResponsive from "../../hooks/useResponsive";
import { BASEURL } from "../../constants/constants";
import { AppStartupContext } from "src/context/AppStartup";
// import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from "@mui/material/styles";
import {
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Iconify from "../../components/iconify";
import Rating from "../../components/Rating";
// import Typography from 'src/theme/overrides/Typography';
// import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ScrollToBottom from "react-scroll-to-bottom";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import Brightness4Icon from "@mui/icons-material/Brightness4";
// import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import searchService from "../../services/search";
import dashboardService from "src/services/dashboard";
import { useOutletContext } from "react-router-dom";
import loginService from "../../services/loginService";
import { chatSuggestions } from "../../constants/constants";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useResourceSelector } from "src/pages/dashboard/slices";

// ----------------------------------------------------------------------
const baseUrl = BASEURL;
const cookies = new Cookies();
const StyledContent = styled(ScrollToBottom)(({ theme }) => ({
  // maxWidth: 480,
  margin: "auto",
  minHeight: "calc(100vh - 196px)",
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  gap: 21,
  padding: theme.spacing(0),
}));

const StyledMessageContainer = styled("div", {
  shouldForwardProp: (prop) => prop,
})(({ theme, themename }) => ({
  "& div.message": {
    fontFamily: "Helvetica",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",

    "&.message-user": {
      fontSize: 16,
      color: theme.palette.colorTheme[themename]?.secondaryTextColor,
      background: theme.palette.colorTheme[themename]?.primaryBgColor,
      letterSpacing: "0.625px",
      padding: "8px 16px",
      "& p": {
        margin: "10px 0",
        wordBreak: "break-word",
      },
    },

    "&.message-bot": {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.palette.colorTheme[themename]?.primaryTextColor,
      background: theme.palette.colorTheme[themename]?.primaryBgColor,
      letterSpacing: "1px",
      padding: "16px",
      paddingBottom: "32px",
      "& p": {
        margin: "10px 0",
        wordBreak: "break-word",
      },
    },
  },
}));
const StyledMainContainer = styled("div", {
  shouldForwardProp: (prop) => prop,
})(({ theme, themename }) => ({
  width: "100%",
  height: "calc(100vh - 56px)",
  background: theme.palette.colorTheme[themename]?.primaryBgColor,
  overflow: "auto",
}));

const StyledCommentPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    padding: "10px",
    background: "#d9d9d9",
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
}));

const StyledSpinner = styled("span")(({ theme }) => ({
  width: "40px",
  height: "40px",
  display: "inline-block",
  position: "relative",
  "&::after, &::before": {
    content: '""',
    boxSizing: "border-box",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "2px solid #FFF",
    position: "absolute",
    left: "0",
    top: "0",
    animation: "animloader 2s linear infinite",
  },
  "&::after": {
    animationDelay: "1s",
  },
}));

const StyledChatInputContainer = styled("div", {
  shouldForwardProp: (prop) => prop,
})(({ theme, themename }) => ({
  backgroundImage: `linear-gradient(180deg, hsla(0,0%,100%,0) 10%, ${theme.palette.colorTheme[themename]?.primaryBgColor} 40%)`,
  position: "absolute",
  width: "calc(100% - 10px)",
  paddingLeft: "0.8rem",
  paddingRight: "0.5rem",
  paddingBottom: "10px",
  paddingTop: "30px",
  left: 0,
  bottom: 0,
  [theme.breakpoints.up("md")]: {
    paddingBottom: "18px",
    paddingTop: "18px",
  },
}));

const StyledSuggestionContainer = styled("div", {
  shouldForwardProp: (prop) => prop,
})(({ theme, themename }) => ({
  display: "flex",
  alignItems: "baseline",
  padding: "10px 20px 5px",
  fontFamily: "Helvetica",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "normal",
  maxWidth: "1500px",
  margin: "auto",

  "& span.suggestion-static-text": {
    marginRight: "12px",
    color: theme.palette.colorTheme[themename]?.primaryTextColor,
  },

  "& div.suggestions-box": {
    width: "calc(100% - 94px)",
    display: "flex",
    overflowY: "auto",
    padding: "8px 12px",

    "& .suggestion-item": {
      padding: "5px 15px",
      border: "1px solid transparent",
      borderRadius: "20px",
      background: theme.palette.colorTheme[themename]?.secondaryBgColor,
      marginRight: "8px",
      color: theme.palette.colorTheme[themename]?.secondaryTextColor,
      cursor: "pointer",
      flex: "none",
      "&:hover": {
        boxShadow: `0px 0px 8px 3px ${theme.palette.colorTheme[themename]?.shadowColor}`,
        borderColor: theme.palette.colorTheme[themename]?.borderColor,
        transition: "all 0.3s",
      },
      "&.selected-item": {
        color: "#ffffff",
        background: `${theme.palette.colorTheme[themename]?.successColor} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
      },
    },
  },
}));

const StyledGrid = styled("div")(({ theme }) => ({
  width: "70%",
  maxHeight: "180px",
  overflowY: "scroll",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "repeat(2,auto)",
  gap: "16px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    paddingBottom: "16px",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

const ThinLinedBox = styled("div")(({ theme, themename }) => ({
  height: "100%",
  minHeight: 66,
  display: "flex",
  alignItems: "center",
  padding: "12px 16px",
  borderRadius: "12px",
  background: theme.palette.colorTheme[themename]?.secondaryBgColor,
  transition: "background 100ms ease-in-out",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minHeight: "54px",
    display: "flex",
    padding: "8px 16px",
    borderRadius: "12px",
  },
}));

const SuggestionText = styled(Typography)(({ theme, themename }) => ({
  fontSize: "14px",
  fontWeight: 300,
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  letterSpacing: "0.1px",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:hover": {
    boxShadow: `0px 0px 8px 3px ${theme.palette.colorTheme[themename]?.shadowColor}`,
    transition: "all 0.3s",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "12px",
  },
}));

const QuestionSuggestion = styled("div")(({ theme, themename }) => ({
  width: "fit-content",
  fontSize: 14,
  color: theme.palette.colorTheme[themename]?.primaryTextColor,
  padding: "8px 16px",
  border: `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
  borderRadius: "12px",
  cursor: "pointer",
  transition: "background 500ms ease-in-out",
  "&.selected-item,&:hover": {
    background: theme.palette.colorTheme[themename]?.secondaryBgColor,
  },
}));

const ThemeButton = styled("div")(({ theme, themename }) => ({
  position: "fixed",
  bottom: 124,
  right: 24,
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 50,
  background: theme.palette.colorTheme[themename]?.secondaryBgColor,
  border: `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
  boxShadow: `0px 0px 8px 3px ${theme.palette.colorTheme[themename]?.shadowColor}`,
  cursor: "pointer",
}));

let messageId = 1;
// const cookies = new Cookies();
let convoId = null;
// ----------------------------------------------------------------------

Chat.propTypes = {
  onNavData: PropTypes.func,
};

export default function Chat({ onNavData }) {
  let { convoId } = useParams();
  let scrollEnd = useRef();
  const { initialPitch, setNavData } = useOutletContext();
  const outletContext = useOutletContext();
  const { theme: themename, setTheme } = useContext(AppStartupContext);
  const location = useLocation();
  const resources = useResourceSelector();
  const { state } = location;
  const chatType = state?.chatType;

  const mdUp = useResponsive("up", "md");
  const [userQuery, setUserQuery] = useState("");
  const [chatConfig, setChatConfig] = useState([...initialPitch]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [commentAnchorEl, setCommentAnchorEl] = useState(null);
  const [actingMessageId, setActingMessageId] = useState(null);
  const [comment, setComment] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [queryInProgress, setQueryInProgress] = useState(false);
  const [conversId, setConversId] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [moduleChatSuggestions, setModuleChatSuggestions] = useState([]);
  const [moduleId, setModuleId] = useState(state?.moduleId ?? 1);
  const [responseSource, setResponseSource] = useState([]);
  const [questionSuggestions, setQuestionSuggestions] = useState([]);

  useEffect(() => {
    if (typeof convoId !== typeof undefined && convoId !== null) {
      setConversId(convoId);
      getConversationById();
    }

    getChatHistory(moduleId);
  }, []);

  useEffect(() => {
    handleFetchModuleChatSuggestions();
  }, [state]);

  useEffect(() => {
    if (location.pathname === "/search_user/chat") {
      convoId = null;
      setChatConfig([]);
      setConversId(null);
      if (state?.chatModuleChanged) {
        setModuleId(state?.moduleId);
        getChatHistory(state?.moduleId);
        setUserQuery("");
        setSelectedSuggestion("");
        clearSelectedSuggestion();
      }
    } else if (typeof convoId !== typeof undefined && convoId !== null) {
      setConversId(convoId);
      getConversationById();
    }
  }, [location, setChatConfig, setConversId]);

  const handleFetchModuleChatSuggestions = () => {
    searchService
      .getApplicationSuggestion({ applicationId: state?.id })
      .then((response) => setModuleChatSuggestions(response ?? []));
  };

  const getChatHistory = async (_moduleId) => {
    try {
      let chatHistoryRes = await searchService.getChatHistory(_moduleId);
      setNavData(chatHistoryRes?.data?.conversation);
    } catch (err) {
      console.log(err);
    }
  };

  const getConversationById = async () => {
    try {
      setChatConfig([]);
      let chatDetailsRes = await searchService.getChatDetails(convoId);
      let updatedChatConfig = [];
      chatDetailsRes.data.conversation.Questions.forEach((question) => {
        updatedChatConfig.push(
          {
            id: question.id,
            text: question.question,
            sender: "user",
          },
          {
            id: question.Answers[0]?.id || "",
            question: question?.question,
            text: question.Answers[0]?.answer || "",
            sender: "bot",
            source: isValidJSON(question.Answers[0]?.source)
              ? JSON.parse(question.Answers[0]?.source)
              : [],
            rating: question.Answers[0]?.rating || "",
            commentAdded: question.Answers[0]?.comment !== null || false,
            comment: question.Answers[0]?.comment || null,
          }
        );
      });
      // console.log("173 > updatedChatConfig > ", updatedChatConfig);
      setChatConfig(updatedChatConfig);
    } catch (err) {
      console.log(err);
    }
  };

  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const userQueryChanged = (ev) => {
    // console.log(ev.target.value);
    setUserQuery(ev.target.value);
  };

  const clearQuery = () => {
    setUserQuery("");
    setSelectedSuggestion("");
    clearSelectedSuggestion();
  };

  const askQuery = async (ev) => {
    setUserQuery(selectedSuggestion ? `${selectedSuggestion}` : "");
    setQueryInProgress(true);

    let updatedChatConfig = [
      ...chatConfig,
      {
        id: messageId++,
        text: userQuery,
        sender: "user",
      },
      {
        sender: "bot",
        text: "",
        question: userQuery,
        isDummy: true,
      },
    ];
    setChatConfig(updatedChatConfig);
    setTimeout(() => {
      // scrollEnd.scrollIntoView({ behavior: "smooth", bloack: "end", inline: "nearest" });
      console.log(
        document.querySelector(".main-scroll-container > div").scrollHeight
      );
      document.querySelector(".main-scroll-container > div").scroll({
        left: 0,
        top: document.querySelector(".main-scroll-container > div")
          .scrollHeight,
        behavior: "smooth",
      });
    }, 500);
    try {
      fetchEventSource(`${baseUrl}/conversation/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
        },
        body: JSON.stringify({
          applicationId: state?.id,
          moduleId: moduleId,
          question: userQuery,
          convoId: conversId ? parseInt(conversId) : null,
        }),
        openWhenHidden: true,
        onmessage(event) {
          const data = JSON.parse(event?.data ?? "") || undefined;
          if (data && data?.suggestions) {
            setQuestionSuggestions(data?.suggestions);
          }

          if (data && data?.text) {
            updatedChatConfig.splice(updatedChatConfig.length - 1, 1);
            updatedChatConfig = [
              ...updatedChatConfig,
              {
                id: data?.answerId,
                question: userQuery,
                text: data?.text,
                sender: data?.sender,
                rating: data?.rating,
                source: data?.source,
                commentAdded: data?.commentAdded,
                comment: "",
                convoId: data?.convoId,
              },
            ];

            convoId = convoId ? convoId : data?.convoId;
            setConversId(convoId);
            setChatConfig(updatedChatConfig);
          }
        },
        onclose() {
          getChatHistory(moduleId);
          setQueryInProgress(false);
        },
        onerror(error) {
          setSnackbarMessage("Something went wrong !!!");
          setOpenSnackbar(true);
          setQueryInProgress(false);
          showErrorResponse(updatedChatConfig);
          throw error;
        },
      });
    } catch (err) {
      console.log(err);
      // setOpenSnackbar(true);
      showErrorResponse(updatedChatConfig);
      setQueryInProgress(false);
    }
  };

  const showErrorResponse = (updatedChatConfig) => {
    updatedChatConfig.splice(updatedChatConfig.length - 1, 1);
    updatedChatConfig = [
      ...updatedChatConfig,
      {
        // ...searchRes.data.answer
        id: null,
        question: userQuery,
        text: "Network Error! Please try again.",
        sender: "bot",
        rating: 0,
        source: [],
        commentAdded: false,
        comment: "",
        convoId: convoId,
      },
    ];
    setChatConfig(updatedChatConfig);
  };

  const updateResponseRating = async (_ev, message, rating) => {
    let updatedChatConfig = chatConfig.map((item) => ({
      ...item,
      rating: item.id === message.id ? rating : item.rating,
    }));
    try {
      const rateRes = await searchService.rateResponse({
        id: message.id,
        review: rating,
      });
      if (rateRes?.status === 200) {
        setChatConfig(updatedChatConfig);
        // cookies.set("chat_data", updatedChatConfig);
      } else {
        setSnackbarMessage("Something went wrong !!!");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage(err?.message);
      setOpenSnackbar(true);
    }
  };

  const viewResponseSources = (ev, message) => {
    console.log(message);
    setResponseSource(message?.source);
    setAnchorEl(ev.currentTarget);
  };

  const handleOpenDocument = (document) => {
    //get document link
    dashboardService?.getFileUrl(document?.docId ?? "").then((response) => {
      const url = `${response?.url}#page=${document?.page ?? 0}`;
      window.open(url, "_blank");
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const copyText = (_e, message) => {
    let url = "";
    if (process.env.REACT_APP_NAME === "schneider") {
      url = "https://schneider.argusquery.ai/login";
    } else if (process.env.REACT_APP_NAME === "demo") {
      url = "https://demo.argusquery.ai/login";
    } else if (process.env.REACT_APP_NAME === "uat") {
      url = "https://uat.argusquery.ai/login";
    } else if (process.env.REACT_APP_NAME === "prod") {
      url = "https://app.argusquery.ai/login";
    } else if (process.env.REACT_APP_NAME === "sandbox") {
      url = "https://sandbox.argusquery.ai/login";
    }

    const copiedText = `Question:\n${message?.question ?? ""}\n\nAnswer:\n${
      message?.text ?? ""
    }\n\n${url}`;

    navigator.clipboard.writeText(copiedText);
    setSnackbarMessage("Text Copied!");
    setOpenSnackbar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage("");
    setOpenSnackbar(false);
  };

  const showCommentBox = (_e, message) => {
    setActingMessageId(message.id);
    setCommentAnchorEl(_e.currentTarget);
    console.log(message);
    if (message.comment !== null) {
      setComment(message.comment);
    } else setComment("");
    // setOpenResponseComment(true);
  };

  const handleCloseComment = () => {
    // setOpenResponseComment(false);
    setActingMessageId(null);
    setCommentAnchorEl(null);
  };

  const openResponseComment = Boolean(commentAnchorEl);

  const addComment = async (_e, message) => {
    let updatedChatConfig = chatConfig.map((item) => ({
      ...item,
      commentAdded: item.id === message.id ? true : item.commentAdded,
      comment: item.id === message.id ? comment : item.comment,
    }));
    console.log(updatedChatConfig);
    try {
      const commentRes = await searchService.addComment({
        id: message.id,
        comment: comment,
      });
      console.log(commentRes);
      if (commentRes?.status === 200) {
        // setCommentAnchorEl(null); --- used below for this
        handleCloseComment();
        setSnackbarMessage("Comment Added!");
        setOpenSnackbar(true);
        setChatConfig(updatedChatConfig);
        setComment("");
        // cookies.set("chat_data", updatedChatConfig);
      } else {
        setSnackbarMessage("Something went wrong !!!");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage(err?.message);
      setOpenSnackbar(true);
    }
  };

  const handleKeyDown = (event) => {
    if (
      userQuery.replace(`${selectedSuggestion}`, "").trim() !== "" &&
      !queryInProgress &&
      event.keyCode === 13 &&
      !event.shiftKey
    ) {
      event.preventDefault();
      askQuery(event);
    }
  };

  const suggestionSelected = (ev, item) => {
    if (selectedSuggestion === item?.description) {
      ev.target.classList.remove("selected-item");
      let updatedUserQuery = `${userQuery?.replace(
        `${!isEmpty(item?.description) ? item?.description : item?.suggestion}`,
        ""
      )}`;

      setSelectedSuggestion();
      setUserQuery(updatedUserQuery);
    } else {
      clearSelectedSuggestion();
      ev.target.classList.add("selected-item");
      const suggestionPrefix = {
        prev: `${selectedSuggestion}`,
        curr: !isEmpty(item?.description)
          ? item?.description
          : item?.suggestion,
      };

      let updatedUserQuery = `${suggestionPrefix.curr}`;
      setSelectedSuggestion(item?.description);
      setUserQuery(updatedUserQuery);
    }
  };

  const clearSelectedSuggestion = () => {
    document
      .querySelectorAll(".suggestions-box .suggestion-item")
      ?.forEach((el) => el.classList.remove("selected-item"));
    setSelectedSuggestion("");
  };

  const handleQuestionOnClick = (ev, question) => {
    if (selectedSuggestion === question) {
      ev.target.classList.remove("selected-item");
      let updatedUserQuery = `${userQuery?.replace(`${question}`, "")}`;

      setSelectedSuggestion();
      setUserQuery(updatedUserQuery);
    } else {
      clearSelectedSuggestion();
      ev.target.classList.add("selected-item");
      const suggestionPrefix = {
        prev: `${selectedSuggestion}`,
        curr: question,
      };

      let updatedUserQuery = `${suggestionPrefix.curr}`;
      setSelectedSuggestion(question);
      setUserQuery(updatedUserQuery);
    }
  };

  const isA3Module = resources?.moulesV2?.includes(state?.moduleId);
  return (
    <>
      <Helmet>
        <title> Chat - {chatType ? chatType : "FAQ"} </title>
      </Helmet>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <StyledMainContainer themename={themename}>
        <StyledContent
          sx={{
            position: "relative",
            height: "100%",
            alignItems: "center",
          }}
          className="main-scroll-container"
          ref={(el) => {
            scrollEnd = el;
          }}
        >
          <StyledMessageContainer
            className="chat-message-container"
            sx={{ width: "100%", height: "100%", paddingTop: 2 }}
            themename={themename}
          >
            {[...initialPitch, ...chatConfig]?.map((message, index) => (
              <div
                key={index}
                style={{
                  fontSize: !mdUp && "18.5px",
                }}
                className={`message message-${message.sender}`}
              >
                <div
                  className="message-inner-container"
                  style={{
                    maxWidth: "1400px",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  <div
                    className="message-avatar"
                    style={{
                      width: mdUp ? "36px" : "32px",
                      height: mdUp ? "36px" : "32px",
                      borderRadius: "7px",
                      background:
                        message.sender === "bot" ? "#099e50" : "#d9d9d9",
                      color: "#000000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: mdUp ? "20px" : "16px",
                    }}
                  >
                    {message.sender === "bot" ? (
                      <img
                        src="/assets/icons/chat-icon.svg"
                        alt="schneider logo"
                        width={mdUp ? "24" : "21"}
                        height={mdUp ? "26.5" : "23.5"}
                        style={{
                          filter: themename === "dark" ? "unset" : "invert(1)",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: mdUp ? "18px" : "16px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                        }}
                      >
                        {loginService.getInitials() || "Q"}
                      </span>
                    )}
                  </div>
                  <div
                    className="message-content"
                    style={{
                      width: mdUp ? "calc(100% - 56px)" : "calc(100% - 50px)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      {message.sender === "bot" ? (
                        !message.isDummy ? (
                          <Markdown
                            className="message-bot"
                            remarkPlugins={[remarkGfm]}
                          >
                            {message?.text}
                          </Markdown>
                        ) : (
                          <div className="message-bot">
                            <StyledSpinner className="loader"></StyledSpinner>
                          </div>
                        )
                      ) : (
                        <Markdown
                          className="message-user"
                          remarkPlugins={[remarkGfm]}
                        >
                          {message?.text}
                        </Markdown>
                      )}
                    </div>
                    {message.sender === "bot" && !message.isDummy && (
                      <Stack flexDirection="column" gap={2}>
                        <div
                          style={{
                            marginTop: "28px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="response-rating">
                            <Typography component="legend">
                              Rate the response
                            </Typography>
                            <Rating
                              blink={
                                chatConfig?.length - 1 === index &&
                                !queryInProgress
                              }
                              name={message?.id}
                              value={message.rating}
                              onChange={(_ev, newValue) => {
                                updateResponseRating(_ev, message, newValue);
                              }}
                            />
                          </div>
                          <div
                            className="message-action"
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            <IconButton onClick={(ev) => copyText(ev, message)}>
                              <ContentCopyRoundedIcon />
                            </IconButton>
                            <IconButton
                              onClick={(ev) => showCommentBox(ev, message)}
                            >
                              <MapsUgcRoundedIcon
                                style={{
                                  color: message.commentAdded ? "green" : "",
                                }}
                              />
                            </IconButton>
                            <StyledCommentPopover
                              id={`response-comment_${message.id}`}
                              open={
                                actingMessageId === message.id &&
                                openResponseComment
                              }
                              anchorEl={commentAnchorEl}
                              onClose={handleCloseComment}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              sx={{
                                padding: "10px",
                              }}
                            >
                              <TextField
                                sx={{
                                  width: "100%",
                                  margin: "auto",
                                  display: "flex",
                                  background: "rgba(145, 158, 171, 0.2)",
                                  fontFamily: "Helvetica",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "0.3px",
                                  "& .MuiInputBase-root": {
                                    color: "#000",
                                  },
                                }}
                                id="response-comment-input"
                                placeholder="Add Comment"
                                variant="outlined"
                                multiline
                                rows={5}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              />
                              <Button
                                disabled={comment.trim()?.length === 0}
                                sx={{
                                  padding: "5px 15px",
                                  marginTop: "15px",
                                  borderRadius: "25px",
                                  background: "rgba(145, 158, 171, 0.24)",
                                  color: "rgba(0, 0, 0, 0.7)",
                                  transition: "all 0.5s",
                                  "&:hover": {
                                    background: "#202123",
                                    color: "#E1E1E1",
                                    transform: "scale(1.08)",
                                  },
                                }}
                                onClick={(e) => addComment(e, message)}
                              >
                                Submit
                              </Button>
                            </StyledCommentPopover>
                            {message?.source?.length > 0 && (
                              <>
                                <IconButton
                                  style={{
                                    color: "#8E8E9E",
                                    fontFamily: "Helvetica",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    letterSpacing: "0.4px",
                                  }}
                                  aria-describedby={`response-source_${message.id}`}
                                  onClick={(ev) =>
                                    viewResponseSources(ev, message)
                                  }
                                >
                                  <DifferenceOutlinedIcon
                                    style={{ color: "#637381" }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </div>
                        </div>
                        {!queryInProgress &&
                          chatConfig?.length - 1 === index && (
                            <Stack flexDirection="column" gap={2}>
                              {questionSuggestions?.map((question, index) => (
                                <QuestionSuggestion
                                  themename={themename}
                                  onClick={(event) =>
                                    handleQuestionOnClick(event, question)
                                  }
                                >
                                  {question ?? ""}
                                </QuestionSuggestion>
                              ))}
                            </Stack>
                          )}
                      </Stack>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div style={{ height: "11.5rem" }} />
          </StyledMessageContainer>
        </StyledContent>
        <Popover
          id={`response-source`}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List
            dense={true}
            sx={{
              maxHeight: "180px",
              minWidth: "210px",
              maxWidth: "420px",
              overflow: "auto",
              padding: 0,
            }}
          >
            {responseSource?.map(
              (item, sourceIndex) =>
                !!item && (
                  <ListItem
                    key={sourceIndex}
                    sx={{
                      padding: 0,
                      "&:not(:last-child)": {
                        borderBottom: "1px solid #999",
                      },
                    }}
                  >
                    <ListItemButton onClick={() => handleOpenDocument(item)}>
                      <ListItemText
                        primary={`${item?.source ?? ""} | Page ${
                          item?.page ?? ""
                        }`}
                      />
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Popover>
        <StyledChatInputContainer themename={themename}>
          {isEmpty(chatConfig) ? (
            <Stack alignItems="center" mb={4}>
              <StyledGrid>
                {moduleChatSuggestions?.map((suggestion) => (
                  <ThinLinedBox
                    themename={themename}
                    onClick={(ev) => suggestionSelected(ev, suggestion)}
                  >
                    <SuggestionText
                      themename={themename}
                      title={suggestion?.suggestion ?? ""}
                    >
                      {suggestion?.suggestion ?? ""}
                    </SuggestionText>
                  </ThinLinedBox>
                ))}
              </StyledGrid>
            </Stack>
          ) : (
            moduleChatSuggestions?.length > 0 && (
              <StyledSuggestionContainer
                className="suggestions-container"
                themename={themename}
              >
                <span className="suggestion-static-text">Suggestions: </span>
                <div className="suggestions-box">
                  {moduleChatSuggestions?.map((item, index) => (
                    <div
                      key={index}
                      value={item.text}
                      className="suggestion-item"
                      onClick={(ev) => suggestionSelected(ev, item)}
                    >
                      {item?.suggestion ?? ""}
                    </div>
                  ))}
                </div>
              </StyledSuggestionContainer>
            )
          )}
          {!isA3Module && (
            <TextField
              sx={{
                width: "100%",
                maxWidth: "1500px",
                margin: "auto",
                display: "flex",
                "& .MuiInputBase-root": {
                  minHeight: mdUp ? "56px" : "48px",
                  fontFamily: "Helvetica",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  fontSize: mdUp ? "17px" : "17px",
                  color: (theme) =>
                    theme.palette.colorTheme[themename]?.ternaryTextColor,
                  borderRadius: "13px",
                  background: (theme) =>
                    theme.palette.colorTheme[themename]?.primaryBgColor,
                  border: (theme) =>
                    `1px solid ${theme.palette.colorTheme[themename]?.borderColor}`,
                  paddingLeft: mdUp ? "28px" : "24px",
                  paddingRight: "24px",
                  "& fieldset": { border: "none" },
                  "& .MuiInputAdornment-root": {
                    "&.first-button": {
                      marginRight: "12px",
                    },
                    "& .MuiIconButton-root": {
                      "&.Mui-disabled": {
                        color: "rgba(145, 158, 171, 0.2)",
                        cursor: "not-allowed",
                        pointerEvents: "all",
                      },
                      "& svg.MuiSvgIcon-root": {
                        width: mdUp ? "24px" : "21px",
                        height: mdUp ? "24px" : "21px",
                      },
                      "& svg": {
                        width: mdUp ? "32px" : "24px",
                        height: mdUp ? "32px" : "24px",
                      },
                    },
                  },
                },
              }}
              id="search-query-input"
              placeholder="Send a message"
              variant="outlined"
              multiline
              maxRows={5}
              InputProps={{
                endAdornment: (
                  <>
                    {userQuery.trim() !== "" && !queryInProgress && (
                      <InputAdornment position="end" className="first-button">
                        <IconButton edge="end" onClick={(_e) => clearQuery()}>
                          <CloseOutlinedIcon></CloseOutlinedIcon>
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      {queryInProgress ? (
                        <StyledSpinner className="loader"></StyledSpinner>
                      ) : (
                        <IconButton
                          disabled={userQuery.trim() === ""}
                          onClick={(e) => askQuery(e)}
                          edge="end"
                        >
                          <Iconify
                            icon="fluent:send-16-filled"
                            sx={{
                              color: (theme) =>
                                theme.palette.colorTheme[themename]
                                  ?.ternaryTextColor,
                            }}
                          />
                        </IconButton>
                      )}
                    </InputAdornment>
                  </>
                ),
              }}
              value={userQuery}
              onKeyDown={(ev) => handleKeyDown(ev)}
              onChange={(e) => userQueryChanged(e)}
            />
          )}
          <Typography
            variant="body1"
            sx={{
              color: "#7C7C89",
              fontFamily: "Helvetica",
              fontSize: mdUp ? "14px" : "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textAlign: "center",
              mt: mdUp ? 2 : 1,
            }}
          >
            Powered by <b>Argus </b>
            <small>(v1.1)</small>
          </Typography>
        </StyledChatInputContainer>
      </StyledMainContainer>
      <ThemeButton
        themename={themename}
        onClick={() => setTheme(themename === "dark" ? "light" : "dark")}
      >
        <Brightness4Icon
          sx={{ color: themename === "dark" ? "#f9f9f9" : "#171717" }}
        />
      </ThemeButton>
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import useResponsive from "src/hooks/useResponsive";
import Iconify from "src/components/iconify";
import { useState } from "react";
import userService from "src/services/userService";

const StyledContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const StyledBanner = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "237px 100px",
  backgroundImage: 'url("/assets/backgroundImage.webp")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    padding: "172px 20px",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "100px 0",
  backgroundColor: "#1c1f22",
  [theme.breakpoints.down("md")]: {
    padding: "60px 0",
  },
}));

const StyledVideo = styled("div")(({ theme }) => ({
  flex: 1.2,
  width: "90%",
  aspectRatio: "1.2/1",
  height: "auto",
  borderRadius: "20px",
}));

const StyledImage = styled("div")(({ theme }) => ({
  width: "auto",
  height: "700px",
  aspectRatio: "3/6",
  borderRadius: "20px",
  img: { height: "100%" },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    height: "600px",
    aspectRatio: "2/3.5",
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "111px 40px",
  background: "#000",
  [theme.breakpoints.down("md")]: {
    padding: "50px 20px",
  },
}));

const ThickSolidStack = styled(Stack)(() => ({
  border: "3px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "20px",
}));

const ThinSolidBox = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "240px",
  minHeight: "50px",
  fontSize: "10px",
  letterSpacing: "0.5px",
  color: "#CDCFDD",
  padding: "8px 16px",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "10px",
  margin: "8px",
  whiteSpace: "pre-line",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    margin: "0 0 16px 0",
    whiteSpace: "unset",
  },
}));

const DummyField = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: "50px",
  fontSize: "14px",
  letterSpacing: "0.5px",
  color: "rgba(255, 255, 255, 0.6)",
  padding: "8px 24px",
  border: "1px solid #3F4246",
  borderRadius: "10px",
  margin: "24px 8px 0 8px",
  background: "#333639",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    margin: "16px 0 0 0",
  },
}));

const Card = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#333639",
  borderRadius: "20px",
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    width: "auto",
    margin: "0 20px",
  },
}));

const StyledGrid = styled("div")(
  ({ theme, rowGap = 3, columnGap = 3, type }) => ({
    display: "flex",
    alignItems: "center",
    rowGap: rowGap * 8,
    columnGap: columnGap * 8,
    ...(type === "list" && {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(3,auto)",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
        gridTemplateRows: "repeat(2,auto)",
      },
    }),
  })
);

const TextBox = styled("div")(({ theme }) => ({
  minHeight: "60px",
  display: "flex",
  alignItems: "center",
  fontSize: 12,
  fontWeight: 500,
  color: "#ffffff",
  padding: "12px 32px",
  borderRadius: "12px",
  whiteSpace: "pre-line",
  background: "#515151",
  [theme.breakpoints.down("md")]: {
    whiteSpace: "unset",
  },
}));

const Divider = styled("div")(() => ({
  width: "100%",
  padding: "12px 0",
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: "18px",
  lineHeight: 1.7,
  color: "rgb(190, 191, 194)",
  "&:before": {
    display: "inline-block",
    content: '""',
    width: "8px",
    height: "8px",
    marginRight: "12px",
    marginLeft: "8px",
    marginBottom: "2px",
    background: "rgb(190, 191, 194)",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "200px",
  fontSize: "18px",
  fontWeight: "600",
  padding: "14px 25px",
  marginTop: "20px",
  borderRadius: "29px",
  lineHeight: "normal",
  color: "rgb(245, 245, 255)",
  background:
    "linear-gradient(89deg,#ff0000 -11.73%,rgba(146,3,209,1) 52.36%,rgb(0,4,255) 110.63%)",
  boxShadow:
    "0 0.7065919983928324px 0.7065919983928324px -0.625px #00000026, 0 1.8065619053231785px 1.8065619053231785px -1.25px #00000025, 0 3.6217592146567767px 3.6217592146567767px -1.875px #00000023, 0 6.8655999097303715px 6.8655999097303715px -2.5px #00000020, 0 13.646761411524492px 13.646761411524492px -3.125px #0000001b, 0 30px 30px -3.75px #0000000d",
}));

const StyledFooter = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#000",
}));

const LandingPage = () => {
  const navigate = useNavigate();
  const mdUp = useResponsive("up", "md");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleRedirectToLogin = () => {
    navigate("/sandbox");
  };

  // const handleText = (e) => {
  //   console.log(e.target.value);
  //   switch (e.target.id) {
  //     case "name":
  //       setName(e.target.value);
  //       break;
  //     case "email":
  //       setEmail(e.target.value);
  //       break;
  //     case "message":
  //       setMessage(e.target.value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleContact = async () => {
    console.log(name, email, text);
    const data = {
      name,
      email,
      text,
    };

    await userService
      .contactUs(data)
      .then(() => {
        setName("");
        setEmail("");
        setMessage("");
        setShowSnackbar({
          type: "success",
          message: "Message sent successfully!",
        });
      })
      .catch((error) => {
        setShowSnackbar({
          type: "error",
          message: "Semething went wrong!",
        });
      });
  };

  const handleCloseSnackBar = () => setShowSnackbar({});

  const proprietaryDataExample = [
    { title: `Marketing & Sales Collateral` },
    { title: `User Manuals Service Manuals` },
    { title: "Scientific Docs. Research" },
    { title: "Customer Data CRM Data" },
  ];

  const supportedFileIcons = [
    { src: "/assets/icons/drive-icon.svg" },
    { src: "/assets/icons/word-icon.svg" },
    { src: "/assets/icons/excel-icon.svg" },
    { src: "/assets/icons/salesforce-icon.svg" },
    { src: "/assets/icons/mysql-icon.svg" },
    { src: "/assets/icons/database-icon.svg", width: 32 },
  ];

  const benefits = [
    { title: "Reduce administrative burden and improve productivity." },
    { title: "Find Information when you need it via a mobile or desktop." },
    { title: "Search using natural language prompts." },
    { title: "Easy as a search bar. No training needed." },
    { title: "Fully customizable search criteria." },
  ];

  const applicableFor = [
    { title: "Marketing" },
    { title: "Legal" },
    { title: "Sales & Service Support" },
    { title: "Business Development" },
    { title: "Customer Success" },
    { title: "Operations" },
  ];

  return (
    <StyledContainer>
      <Snackbar
        open={!isEmpty(showSnackbar)}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity={showSnackbar?.type}
          sx={{ width: "100%" }}
        >
          {showSnackbar?.message}
        </Alert>
      </Snackbar>
      <StyledBanner>
        <Typography
          color="#ffffff"
          fontSize={40}
          fontWeight={400}
          lineHeight={1.2}
        >
          Welcome to the
        </Typography>
        <Typography
          color="#ffffff"
          fontSize={50}
          fontWeight={700}
          lineHeight={1.2}
          textAlign="center"
        >
          ArgusQuery.AI SandBox
        </Typography>
        <Typography
          color="#ffffff"
          fontSize={mdUp ? 24 : 18}
          fontWeight={500}
          mt={3.37}
          textAlign="center"
        >
          Test version to understand customer preferences. Not actual product
        </Typography>
        <StyledButton
          variant="contained"
          startIcon={<Iconify icon="ph:play-fill" width={28} />}
          onClick={handleRedirectToLogin}
        >
          Try Now
        </StyledButton>
      </StyledBanner>
      <StyledSection>
        <Stack
          direction={mdUp ? "row" : "column-reverse"}
          alignItems="center"
          spacing={10}
          maxWidth={mdUp ? 1100 : "auto"}
          pt={mdUp ? 10 : 0}
          px={2.5}
        >
          <StyledVideo>
            <iframe
              frameBorder="0"
              src="https://player.vimeo.com/video/900252277?h=3ac3bb420d"
              allow="autoplay; fullscreen; picture-in-picture"
              width="100%"
              height="100%"
              title="ArgusQueryLandingPage_Video"
            />
          </StyledVideo>
          <Stack direction="column" flex={1}>
            <Typography
              color="#ffffff"
              fontSize={mdUp ? 35 : 28}
              fontWeight={400}
              lineHeight={1.2}
            >
              Elevate productivity with <strong>ArgusQuery</strong> Generative
              AI Platform
            </Typography>
            {/* <Typography color="#ffffff" fontSize={35} fontWeight={600}>
              ArgusQuery.AI
            </Typography> */}
            <Typography
              color="rgba(255, 255, 255, 0.6)"
              fontSize={18}
              fontWeight={400}
              lineHeight={1.6}
              mt={2.5}
            >
              Employees spend countless hours accessing multiple internal and
              external data sources to search for information, to analyze data,
              and to create content.
            </Typography>
            <Typography
              color="rgba(255, 255, 255, 0.6)"
              fontSize={18}
              fontWeight={400}
              lineHeight={1.6}
              mt={2}
            >
              ArgusQuery using generative AI gives unparalleled valuable
              insights from proprietary data and dramatically amplifies
              productivity.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          flexDirection={mdUp ? "row" : "column"}
          alignItems="center"
          maxWidth={mdUp ? 1000 : "auto"}
          paddingTop={mdUp ? 22.62 : 7.5}
          gap={4}
          px={mdUp ? 0 : 2.5}
        >
          <Stack flex={1} flexDirection="column" gap={4}>
            <Typography
              fontSize={mdUp ? 35 : 28}
              fontWeight={600}
              color="#ffffff"
              lineHeight="normal"
            >
              Interrogate data using natural language conversational prompts
            </Typography>
            <Typography fontSize={18} color="#ffffff">
              ArgusQuery.AI collates contextual and related information from
              proprietary data using Generative Pre-trained Transformers GPT to
              answer questions using conversational natural language prompts via
              mobile or desktop.
            </Typography>
            <Stack>
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#ffffff"
                lineHeight={2}
              >
                Proprietary Data Examples:
              </Typography>
              {proprietaryDataExample?.map((text, index) => (
                <StyledTypography key={index}>
                  <Typography color="#ffffff" component="span">
                    {text?.title}
                  </Typography>
                </StyledTypography>
              ))}
            </Stack>
          </Stack>
          <Stack flex={1.3} flexDirection="column" alignItems="center">
            <Card>
              <Typography fontSize={14} fontWeight={600} color="#ffffff" mb={2}>
                Proprietary Data
              </Typography>
              <StyledGrid columnGap={2}>
                {supportedFileIcons?.map((fileIcon, index) => (
                  <Stack
                    key={index}
                    alignItems="center"
                    justifyContent="center"
                    mx={mdUp ? 1 : 0}
                  >
                    <img
                      src={fileIcon?.src}
                      alt="file-icon"
                      width={fileIcon?.width ?? 44}
                    />
                  </Stack>
                ))}
              </StyledGrid>
            </Card>
            <Stack flexDirection="column" my={2}>
              <img src="/assets/icons/down-icon.svg" alt="down" width={18} />
              <img src="/assets/icons/down-icon.svg" alt="down" width={18} />
            </Stack>
            <ThickSolidStack
              direction="column"
              alignItems="center"
              spacing={2}
              px={2}
              py={4}
              mx={mdUp ? 0 : 2.5}
              mb={mdUp ? 0 : 2.5}
            >
              <Stack flexDirection="row" alignItems="center" gap={2} pb={1.5}>
                <img src="/assets/logo.png" alt="argus-icon" width={40} />
                <Typography fontSize={14} fontWeight={600} color="#ffffff">
                  ArgusQuery
                </Typography>
              </Stack>
              <Stack flexDirection="column">
                <Stack flexDirection={mdUp ? "row" : "column"}>
                  <ThinSolidBox>
                    {`Search and instantaneously find most 
                    relevant context rich information.`}
                  </ThinSolidBox>
                  <ThinSolidBox>
                    {`Analyze documents, content,
                  diagrams, tables.`}
                  </ThinSolidBox>
                </Stack>
                <Stack flexDirection={mdUp ? "row" : "column"}>
                  <ThinSolidBox>{`Summarize complex information.`}</ThinSolidBox>
                  <ThinSolidBox>
                    {`Compare, contrast, flag specific 
                  information or content`}
                  </ThinSolidBox>
                </Stack>
                <Stack flexDirection={mdUp ? "row" : "column"}>
                  <ThinSolidBox>
                    {`Create collateral and context rich content`}
                  </ThinSolidBox>
                  <ThinSolidBox>
                    {`Build and automate detailed context 
                  specific documentation`}
                  </ThinSolidBox>
                </Stack>
                <DummyField>
                  <span>Send your message</span>
                  <Iconify
                    icon="ic:sharp-send"
                    color="rgba(255, 255, 255, 0.6)"
                  />
                </DummyField>
              </Stack>
            </ThickSolidStack>
          </Stack>
        </Stack>

        <Stack
          direction={mdUp ? "row" : "column-reverse"}
          alignItems="center"
          spacing={mdUp ? 10 : 7.5}
          maxWidth={1000}
          pt={mdUp ? 22.62 : 7.5}
          px={2.5}
        >
          <StyledImage>
            <img
              src="/assets/images/products/mobile-view.png"
              alt="mobile-view"
            />
          </StyledImage>
          <Stack direction="column" flex={1}>
            <Typography
              color="#ffffff"
              fontSize={mdUp ? 35 : 28}
              fontWeight={700}
              mb={2.25}
            >
              Benefits and Features
            </Typography>
            {benefits?.map((item, index) =>
              benefits?.length - 1 > index ? (
                <Divider key={index}>
                  <Typography
                    fontSize={18}
                    fontWeight={400}
                    color="rgb(190, 191, 194)"
                    lineHeight={mdUp ? 1.6 : 1.5}
                  >
                    {item?.title}
                  </Typography>
                </Divider>
              ) : (
                <Typography
                  fontSize={18}
                  fontWeight={400}
                  color="rgb(190, 191, 194)"
                  lineHeight={1.6}
                  pt={1.5}
                >
                  {item?.title}
                </Typography>
              )
            )}
            <Typography
              fontSize={18}
              fontWeight={700}
              color="#ffffff"
              lineHeight={1.6}
              mt={6}
              mb={1}
            >
              Applications For:
            </Typography>
            <StyledGrid columnGap={4} rowGap={0} type="list">
              {applicableFor?.map((text, index) => (
                <StyledTypography key={index}>{text?.title}</StyledTypography>
              ))}
            </StyledGrid>
          </Stack>
        </Stack>
      </StyledSection>
      <StyledStack>
        <Typography
          fontSize={35}
          fontWeight={700}
          mb={2.6}
          textAlign="center"
          color="#ffffff"
        >
          Security and Compliance
        </Typography>
        <Typography
          fontSize={18}
          fontWeight={400}
          lineHeight={1.5}
          maxWidth={626}
          textAlign="center"
          color="#ffffff"
        >
          Implementing ArgusQuery.AI on proprietary data can be done with a
          focus on maintaining robust security measures and ensuring compliance
          with data protection regulations. Safeguard sensitive information and
          maintain the trust of stakeholders.
        </Typography>
      </StyledStack>
      <StyledSection>
        <Stack
          width={mdUp ? 600 : "100%"}
          alignItems="center"
          px={mdUp ? 0 : 2.5}
        >
          <Typography fontSize={35} fontWeight={600} color="#ffffff">
            Contact
          </Typography>
          <Stack
            width="100%"
            flexDirection={mdUp ? "row" : "column"}
            alignItems="center"
            gap={2}
            mb={2}
            mt={6}
          >
            <TextField
              type="text"
              placeholder="Name"
              fullWidth={true}
              sx={{
                "& .MuiInputBase-root": { background: "#fff" },
              }}
              value={name}
              onChange={(event) => setName(event?.target?.value)}
            />
            <TextField
              type="email"
              placeholder="Email"
              fullWidth={true}
              sx={{
                "& .MuiInputBase-root": { background: "#fff" },
              }}
              value={email}
              onChange={(event) => setEmail(event?.target?.value)}
            />
          </Stack>
          <TextField
            type="text"
            placeholder="Message"
            multiline={true}
            minRows={6}
            fullWidth={true}
            sx={{
              "& .MuiInputBase-root": { background: "#fff" },
            }}
            value={text}
            onChange={(event) => setMessage(event?.target?.value)}
          />
          <Button
            variant="contained"
            size="large"
            fullWidth={true}
            style={{ marginTop: "16px", color: "#1c1f22", background: "#fff" }}
            onClick={handleContact}
          >
            Send
          </Button>
        </Stack>
      </StyledSection>
      <StyledFooter>
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={mdUp ? 12.5 : 8}
        >
          <Typography fontSize={35} fontWeight={700} color="#ffffff">
            ArgusQuery.AI
          </Typography>
          <Typography
            fontSize={18}
            fontWeight={400}
            color="#ffffff"
            textAlign="center"
            mb={4}
            px={2.5}
          >
            Test version to understand customer preferences. Not actual product
          </Typography>
          <StyledButton
            variant="contained"
            startIcon={<Iconify icon="ph:play-fill" width={28} />}
            onClick={handleRedirectToLogin}
          >
            Try Now
          </StyledButton>
        </Stack>
        <Stack
          width="100%"
          flexDirection={mdUp ? "row" : "column"}
          alignItems={mdUp ? "center" : "flex-start"}
          justifyContent="space-between"
          gap={mdUp ? 0 : 2}
          borderTop="1px solid #3c3c3c"
          px={mdUp ? 6.25 : 2.5}
          pt={3}
          pb={2}
        >
          <Stack>
            <Typography
              fontSize={14}
              fontWeight={500}
              color="rgb(230, 230, 230)"
            >
              © ArgusIOT Inc
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              color="rgb(230, 230, 230)"
            >
              San Francisco, California
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography
              fontSize={14}
              fontWeight={500}
              color="rgba(255, 255, 255, .6)"
            >
              Contact Us:
            </Typography>
            <Typography
              component="a"
              href="mailto:solutions@argusquery.ai"
              fontSize={14}
              fontWeight={500}
              ml={0}
              color="rgba(255, 255, 255, .6)"
            >
              solutions@argusquery.ai
            </Typography>
          </Stack>
        </Stack>
      </StyledFooter>
    </StyledContainer>
  );
};

export default LandingPage;

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import TextField from "src/components/elements/Textfield";
import Button from "src/components/elements/Button";

const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputBase-root": {
    fontSize: 14,
    minWidth: 280,
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      minWidth: 224,
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: "#00000033",
    "&:hover": { borderColor: "#00000033" },
  },
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#00000033",
  },
}));
const StyledButton = styled(Button)({
  fontWeight: 400,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderColor: "#00000033",
});

const SearchField = ({
  id,
  name,
  type,
  size,
  placeholder,
  value,
  onChange,
  onSearch,
}) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <StyledTextField
        id={id}
        name={name}
        type={type}
        size={size}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <StyledButton variant="outlined" content="SEARCH" onClick={onSearch} />
    </Stack>
  );
};

SearchField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

SearchField.defaultProps = {
  id: "search-field",
  name: "search",
  type: "text",
  size: "small",
  disabled: false,
  onChange: () => null,
};

export default SearchField;

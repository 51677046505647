//@mui
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Checkbox from "../../../../components/elements/Checkbox";
import Button from "../../../../components/elements/Button";
import { useState } from "react";

const StyledContainer = styled(Box)(() => ({
  maxWidth: 500,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 24,
  paddingTop: 32,
  borderRadius: 12,
  backgroundColor: "#ffffff",
  transform: "translate(-50%, -50%)",
  border: "none",
}));

const DeleteFilePopup = ({ open, onClose, onConfirm }) => {
  const [value, setValue] = useState(false);

  return (
    <Modal open={open} onClose={onClose}>
      <StyledContainer>
        <Typography>
          Once deleted will no longer be able to access. Please select the level
          of cleanup you want while deleting a file.
        </Typography>
        <Stack flexDirection="column" mt={2}>
          <Checkbox
            size="small"
            label="Remove Extracted Files"
            checked={true}
          />
          <Checkbox
            size="small"
            label="Remove Embeddings"
            checked={value}
            onChange={() => setValue(!value)}
          />
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          pt={3}
        >
          <Button variant="outlined" content="Cancel" onClick={onClose} />
          <Button content="Delete File" onClick={() => onConfirm(value)} />
        </Stack>
      </StyledContainer>
    </Modal>
  );
};

export default DeleteFilePopup;

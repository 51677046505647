import PropTypes from "prop-types";
import MaterialIconButton from "@mui/material/IconButton";

const IconButton = ({ size, color, disabled, icon, onClick, ...props }) => {
  return (
    <MaterialIconButton
      size={size}
      color={color}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {icon}
    </MaterialIconButton>
  );
};

IconButton.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

IconButton.defaultProps = {
  size: "medium",
  disabled: false,
  icon: "",
  onClick: () => null,
};

export default IconButton;

import { isEmpty } from "lodash";
import Proptypes from "prop-types";
//@mui
import { styled } from "@mui/material/styles";

import MaterialTable from "src/components/elements/Table";
import Pagination from "src/components/elements/Pagination";

const Container = styled("div")({
  height: "calc(100% - 70px)",
});

const TableContainer = styled("div")({
  height: "calc(100% - 52px)",
  overflowY: "scroll",
});

const Table = ({
  loading,
  data,
  columns,
  stickyHeader,
  defaultEmptyValue,
  pagination,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <Container>
      <TableContainer className="table-container">
        <MaterialTable
          loading={loading}
          data={data}
          columns={columns}
          stickyHeader={stickyHeader}
          defaultEmptyValue={defaultEmptyValue}
        />
      </TableContainer>
      {!isEmpty(pagination) && !isEmpty(data) && (
        <Pagination
          count={pagination?.totalSize}
          page={pagination?.page}
          rowsPerPage={pagination?.size}
          totalCount={pagination?.totalPages}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </Container>
  );
};

Table.propTypes = {
  stickyHeader: Proptypes.bool,
  defaultEmptyValue: Proptypes.string,
};

Table.defaultProps = {
  stickyHeader: true,
  defaultEmptyValue: "",
};

export default Table;

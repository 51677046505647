import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Grid,
  Typography,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import configService from "src/services/configService";

const ConfigPromptForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const myProps = location.state;
  const [formData, setFormData] = useState({
    id: null,
    context: "",
    prompt: "",
    story: "",
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const options = [
    {
      id: 1,
      value: "sales",
      name: "Sales",
    },
    {
      id: 2,
      value: "product",
      name: "Product",
    },
    {
      id: 3,
      value: "dataStorage",
      name: "Data Storage",
    },
    {
      id: 4,
      value: "generic",
      name: "Generic",
    },
  ];

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleDropDown = (e) => {
    console.log(e);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const getConfigurationsById = async (id) => {
    try {
      const data = await configService.getPromptConfigsById(id);
      console.log("data", data);
      setFormData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (myProps?.id) {
      getConfigurationsById(myProps.id);
    }
  }, [myProps]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    console.log(formData);
    try {
      if (myProps?.id) {
        await configService.updatePromptConfig(formData);
      } else {
        await configService.createPromptConfig(formData);
      }
      navigate("/prompt_config/list", { replace: true });
      setMsg("Configuration added successfully");
      setOpen(true);
    } catch (error) {
      setDisable(false);
      console.log(error);
      setMsg(error?.message);
      setOpen(true);
    }
  };

  const generatePrompt = async () => {
    try {
      setDisableBtn(true);
      const data = await configService.generatePrompt(formData.story);
      console.log(data);
      setFormData({ ...formData, prompt: data?.data?.content });
      setDisableBtn(false);
    } catch (err) {
      console.log(err);
      setDisableBtn(false);
    }
  };
  return (
    <>
      <Helmet>
        <title> Add Configurations | Connect </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Prompt Configuration
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="dropdown-label">Context</InputLabel>
          <Select
            required
            labelId="dropdown-label"
            id="dropdown"
            label="Context"
            name="context"
            value={formData.context}
            onChange={(event) => handleDropDown(event)}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid item xs={12} sm={10}>
          <TextField
            required
            multiline
            rows={4}
            label="Story"
            name="story"
            value={formData.story}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Button
          disabled={disableBtn}
          variant="contained"
          color="primary"
          onClick={() => generatePrompt()}
        >
          Generate Prompt
        </Button>
        <Grid item xs={12} sm={10}>
          <TextField
            required
            multiline
            rows={4}
            label="Prompt"
            name="prompt"
            value={formData.prompt}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </Grid>
        ˝{" "}
        <Grid style={{ marginTop: "1rem" }} item xs={12} sm={6}>
          <Button
            disabled={disable}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default ConfigPromptForm;

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllCategoryAction,
  createNewCategoryAction,
  updateCategoryAction,
} from "./categoryAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
  meta: {},
};

const loadingActionState = [
  fetchAllCategoryAction.pending,
  createNewCategoryAction.pending,
  updateCategoryAction.pending,
];
const rejectedActionState = [
  fetchAllCategoryAction.rejected,
  createNewCategoryAction.rejected,
  updateCategoryAction.rejected,
];

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetCategory: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllCategoryAction.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload?.data ?? [];
        state.meta = action?.payload?.meta ?? {};
      })
      .addCase(createNewCategoryAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(updateCategoryAction.fulfilled, (state, action) => {
        state.status = "success";
      });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetCategory } = categorySlice.actions;
export {
  fetchAllCategoryAction,
  createNewCategoryAction,
  updateCategoryAction,
};
export default categorySlice.reducer;

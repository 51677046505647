import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { allFileTableColumns } from "../../config";
import { convertKiloBytes } from "../../../../utils/helper";
import { fetchFilesAction, resetFiles, useFilesSelector } from "../../slices";

import SearchField from "src/components/SearchField";
import Table from "src/pages/dashboard/components/Table";
import Tooltip from "src/components/elements/Tooltip";
import IconButton from "src/components/elements/IconButton";
import Iconify from "src/components/iconify";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const StyledLink = styled(Link)({
  fontSize: 14,
  fontWeight: 600,
  color: "#005544",
  textDecoration: "none",
  letterSpacing: "0.5px",
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const AllFilePage = () => {
  const dispatch = useDispatch();
  const { data, meta, status } = useFilesSelector();

  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    dispatch(fetchFilesAction({ page: 0, size: 10 }));

    return () => dispatch(resetFiles());
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const draftFiles = data?.map((item, index) => ({
        id: item?.id,
        sno:
          index + (meta?.page ?? 0) * (meta?.size ?? 0) < 9
            ? `0${index + 1 + (meta?.page ?? 0) * (meta?.size ?? 0)}`
            : index + 1 + (meta?.page ?? 0) * (meta?.size ?? 0),
        categoryName: item?.categoryName ?? "",
        count: item?.DocumentConfig?.length ?? 0,
        size: item?.DocumentConfig?.reduce(
          (aggr, curr) => aggr + curr?.size ?? 0,
          0
        ),
      }));

      setFiles(draftFiles);
    }
  }, [data]);

  useEffect(() => {
    const draftFilteredFiles = files?.filter((item) =>
      item?.categoryName?.toLowerCase()?.includes(searchString?.toLowerCase())
    );

    setFilteredFiles(draftFilteredFiles ?? []);
  }, [files, searchString]);

  const handleSearchOnChange = (event) => {
    const { value } = event?.target ?? {};

    setSearchString(value);
  };

  const handleOnPageChange = (event, newPage) => {
    dispatch(fetchFilesAction({ page: newPage, size: meta?.size }));
  };

  const handleOnRowsPerPageChange = (event) => {
    dispatch(
      fetchFilesAction({
        page: 0,
        size: parseInt(event.target.value, 10),
      })
    );
  };

  const renderProductCategoryLink = (value, item) => (
    <StyledLink to={`${item?.id}`}>{value}</StyledLink>
  );

  const renderSize = (value) => convertKiloBytes(value);

  allFileTableColumns?.forEach((column) => {
    if (isEqual(column?.id, "categoryName")) {
      column["value"] = renderProductCategoryLink;
    } else if (isEqual(column?.id, "size")) {
      column["value"] = renderSize;
    }
  });

  return (
    <StyledRoot>
      <Stack flexDirection="row" alignItems="center" mb={7}>
        <Typography fontSize={32} fontWeight={600} color="#0000008a">
          File Categories
        </Typography>
        <Tooltip arrow={true} placement="right-start" title="See all files.">
          <IconButton
            icon={
              <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
            }
          />
        </Tooltip>
      </Stack>
      <TableContainer>
        <Stack alignItems="flex-end" p={2}>
          <SearchField
            name="searchCategory"
            placeholder="Search by File Category Name"
            value={searchString}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Table
          loading={isEqual(status, "loading")}
          data={filteredFiles}
          columns={allFileTableColumns}
          pagination={meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
    </StyledRoot>
  );
};

export default AllFilePage;

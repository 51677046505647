import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import loginService from "../../../services/loginService";
import useResponsive from "../../../hooks/useResponsive";
import { AppStartupContext } from "src/context/AppStartup";
import { appTypes } from "src/constants/constants";
import { loginUserAction } from "src/slices/Authentication/AuthSlice";

import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------
const StyledTextWrapper = styled("p")(({ theme }) => ({
  color: isEqual(process.env.REACT_APP_NAME, "sandbox") ? "#000000" : "#009E4D",
  fontFamily: "Helvetica",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "26px",
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  width: "400px",
  "&.MuiTextField-root": {
    "& label": {
      top: "-5.5px",
      fontSize: 22,
      color: isEqual(process.env.REACT_APP_NAME, "sandbox")
        ? "#000000"
        : "#009E4D",
    },
    "& .MuiInput-root": {
      "& input": {
        height: 25,
        fontSize: 18,
      },
      "&:hover:not(.Mui-disabled, .Mui-error):before": {
        borderBottomColor: "#000000",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#A9D0BC",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      "& label": {
        fontSize: 20,
      },
      "& .MuiInput-root": {
        "& input": {
          height: 22,
          fontSize: 16,
        },
      },
    },
    // Form Error CSS
    "&.has-error": {
      "& label": {
        color: "#FF0000",
      },
      "& .MuiInput-root": {
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottomColor: "#FF0000",
        },
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "#FF0000",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#FF0000",
      },
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const CssLoadingButton = styled(LoadingButton)(({ theme }) => ({
  width: "max-content",
  borderRadius: "36px",
  boxShadow: "none",
  background: isEqual(process.env.REACT_APP_NAME, "sandbox")
    ? "#000000"
    : "#009E4D",
  padding: "12px 44px",
  "& span": {
    color: "#FFF",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  "&:hover": {
    background: isEqual(process.env.REACT_APP_NAME, "sandbox")
      ? "#000000"
      : "#009E4D",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 24px",
    marginTop: "48px!important",
    "& span": {
      fontSize: "19px",
    },
  },
}));

const Link = styled(Typography)({
  cursor: "pointer",
});

export default function LoginForm() {
  const smDown = useResponsive("down", "sm");
  const navigate = useNavigate();
  const appStartup = useContext(AppStartupContext);

  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async (_data, event) => {
    console.log("here", event);
    event.preventDefault();
    console.log(loginDetails);
    try {
      event.preventDefault();
      setDisable(true);
      if (loginDetails.email && loginDetails.password) {
        const loginRes = await loginService.loginUser({
          email: loginDetails.email.toLowerCase(),
          password: loginDetails.password,
        });
        console.log(loginRes);
        if (loginRes?.data.token) {
          const userDetails = loginRes?.data ?? {};
          loginService.doLogin(userDetails?.token);
          localStorage.setItem("auth", JSON.stringify(userDetails));

          const userType = loginService.getparamsFromCookie("userType");
          setAlertSeverity("success");
          setMsg("You have successfully logged in.");
          setOpen(true);
          const timeoutId = setTimeout(() => {
            const dashRoles = ["customerAdmin", "customeAdmin", "regionAdmin"];

            clearTimeout(timeoutId);

            if (isEqual(appStartup?.app, appTypes.sandbox)) {
              navigate("/sandbox/chat_type");
              return;
            }
            if (isEqual(userType, "searchUser")) {
              navigate("/search_user");
              return;
            }
            if (dashRoles.includes(userType)) {
              navigate("/dashboard", { replace: true });
              return;
            }
          }, 2000);
        } else {
          setDisable(false);
          setAlertSeverity("error");
          setMsg("Unsuccessful Login!");
          setOpen(true);
        }
      }
    } catch (err) {
      setDisable(false);
      setAlertSeverity("error");
      setMsg("Login Failed! Please check your credentials and try again!");
      setOpen(true);
      console.log(err);
    }
  };

  const textInputChange = (evt) => {
    const { name, value } = evt.target;

    setValue(name, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const handleForgetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      >
        <Alert severity={alertSeverity}>{msg}</Alert>
      </Snackbar>
      <div style={{ marginTop: smDown ? "64px" : "100px" }}>
        <Stack spacing={8}>
          <StyledTextWrapper>Login</StyledTextWrapper>
          <form onSubmit={handleSubmit(handleClick)} method="post">
            <Stack spacing={8}>
              <Stack spacing={4}>
                <CssTextField
                  {...register("email", {
                    required: "This is required.",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter a valid email.",
                    },
                  })}
                  required
                  id="login-email"
                  type="email"
                  variant="standard"
                  name="email"
                  label="Email address"
                  value={loginDetails.email}
                  onChange={(ev) => {
                    textInputChange(ev);
                  }}
                  className={errors.email && "has-error"}
                  helperText={
                    errors.email && (
                      <span className="form-error-text">
                        {errors.email.message}
                      </span>
                    )
                  }
                />
                <CssTextField
                  {...register("password", { required: true })}
                  required
                  id="login-password"
                  variant="standard"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={loginDetails.password}
                  onChange={textInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={errors.password && "has-error"}
                  helperText={
                    errors.password && (
                      <span className="form-error-text">
                        This field is required
                      </span>
                    )
                  }
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Link
                    fontSize={16}
                    variant="subtitle2"
                    onClick={handleForgetPassword}
                  >
                    Forgot password?
                  </Link>
                </Stack>
              </Stack>

              <CssLoadingButton
                id="login-btn"
                disable={disable ? "disabled" : undefined}
                size={smDown ? "small" : "default"}
                type="submit"
                variant="contained"
                onClick={handleSubmit(handleClick)}
              >
                <span>Login</span>
              </CssLoadingButton>
            </Stack>
          </form>
        </Stack>
      </div>
    </>
  );
}

import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import useResponsive from "src/hooks/useResponsive";
import { AppStartupContext } from "src/context/AppStartup";
import { appTypes } from "src/constants/constants";

import {
  LoginForm,
  SignupForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  EnterOtpForm,
} from "src/sections/auth/login";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  backgroundColor: theme.palette.background.paper,
}));

const StyledLeftSection = styled("div")(({ theme }) => ({
  flex: 1.3,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "48px",
  backgroundColor: theme.palette.background.paper,
  "& img": {
    height: "100%",
    objectFit: "contain",
  },
}));

const StyledContent = styled(Stack)(({ theme }) => ({
  flex: 1,
  margin: "0",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(6),
  overflowY: "scroll",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(6),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3),
  },
}));

const HeadlineText = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: '"Helvetica-Regular", Helvetica',
  color: "#000000",
  fontSize: "22px",
  fontWeight: 400,
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

const MainLogo = styled("img")(({ theme }) => ({
  height: "33px",
  width: "33.5px",
  objectFit: "cover",
  marginRight: "16px",
  [theme.breakpoints.down("sm")]: {
    height: "32px",
    width: "35px",
    marginRight: "12px",
  },
}));

const TextWrapper = styled("p")(({ theme }) => ({
  margin: 0,
  letterSpacing: 0,
  lineHeight: "normal",
  whiteSpace: "nowrap",
  fontFamily: '"Helvetica-Regular", Helvetica',
}));

const TextWrapperBold = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontFamily: '"Helvetica-Bold", Helvetica',
}));

const FootlineText = styled("p")(({ theme }) => ({
  fontSize: "19px",
  fontWeight: 400,
  margin: 0,
  color: "#000000",
  marginTop: 24,
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
  "& span": {
    color: "#009E4D",
    marginLeft: "8px",
    cursor: "pointer",
  },
}));
// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const appStartup = useContext(AppStartupContext);
  const location = useLocation();

  const navigateToSignup = () => {
    navigate("/signup");
  };

  const isLogin = ["/", "/login"]?.includes(location.pathname);

  const rendeFormComponent = (pathname) =>
    ({
      "/": <LoginForm />,
      "/login": <LoginForm />,
      "/signup": <SignupForm />,
      "/forgot-password": <ForgotPasswordForm />,
      "/reset-password": <ResetPasswordForm />,
      "/enter-otp": <EnterOtpForm />,
    }[pathname]);

  const renderPageTitle = (pathname) =>
    ({
      "/": "Login | Argus",
      "/login": "Login | Argus",
      "/signup": "Signup | Argus",
      "/forgot-password": "Forgot Password | Argus",
      "/reset-password": "Reset Password | Argus",
    }[pathname]);

  return (
    <>
      <Helmet>
        <title>{renderPageTitle(location?.pathname ?? "")}</title>
      </Helmet>
      <StyledRoot>
        {mdUp && (
          <StyledLeftSection>
            <img src="/assets/illustrations/login-sidebar-bg.png" alt="login" />
          </StyledLeftSection>
        )}
        <StyledContent>
          <HeadlineText>
            {[
              appTypes.sandbox,
              appTypes.uat,
              appTypes.local,
              appTypes.app,
            ]?.includes(appStartup?.app) ? (
              <>
                <MainLogo src="/assets/logo.png" alt="argus logo" />
                {appTypes.sandbox === appStartup?.app ? (
                  <TextWrapper>
                    {"ArgusQuery "}
                    <TextWrapperBold>{"Sandbox"}</TextWrapperBold>
                  </TextWrapper>
                ) : (
                  <TextWrapper>
                    {"ArgusQuery"}
                    <TextWrapperBold>{".ai"}</TextWrapperBold>
                  </TextWrapper>
                )}
              </>
            ) : (
              <>
                <MainLogo
                  src="/assets/icons/schneider-icon@2x.png"
                  alt="schneider logo"
                />
                <TextWrapper>
                  <TextWrapperBold>Schneider</TextWrapperBold>
                  {" ArgusQuery.ai"}
                </TextWrapper>
              </>
            )}
          </HeadlineText>
          {rendeFormComponent(location?.pathname ?? "")}
          {isLogin && isEqual(appStartup?.app, appTypes.sandbox) && (
            <FootlineText>
              Don't have an account yet?
              <span onClick={navigateToSignup}>Create an account.</span>
            </FootlineText>
          )}
        </StyledContent>
      </StyledRoot>
    </>
  );
}

import PropTypes from "prop-types";
import MuiTooltip from "@mui/material/Tooltip";

const Tooltip = ({ title, children, arrow, placement, ...props }) => {
  return (
    <MuiTooltip title={title} arrow={arrow} placement={placement} {...props}>
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  arrow: PropTypes.string,
  placement: PropTypes.string,
};

Tooltip.defaultProps = {
  title: "",
};

export default Tooltip;

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import MaterialSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Chip from "../Chip";

const StyledMenuItem = styled(MenuItem)({
  "&.Mui-selected": {
    color: "#fff",
    background: "rgb(32 101 209 / 70%)",
  },
});

const Select = ({
  id,
  variant,
  name,
  placeholder,
  options,
  size,
  value,
  error,
  helperText,
  disabled,
  fullWidth,
  multiple,
  onChange,
  ...props
}) => {
  const renderValue = (selected) =>
    (selected?.toString()?.length ?? 0) === 0 ? (
      <Typography fontSize={14} fontWeight={400}>
        {placeholder}
      </Typography>
    ) : multiple ? (
      <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap={1}>
        {options?.map(
          (option) =>
            value?.includes(option?.value) && (
              <Chip label={option?.label ?? ""} />
            )
        )}
      </Stack>
    ) : (
      options?.find((option) => option?.value === selected)?.label
    );

  return (
    <FormControl error={error} disabled={disabled}>
      <MaterialSelect
        id={id}
        variant={variant}
        name={name}
        size={size}
        value={value}
        fullWidth={fullWidth}
        onChange={onChange}
        displayEmpty
        renderValue={renderValue}
        multiple={multiple}
        {...props}
      >
        {options?.map((option) => (
          <StyledMenuItem value={option?.value}>
            {option?.label ?? ""}
          </StyledMenuItem>
        ))}
      </MaterialSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.any.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  variant: "outlined",
  placeholder: "",
  size: "small",
  disabled: false,
  error: false,
  helperText: "",
  fullWidth: true,
  onChange: () => null,
};

export default Select;

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllUsersAction,
  createUserAction,
  updateUserAction,
} from "./userAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
  meta: {},
};

const loadingActionState = [
  fetchAllUsersAction.pending,
  createUserAction.pending,
  updateUserAction.pending,
];
const rejectedActionState = [
  fetchAllUsersAction.rejected,
  createUserAction.rejected,
  updateUserAction.rejected,
];

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUsers: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsersAction.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload?.data ?? [];
        state.meta = action?.payload?.meta ?? {};
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(updateUserAction.fulfilled, (state, action) => {
        state.status = "success";
      });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetUsers } = userSlice.actions;
export { fetchAllUsersAction, createUserAction, updateUserAction };
export default userSlice.reducer;

import FormControlLabel from "@mui/material/FormControlLabel";
import MaterialSwitch from "@mui/material/Switch";

const Switch = ({
  label,
  labelPlacement,
  name,
  size,
  disabled,
  checked,
  value,
  onChange,
  ...props
}) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={
        <MaterialSwitch
          name={name}
          size={size}
          disabled={disabled}
          checked={checked}
          value={value}
          onChange={onChange}
          {...props}
        />
      }
    />
  );
};

export default Switch;

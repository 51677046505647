import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllApplicationAction,
  createApplicationAction,
  updateApplicationAction,
  fetchAllCategoryAction,
  createNewCategoryAction,
  fetchFilesAction,
  fetchAllModuleAction,
  updateModuleAction,
  fetchAllRegionsAction,
  createNewRegionAction,
  updateRegionAction,
  fetchAllRolesAction,
  fetchAllUsersAction,
  createUserAction,
  uploadMultipleFilesAction,
  updateUserAction,
} from "../index";

const initialState = {
  message: "",
  type: "",
};

const rejectedActionState = [
  fetchAllApplicationAction.rejected,
  createApplicationAction.rejected,
  updateApplicationAction.rejected,
  fetchAllCategoryAction.rejected,
  createNewCategoryAction.rejected,
  fetchFilesAction.rejected,
  fetchAllModuleAction.rejected,
  updateModuleAction.rejected,
  fetchAllRegionsAction.rejected,
  createNewRegionAction.rejected,
  updateRegionAction.rejected,
  fetchAllRolesAction.rejected,
  fetchAllUsersAction.rejected,
  createUserAction.rejected,
  uploadMultipleFilesAction.rejected,
  updateUserAction.rejected,
];

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    resetSnackbar: () => {
      return initialState;
    },
    setSnackbar: (state, action) => {
      return action?.payload;
    },
  },
  extraReducers(builder) {
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.message =
          action?.payload?.data?.message ?? "Something went wrong!";
        state.type = "error";
      });
    });
  },
});

export const { resetSnackbar, setSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;

//@mui
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import Iconify from "../iconify/Iconify";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  height: "90%",
  padding: "24px 0",
  borderRadius: 12,
  backgroundColor: "#202225",
  transform: "translate(-50%, -50%)",
  border: "none",
  ".MuiButtonBase-root": {
    position: "absolute",
    right: 16,
    top: 16,
    zIndex: 4,
  },
}));

const Popup = ({ open, width, height, children, onClose }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="adduser-modal-title"
      aria-describedby="adduser-modal-description"
    >
      <StyledContainer width={width} height={height}>
        <IconButton onClick={onClose}>
          <Iconify icon="carbon:close-filled" width={28} height={28} />
        </IconButton>
        {children}
      </StyledContainer>
    </Modal>
  );
};

export default Popup;

import PropTypes from "prop-types";
import MaterialTextField from "@mui/material/TextField";

const TextField = ({
  id,
  name,
  placeholder,
  type,
  size,
  disabled,
  value,
  helperText,
  error,
  onChange,
  ...props
}) => {
  return (
    <MaterialTextField
      id={id}
      name={name}
      placeholder={placeholder}
      type={type}
      size={size}
      disabled={disabled}
      value={value}
      helperText={helperText}
      error={error}
      onChange={onChange}
      {...props}
    />
  );
};

MaterialTextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

MaterialTextField.defaultProps = {
  type: "text",
  size: "medium",
  disabled: false,
  helperText: "",
  error: false,
  onChange: () => null,
};

export default TextField;

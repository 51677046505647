import { combineReducers } from "@reduxjs/toolkit";

import resourceSlice from "./resource/resourceSlice";
import roleReducer from "./role/roleSlice";
import regionReducer from "./regions/regionsSlice";
import categorySlice from "./category/categorySlice";
import applicationSlice from "./application/applicationSlice";
import moduleSlice from "./module/moduleSlice";
import userReducer from "./users/userSlice";
import fileReducer from "./files/filesSlice";
import snackbarReducer from "./snackbar/snackbarSlice";

const dashboard = combineReducers({
  resource: resourceSlice,
  role: roleReducer,
  regions: regionReducer,
  category: categorySlice,
  application: applicationSlice,
  module: moduleSlice,
  users: userReducer,
  files: fileReducer,
  snackbar: snackbarReducer,
});

export * from "./resource/resourceSlice";
export * from "./resource/resourceSelector";
export * from "./role/roleSlice";
export * from "./role/roleSelector";
export * from "./regions/regionsSlice";
export * from "./regions/regionSelector";
export * from "./category/categorySlice";
export * from "./category/categorySelector";
export * from "./application/applicationSlice";
export * from "./application/applicationSelector";
export * from "./module/moduleSlice";
export * from "./module/moduleSelector";
export * from "./users/userSlice";
export * from "./users/userSelector";
export * from "./files/filesSlice";
export * from "./files/filesSelector";
export * from "./snackbar/snackbarSlice";
export * from "./snackbar/snackbarSelector";

export default dashboard;

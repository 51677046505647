import { useState } from "react";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import useResponsive from "src/hooks/useResponsive";
import { sandboxs } from "src/constants/constants";
import { convertFullNameToAbbrevate } from "src/utils/helper";
import Iconify from "src/components/iconify/Iconify";

const StyedLabel = styled("div")(() => ({
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "0.5px",
  color: "#cbcbcb",
}));

const CSSTextField = styled(TextField)(() => ({
  ".MuiInputBase-input": {
    fontSize: 14,
    padding: "6px 12px",
    color: "#ffffff",
  },
}));

const StyledSelect = styled(Select)(() => ({
  color: "#ffffff",
  padding: "8px 12px",
  minHeight: "55px",
  border: "1px solid #152B35",
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    lineHeight: "20px",
    padding: 0,
  },
  "& .MuiSelect-icon": {
    color: "#FFF",
  },
}));

const UploadSection = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 0",
  borderTop: `1px solid ${theme?.palette?.colorTheme?.theme4?.primaryBorder}`,
  borderBottom: `1px solid ${theme?.palette?.colorTheme?.theme4?.primaryBorder}`,
  "& span": {
    fontSize: 14,
    fontWeight: 400,
    color: theme?.palette?.colorTheme?.theme4?.secondaryText,
  },
  "& .file": {
    width: "24px",
    height: "24px",
    color: theme?.palette?.colorTheme?.theme4?.ternaryBackground,
  },
  "& .clip": {
    width: "24px",
    height: "24px",
    color: "#A9A9A9",
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  flex: 1,
  width: "100%",
  padding: "8px 18px",
  fontSize: "14px",
  fontWeight: 400,
  color: theme?.palette?.colorTheme?.theme4?.primaryText,
  letterSpacing: "0.5px",
  boxShadow: "none",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.secondaryBackground,
  "&:hover": {
    backgroundColor: theme?.palette?.colorTheme?.theme4?.secondaryBackground,
  },
}));

const Form = ({
  name,
  persona,
  personaOptions = [],
  queryInProgress,
  onChange,
}) => {
  const mdUp = useResponsive("up", "lg");
  const moduleDetails = JSON.parse(
    localStorage.getItem("selectedModule") ?? ""
  );

  const [isChanged, setIsChanged] = useState(false);

  const handleOnReset = () => {
    const auth = JSON.parse(localStorage?.getItem("auth") ?? "");
    onChange({ name: "name", value: auth?.name ?? "" });
    setIsChanged(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event?.target ?? {};
    setIsChanged(true);
    onChange({ name, value });
  };

  const renderPersonaTitle = (persona) => {
    const personaArr = persona?.split("_");
    let transformedText = "";
    personaArr?.forEach((text) => {
      transformedText = `${transformedText}${text
        ?.charAt(0)
        ?.toUpperCase()}${text?.substr(1)?.toLowerCase()} `;
    });
    return transformedText;
  };

  const renderValue = () => (
    <Stack gap={0.2}>
      <Typography color="#FFFFFF" fontSize={14} letterSpacing={0.5}>
        {renderPersonaTitle(persona)}
      </Typography>
      <Typography fontSize={10} color="#FFFFFF" letterSpacing={0.5}>
        {personaOptions?.find((item) => isEqual(item?.persona, persona))
          ?.personaDesc ?? ""}
      </Typography>
    </Stack>
  );

  return (
    <Stack flexDirection="column" p={3} gap={4}>
      <Stack flexDirection="column" gap={1}>
        <StyedLabel>Name</StyedLabel>
        <CSSTextField
          id="guest-name"
          name="name"
          placeholder="Enter a user friendly name"
          value={name}
          onChange={handleOnChange}
        />
      </Stack>
      <Stack flexDirection="column" gap={1}>
        <StyedLabel>Persona</StyedLabel>
        <StyledSelect
          id="persona"
          displayEmpty
          renderValue={renderValue}
          disabled={queryInProgress}
          onChange={(event) =>
            onChange({ name: "persona", value: event?.target?.value })
          }
        >
          {personaOptions?.map((persona, index) => (
            <MenuItem key={index} id="persona-option" value={persona?.persona}>
              <Stack gap={0.2}>
                <Typography fontSize={14} letterSpacing={0.5}>
                  {renderPersonaTitle(persona?.persona)}
                </Typography>
                <Typography fontSize={10} letterSpacing={0.5}>
                  {persona?.personaDesc ?? ""}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </StyledSelect>
      </Stack>
      <Stack flexDirection="column" gap={1}>
        <StyedLabel style={{ paddingBottom: "8px", lineHeight: "32px" }}>
          File
        </StyedLabel>
        <UploadSection onClick={() => console.log("select file")}>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <Iconify className="file" icon="material-symbols:folder" />
            <span>Add your files</span>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Iconify className="clip" icon="heroicons-solid:paper-clip" />
            <Typography fontSize={14} color="#A9A9A9">
              Add
            </Typography>
          </Stack>
        </UploadSection>
      </Stack>
      <Stack gap={2} maxHeight={160} style={{ overflowY: "scroll" }}>
        {moduleDetails?.documents?.map((document) => (
          <Stack
            key={document?.docName}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            pl={1}
          >
            <Typography fontSize={12} color="#A9A9A9">
              {convertFullNameToAbbrevate(document?.docName ?? "", 30)}
            </Typography>
            <Typography fontSize={12} color="#A9A9A9">
              {document?.fileSize ?? ""}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack
        style={{ cursor: !isChanged ? "not-allowed" : "" }}
        flexDirection={mdUp ? "row" : "column"}
        alignItems="center"
        gap={2}
      >
        <SaveButton
          variant="contained"
          disabled={!isChanged}
          onClick={handleOnReset}
        >
          Revert Changes
        </SaveButton>
        <SaveButton
          id="save-btn"
          variant="contained"
          disabled={!isChanged}
          onClick={() => setIsChanged(false)}
        >
          Save
        </SaveButton>
      </Stack>
    </Stack>
  );
};

export default Form;

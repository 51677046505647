// @mui
import { GlobalStyles as MUIGlobalStyles } from "@mui/material";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
        },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
        },
        body: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        img: {
          display: "block",
          maxWidth: "100%",
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          backgroundColor: "transparent",
        },
        ".form-error-text": {
          color: "red",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          width: "4px",
          backgroundColor: "#c3c3c3",
        },
        "&::-webkit-scrollbar-thumb": {
          width: "4px",
          borderRadius: "10px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-track": {
          width: "4px",
          backgroundColor: "transparent",
        },
        "@keyframes blink": {
          "0%, 50%": {
            opacity: 1,
          },
          "50.1%, 100%": {
            opacity: 0,
          },
        },
        "@keyframes animloader": {
          "0%": {
            transform: "scale(0)",
            opacity: "1",
          },
          "100%": {
            transform: "scale(1)",
            opacity: "0",
          },
        },
      }}
    />
  );

  return inputGlobalStyles;
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe#webpack-dev-server-client-overlay{display:none!important}
.display_block {
  display: none;
}

.download_display {
  display: flex;
  flex-direction: row-reverse;
}

.floatbutton{
  border-radius: 0px;
  height: 160px;
  width: 160px;
  /* background-color: rgb(236, 19, 51);
  color: white; */
}

.dashbaord_float {
  margin-top: auto;
}

.graph-dim {
  width:100%;
  height: 50%;

}

.upload_file {
  margin: auto;
  margin-top: 20%;
}

.upload-button {
  margin-left: 45%;
  margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/pages/common.css"],"names":[],"mappings":"AAAA,yCAAyC,sBAAsB;AAC/D;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ;iBACe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;;AAEb;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["iframe#webpack-dev-server-client-overlay{display:none!important}\n.display_block {\n  display: none;\n}\n\n.download_display {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.floatbutton{\n  border-radius: 0px;\n  height: 160px;\n  width: 160px;\n  /* background-color: rgb(236, 19, 51);\n  color: white; */\n}\n\n.dashbaord_float {\n  margin-top: auto;\n}\n\n.graph-dim {\n  width:100%;\n  height: 50%;\n\n}\n\n.upload_file {\n  margin: auto;\n  margin-top: 20%;\n}\n\n.upload-button {\n  margin-left: 45%;\n  margin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const PhoneNumberField = ({ name, value, onChange, ...props }) => {
  return (
    <PhoneInput
      defaultCountry="in"
      value={value}
      onChange={(value) => onChange({ target: { name, value } })}
      {...props}
    />
  );
};

export default PhoneNumberField;

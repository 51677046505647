import { useState, useEffect, Fragment } from "react";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { isEmpty, isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Divider from "@mui/material/Divider";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import searchService from "src/services/search";
import ChatHistoryItem from "src/pages/sandbox/components/ChatHistoryItem";

const ChatButton = styled(Button)(({ theme }) => ({
  width: "calc(100% - 64px)",
  color: theme?.palette?.common?.white,
  padding: "8px 16px",
  margin: "0 32px 24px 32px",
  borderColor: theme?.palette?.colorTheme?.theme1?.bg2,
  "&:hover": {
    borderColor: theme?.palette?.colorTheme?.theme1?.bg2,
    background: "rgba(145, 158, 171, 0.25)",
  },
}));

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  padding: 10,
  ".MuiIconButton-root": {
    color: "#fff",
  },
  "&:hover,&.active": {
    background: "rgba(145, 158, 171, 0.25)",
  },
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  minWidth: "36px",
  marginRight: "8px",
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledNavItemText = styled(ListItemText)({
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const CSSTextField = styled(TextField)({
  flex: 1,
  "& input": {
    fontSize: 14,
    color: "#FFFFFF",
    padding: "4px 8px",
  },
  "& fieldset": {
    border: "2px solid #FFF !important",
  },
});

const StyledSkeleton = styled(Skeleton)(() => ({
  background: "rgba(145, 158, 171, 0.25)",
}));

const History = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const moduleId = location?.state?.id;
  const chatType = location?.state?.chatType;
  const [isLoading, setIsLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState({});

  useEffect(() => {
    handleFetchChatHistory(moduleId);
  }, []);

  const handleFetchChatHistory = (moduleId) => {
    setIsLoading(true);
    searchService
      .getChatHistory(moduleId)
      .then((response) =>
        handleSortChatHistory(response?.data?.conversation ?? [])
      )
      .catch((error) =>
        setMessage({
          type: "error",
          text: error?.message ?? "Something went wrong!",
        })
      )
      .finally(() => setIsLoading(false));
  };

  const handleSortChatHistory = (chatHistory = []) => {
    const draftChatHistory = {
      Today: [],
      Yesterday: [],
      "Previous 7 Days": [],
      Older: [],
    };

    chatHistory?.forEach((chatHistoryItem) => {
      const chatDate = new Date(chatHistoryItem?.createdAt);
      if (chatHistoryItem?.Questions[0]?.question) {
        const today = new Date();
        const tempDate = new Date();
        const yesterday = new Date(tempDate?.setDate(tempDate?.getDate() - 1));
        const lastWeek = new Date(tempDate?.setDate(tempDate?.getDate() - 7));

        if (
          chatDate?.getDate() === today?.getDate() &&
          chatDate?.getMonth() === today?.getMonth() &&
          chatDate?.getFullYear() === today?.getFullYear()
        ) {
          draftChatHistory["Today"].push(chatHistoryItem);
        } else if (
          chatDate?.getDate() === yesterday?.getDate() &&
          chatDate?.getMonth() === yesterday?.getMonth() &&
          chatDate?.getFullYear() === yesterday?.getFullYear()
        ) {
          draftChatHistory["Yesterday"].push(chatHistoryItem);
        } else if (
          chatDate?.getDate() >= lastWeek?.getDate() &&
          chatDate?.getMonth() >= lastWeek?.getMonth() &&
          chatDate?.getFullYear() >= lastWeek?.getFullYear()
        ) {
          draftChatHistory["Previous 7 Days"].push(chatHistoryItem);
        } else {
          draftChatHistory["Older"].push(chatHistoryItem);
        }
      }
    });

    setChatHistory(draftChatHistory);
  };

  const handleUpdateChatTitle = async (id, convoName) =>
    await searchService
      ?.updateChatTitle(id, { convoName })
      .then((response) => {
        setMessage({
          type: "success",
          text: "Chat Title updated successfully.",
        });
        return response;
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error?.message ?? "Something went wrong!",
        });
        return error;
      });

  const handleDeleteConversation = async (id) =>
    await searchService
      .deleteChat(id)
      .then(() => {
        setMessage({
          type: "success",
          text: "Chat deleted successfully.",
        });
        handleFetchChatHistory(moduleId);
        navigate("/sandbox/chat");
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error?.message ?? "Something went wrong!",
        });
      });

  const handleNewChatOnClick = () =>
    navigate("/sandbox/chat", {
      state: { chatType, id: moduleId },
    });

  const handleSnackClose = () => setMessage({});

  return (
    <>
      <Snackbar
        open={!isEmpty(message)}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={message?.type}>{message?.text}</Alert>
      </Snackbar>
      <Stack flexDirection="column" py={3}>
        <ChatButton
          variant="outlined"
          fullWidth={true}
          startIcon={<AddCommentOutlinedIcon />}
          disabled={isLoading}
          onClick={handleNewChatOnClick}
        >
          New Chat
        </ChatButton>
        <List disablePadding sx={{ px: 1 }}>
          {isLoading
            ? [1, 2, 3]?.map((item) => (
                <Fragment key={item}>
                  <Divider />
                  <Stack px={1} my={2.5} gap={3}>
                    <StyledSkeleton
                      variant="rounded"
                      width="30%"
                      height="14px"
                    />
                    <StyledSkeleton
                      variant="rounded"
                      width="100%"
                      height="24px"
                    />
                    <StyledSkeleton
                      variant="rounded"
                      width="100%"
                      height="24px"
                    />
                    <StyledSkeleton
                      variant="rounded"
                      width="100%"
                      height="24px"
                    />
                  </Stack>
                </Fragment>
              ))
            : Object.keys(chatHistory)?.map(
                (dataGroup) =>
                  chatHistory?.[dataGroup]?.length > 0 && (
                    <Fragment key={dataGroup}>
                      <Divider />
                      <Typography
                        pl={3}
                        mt={2.5}
                        mb={0.5}
                        fontSize={13}
                        color="#919EABCC"
                      >
                        {dataGroup}
                      </Typography>
                      <Stack gap={1} px={1} mb={1.5}>
                        {chatHistory?.[dataGroup]?.map(
                          (chat) =>
                            chat?.Questions[0]?.question && (
                              <ChatHistoryItem
                                chat={chat}
                                onDelete={handleDeleteConversation}
                                onUpdate={handleUpdateChatTitle}
                              />
                            )
                        )}
                      </Stack>
                    </Fragment>
                  )
              )}
        </List>
      </Stack>
    </>
  );
};

export default History;

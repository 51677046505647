import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
//component
import Iconify from "src/components/iconify/Iconify";

import {
  resetRegions,
  resetCategory,
  resetApplication,
  resetModule,
  resetFiles,
  resetUsers,
} from "../../slices";
import loginService from "../../../../services/loginService";
import useResponsive from "src/hooks/useResponsive";

import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";

const HEADER_MOBILE = 48;
const HEADER_DESKTOP = 56;

const StyledRoot = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: theme?.palette?.colorTheme?.theme1?.bg1,
  boxShadow: "none",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 270px)`,
      marginLeft: `370px`,
    },
  }),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: "Helvetica",
  fontStyle: "normal",
  fontWeight: "400",
  color: "#FFFFFF",
  borderColor: "#FFFFFF",
  "&:hover": {
    borderColor: "#FFFFFF",
  },
}));

const StyledMenuItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "400",
  minWidth: "150px",
  "& svg": {
    color: "#F60808",
    width: "24px",
    height: "24px",
    marginRight: "16px",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  minHeight: HEADER_MOBILE,
  boxShadow: "0 7px 9px 0 rgba(0,0,0,.2)",
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 4.5),
  },
}));

const HeadlineText = styled("div")(({ theme }) => ({
  fontSize: "22px",
  fontWeight: 400,
  color: "#CBCBCB",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
}));

const MainLogo = styled("img")(() => ({
  height: "28px",
  width: "auto",
  objectFit: "cover",
  marginRight: "12px",
}));

const TextWrapper = styled("p")(() => ({
  margin: 0,
  letterSpacing: 0,
  lineHeight: "normal",
  whiteSpace: "nowrap",
}));

const TextWrapperBold = styled("span")(() => ({
  fontWeight: 700,
}));

const Divider = styled("div")({
  width: "100%",
  height: 1,
  background: "#dcdcdc",
  marginTop: 8,
});

const Header = ({ openNav, onToggleNav, setOpenInstructions }) => {
  const mdUp = useResponsive("up", "md");

  const handleOnNavigate = () => onToggleNav();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  console.log("localStorageDashboard", localStorage?.getItem("auth"));
  const authDetails = localStorage?.getItem("auth")
    ? JSON.parse(localStorage?.getItem("auth") ?? "")
    : {};

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSearchUser = () => {
    window.open(`${window.location.origin}/search_user/chat_type`, "_blank");
  };

  const handleOpenAccountDetails = () => navigate("account-details");

  const logOutUser = (e) => {
    loginService.dologout();
    dispatch(resetRegions());
    dispatch(resetCategory());
    dispatch(resetApplication());
    dispatch(resetModule());
    dispatch(resetFiles());
    dispatch(resetUsers());
    navigate("/login");
  };

  return (
    <StyledRoot position="fixed" open={openNav}>
      <StyledToolbar>
        <Stack flexDirection="row" alignItems="center">
          {onToggleNav && (
            <IconButton
              id="hamburger-btn"
              icon={<Iconify icon="mingcute:menu-fill" width={24} />}
              onClick={onToggleNav}
            />
          )}
          <HeadlineText onClick={handleOnNavigate}>
            <MainLogo src="/assets/logo-light.png" alt="argus logo" />
            {mdUp && (
              <TextWrapper>
                {"ArgusQuery"}
                <TextWrapperBold>.ai</TextWrapperBold>
              </TextWrapper>
            )}
          </HeadlineText>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={mdUp ? 2 : 1}>
          <IconButton
            icon={
              <Iconify icon="octicon:question-24" color="#fff" width={20} />
            }
            onClick={() => setOpenInstructions(true)}
          />
          {mdUp ? (
            <StyledButton
              variant="outlined"
              size="small"
              content="Chat View"
              endIcon={<Iconify icon="akar-icons:link-out" width={14} />}
              onClick={handleOpenSearchUser}
            />
          ) : (
            <IconButton
              icon={
                <Iconify icon="akar-icons:link-out" color="#fff" width={14} />
              }
              onClick={handleOpenSearchUser}
            />
          )}
          <Button
            variant="text"
            content={
              mdUp && (
                <Stack flexDirection="column" alignItems="flex-start" mr={1}>
                  <Typography fontSize={14} fontWeight={400} color="#abacb9">
                    {authDetails?.name}
                  </Typography>
                  <Typography fontSize={12} fontWeight={400} color="#abacb9">
                    {authDetails?.Account?.account_name ?? ""}
                  </Typography>
                </Stack>
              )
            }
            startIcon={<Avatar sx={{ width: 35, height: 35 }} />}
            endIcon={mdUp && <Iconify icon="formkit:down" color="#ffffff" />}
            onClick={handleOpenMenu}
          />
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <StyledMenuItem onClick={handleOpenAccountDetails}>
                Account details
              </StyledMenuItem>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <StyledMenuItem onClick={(ev) => logOutUser(ev)}>
                <PowerSettingsNewIcon fontSize="28" />
                <span>Sign Out</span>
              </StyledMenuItem>
            </MenuItem>
          </Menu>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};

export default Header;

export const BASEURL = process.env.REACT_APP_BASE_URL;
// export const BASEURL = "https://uat-backend.argusquery.ai";

export const chatSuggestions = [
  {
    id: 1,
    suggestions: [
      {
        display: "Compare",
        text: "Compare product value proposition for ",
        description:
          "Compare products & specific capabilities. e.g. Compare: HeatTag and VESDA-E VES. Output as a table.",
      },
      {
        display: "Technical Specifications",
        text: "List technical specifications for ",
        description:
          "To learn details about specific products e.g. Technical Specifications: HeatTag",
      },
    ],
  },
  {
    id: 2,
    suggestions: [
      {
        display: "Make a case",
        text: "Make a case for heattag versus other products for a health facility in India ",
        description: "",
      },
      {
        display: "Generate",
        text: "Generate a value proposition for heattag using insurance information and firesafety code in Indian hospitals ",
        description: "",
      },
      {
        display: "Pitch",
        text: "Pitch value proposition of HeatTag for a consultant representing a hospital in India.",
        description: "",
      },
    ],
  },
  {
    id: 3,
    suggestions: [
      {
        display: "Complete Pitch",
        text: "Create a pitch for a C suite executive of an Indian hospital for HeatTag.",
        description: "",
      },
      {
        display: "Elevator Pitch",
        text: "Create an elevator pitch for C suite executive of an Indian hospital for HeatTag.",
        description: "",
      },
    ],
  },
];

export const appTypes = {
  demo: "demo",
  admin: "admin",
  sandbox: "sandbox",
  local: "localhost",
  schneider: "schneider",
  uat: "uat",
  app: "app",
};

export const sandboxs = [
  {
    id: 1,
    accountId: "1",
    module_name: "Demo Sandbox 1",
    modeule_desc:
      "Query internal documents, external references, competitive information, local conditions, standards, product service information.",
    isActive: true,
    documents: [
      {
        fileName: "MDT (Medtronic plc.)  (10-Q) 2023-11-30.docx",
        fileSize: "1.06 MB",
      },
      {
        fileName: "reveal-linq-syncope-tap-201708879EN.pptx",
        fileSize: "2.67 MB",
      },
      {
        fileName: "Earnings-Presentation-FY24Q2-Final.pdf",
        fileSize: "2.67 MB",
      },
      {
        fileName: "reveal-system-overview-deck-201700319b-en.ppt",
        fileSize: "4.7 MB",
      },
      {
        fileName: "2023-11-MDT-Post-Q2-FY24-IR-Handout.pdf",
        fileSize: "2.94 MB",
      },
      {
        fileName: "MDT (Medtronic plc.)  (10-Q) 2023-11-30.xlsx",
        fileSize: "96.7 KB",
      },
      {
        fileName: "cas-clinical-evidence-for-pediatric-focal-cryo-en.pdf",
        fileSize: "657 KB",
      },
      {
        fileName: "crhf-afs-procedure-monitoring-rvu-guide.pdf",
        fileSize: "503 KB",
      },
      {
        fileName:
          "SURGICAL METHOD AND APPARATUS FOR Treating Atrial fibrillation.pdf",
        fileSize: "1.2 MB",
      },
      {
        fileName: "crhf-cardiac-device-procedure-codes.pdf",
        fileSize: "118 KB",
      },
      {
        fileName:
          "Exhibit 99.1 - FY24 Q2 Earnings Release - Final ERT Schedules.pdf",
        fileSize: "238 KB",
      },
      {
        fileName: "freezor-spec-sheet-en.pdf",
        fileSize: "261 KB",
      },
      {
        fileName: "cas-freezor-family-sell-sheet.pdf",
        fileSize: "366 KB",
      },
      {
        fileName: "freezor-and-freezor-xtra-sell-sheet.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "freezor-spec-sheet-en.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "MechanismsofCryoablation2014.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "Summary aof safety and Effectivness Data FDA Freezor.pdf",
        fileSize: "218 KB",
      },
      {
        fileName:
          "SURGICAL METHOD AND APPARATUS FOR Treating Atrial fibrillation.pdf",
        fileSize: "218 KB",
      },
      {
        fileName:
          "Best Practice Guide for Cryoballoon Ablation in Atrial Fib.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "cas-coding-corner-cardiac-catheter-ablation-update.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "cas-reimbursement-guide.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "MDT (Medtronic plc.)  (10-K) 2023-06-22.pdf_.pdf",
        fileSize: "218 KB",
      },
      {
        fileName: "reveal-linq-syncope-tap-201708879EN.ppt",
        fileSize: "218 KB",
      },
      {
        fileName: "reveal-system-overview-deck-201700319b-en.pdf",
        fileSize: "218 KB",
      },
    ],
  },
];

export const argusQueryVideoThubnail =
  "https://i.vimeocdn.com/video/1774780932-9902632144fa71649d0cd156a0a5829fca095db32633c790bc9ad7b83e420570-d?mw=1600&mh=900&q=70";

import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
// @mui
import { Stack, TextField, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// components
import loginService from "../../../services/loginService";
import Iconify from "../../../components/iconify";

import useResponsive from "../../../hooks/useResponsive";

import { loginUserAction } from "src/slices/Authentication/AuthSlice";
import { AppStartupContext } from "src/context/AppStartup";
import { appTypes } from "src/constants/constants";

// ----------------------------------------------------------------------
const StyledTextWrapper = styled("p")(({ theme }) => ({
  color: isEqual(process.env.REACT_APP_NAME, "sandbox") ? "#000000" : "#009E4D",
  fontFamily: "Helvetica",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  marginBottom: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: "26px",
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  width: "400px",
  "&.MuiTextField-root": {
    "& label": {
      top: "-5.5px",
      fontSize: 22,
      color: isEqual(process.env.REACT_APP_NAME, "sandbox")
        ? "#000000"
        : "#009E4D",
    },
    "& .MuiInput-root": {
      "& input": {
        height: 25,
        fontSize: 18,
      },
      "&:hover:not(.Mui-disabled, .Mui-error):before": {
        borderBottomColor: "#000000",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#A9D0BC",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      "& label": {
        fontSize: 20,
      },
      "& .MuiInput-root": {
        "& input": {
          height: 22,
          fontSize: 16,
        },
      },
    },
    // Form Error CSS
    "&.has-error": {
      "& label": {
        color: "#FF0000",
      },
      "& .MuiInput-root": {
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottomColor: "#FF0000",
        },
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "#FF0000",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#FF0000",
      },
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const CssLoadingButton = styled(LoadingButton)(({ theme }) => ({
  width: "max-content",
  borderRadius: "36px",
  boxShadow: "none",
  background: isEqual(process.env.REACT_APP_NAME, "sandbox")
    ? "#000000"
    : "#009E4D",
  padding: "12px 44px",
  "& span": {
    color: "#FFF",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  "&:hover": {
    background: isEqual(process.env.REACT_APP_NAME, "sandbox")
      ? "#000000"
      : "#009E4D",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 24px",
    marginTop: "48px!important",
    "& span": {
      fontSize: "19px",
    },
  },
}));

export default function LoginForm() {
  const smDown = useResponsive("down", "sm");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appStartup = useContext(AppStartupContext);

  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [loginDetails, setLoginDetails] = useState({ email: "" });
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async (_data, event) => {
    event.preventDefault();

    try {
      event.preventDefault();
      setDisable(true);
      if (loginDetails.email) {
        const payload = { email: loginDetails.email };

        await loginService.resetPassword(payload);

        setIsEmailSent(true);
        setAlertSeverity("success");
        setMsg("Reset Password Link Sent.");
      }
    } catch (err) {
      setAlertSeverity("error");
      setMsg("Reset Password Failed!");
    } finally {
      setDisable(false);
      setOpen(true);
    }
  };

  const textInputChange = (evt) => {
    const { name, value } = evt.target;
    setValue(name, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const handleForgetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      >
        <Alert severity={alertSeverity}>{msg}</Alert>
      </Snackbar>
      <div style={{ marginTop: "100px" }}>
        {!isEmailSent ? (
          <Stack spacing={smDown ? 4 : 8}>
            <Stack>
              <StyledTextWrapper>Fogot Password ?</StyledTextWrapper>
              <Typography style={{ whiteSpace: "pre-line" }}>
                {`Please enter your email address and we'll send 
              you instructions on how to reset your password.`}
              </Typography>
            </Stack>

            <form onSubmit={handleSubmit(handleClick)} method="post">
              <Stack spacing={8}>
                <Stack spacing={4}>
                  <CssTextField
                    {...register("email", {
                      required: "This is required.",
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Please enter a valid email.",
                      },
                    })}
                    required
                    id="login-email"
                    type="email"
                    variant="standard"
                    name="email"
                    label="Email address"
                    value={loginDetails.email}
                    onChange={textInputChange}
                    className={errors.email && "has-error"}
                    helperText={
                      errors.email && (
                        <span className="form-error-text">
                          {errors.email.message}
                        </span>
                      )
                    }
                  />
                </Stack>
                <CssLoadingButton
                  id="login-btn"
                  disabled={disable}
                  size={smDown ? "small" : "default"}
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit(handleClick)}
                >
                  <span>Reset Password</span>
                </CssLoadingButton>
              </Stack>
            </form>
          </Stack>
        ) : (
          <Stack flexDirection="column" marginTop={12}>
            <Iconify
              icon="simple-line-icons:check"
              width={40}
              color="#009E4D"
            />
            <Typography fontSize={16} fontWeight={600} marginTop={2}>
              Email Sent.
            </Typography>
            <Typography>
              Check your email and open the link we sent to continue.
            </Typography>
          </Stack>
        )}
      </div>
    </>
  );
}

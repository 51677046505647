import React from "react";
import { Provider } from "react-redux";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
//context
import AppStartupProvider from "./context/AppStartup";
// store
import rootStore from "./store";

export default function App() {
  const { store } = rootStore;
  return (
    <AppStartupProvider>
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </AppStartupProvider>
  );
}

import { useContext, useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
//component
import PrivateRoute from "./components/PrivateRoute";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SearchUserLayout from "./layouts/search-user";
// pages
import UserPage from "./pages/UserPage";
import LandingPage from "./pages/LandingPage";
import OnboardingPage from "./pages/sandbox/OnboardingPage";
import LoginPage from "./pages/common/LoginPage";
import ApplicationSelection from "./pages/user/ApplicationSelection";
import Chat from "./pages/user/Chat";
import Page404 from "./pages/Page404";
import ConfigPage from "./pages/configPage";
import ConfigPromptForm from "./pages/AddEditPromptConfig";
import UploadPage from "./pages/uploadPage";
import UserForm from "./pages/AddEditUsers";
import ConfigForm from "./pages/AddEditConfig";
import PromptConfigPage from "./pages/promptConfig";
import ModeSelectPage from "./pages/sandbox/ModeSelectPage";
import Sandbox from "./pages/sandbox/Sandbox";
import Dashboard from "./pages/dashboard/containers";
import Analytics from "./pages/dashboard/containers/Analytics";
import Region from "./pages/dashboard/containers/Region";
import CreateRegion from "./pages/dashboard/containers/CreateRegion";
import ProductCategory from "./pages/dashboard/containers/ProductCategory";
import ProductCategoryConfig from "./pages/dashboard/containers/ProductCategoryConfig";
import Modules from "./pages/dashboard/containers/Modules";
import Applications from "./pages/dashboard/containers/Applications";
import ApplicationConfig from "./pages/dashboard/containers/ApplicationConfig";
import UsersPage from "./pages/dashboard/containers/Users";
import CreateUserPage from "./pages/dashboard/containers/CreateUser";
import FileUploadPage from "./pages/dashboard/containers/FileUpload";
import FoldersPage from "./pages/dashboard/containers/Folders";
import FilesPage from "./pages/dashboard/containers/Files";
import Grafana from "./pages/dashboard/containers/Grafana";
import ApplicationFiles from "./layouts/search-user/applicationFile";
import AccountDetails from "./pages/dashboard/containers/AccountDetails";
//context
import { AppStartupContext } from "./context/AppStartup";
//constant
import { appTypes } from "./constants/constants";

// ----------------------------------------------------------------------

export default function Router() {
  const appStartup = useContext(AppStartupContext);

  const renderHomePageElement = (appType) =>
    ({
      [appTypes.sandbox]: <LandingPage />,
      [appTypes.demo]: <LoginPage />,
      [appTypes.local]: <LoginPage />,
      [appTypes.schneider]: <LoginPage />,
      [appTypes.uat]: <LandingPage />,
      [appTypes.app]: <LoginPage />,
    }[appType]);

  const routes = useRoutes([
    {
      path: "/",
      element: renderHomePageElement(appStartup?.app ?? appTypes.sandbox),
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <LoginPage />,
    },
    {
      path: "/onboarding",
      element: <OnboardingPage />,
    },
    {
      path: "/forgot-password",
      element: <LoginPage />,
    },
    {
      path: "/reset-password",
      element: <LoginPage />,
    },
    {
      path: "/enter-otp",
      element: <LoginPage />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      children: [
        { element: <Navigate to="analytics" />, index: true },
        {
          path: "analytics",
          element: <PrivateRoute children={<Analytics />} />,
        },
        {
          path: "region",
          element: <PrivateRoute children={<Region />} />,
        },
        {
          path: "region/:id/edit",
          element: <PrivateRoute children={<CreateRegion />} />,
        },
        {
          path: "region/create",
          element: <PrivateRoute children={<CreateRegion />} />,
        },
        {
          path: "product-category",
          element: <PrivateRoute children={<ProductCategory />} />,
        },
        {
          path: "product-category/create",
          element: <PrivateRoute children={<ProductCategoryConfig />} />,
        },
        {
          path: "product-category/:id/edit",
          element: <PrivateRoute children={<ProductCategoryConfig />} />,
        },
        {
          path: "modules",
          element: <PrivateRoute children={<Modules />} />,
        },
        {
          path: "application-config",
          element: <PrivateRoute children={<Applications />} />,
        },
        {
          path: "application-config/:applicationId/edit",
          element: <PrivateRoute children={<ApplicationConfig />} />,
        },
        {
          path: "application-config/create",
          element: <PrivateRoute children={<ApplicationConfig />} />,
        },
        {
          path: "users",
          element: <PrivateRoute children={<UsersPage />} />,
        },
        {
          path: "users/:userId/edit",
          element: <PrivateRoute children={<CreateUserPage />} />,
        },
        {
          path: "users/create",
          element: <PrivateRoute children={<CreateUserPage />} />,
        },
        {
          path: "file-upload",
          element: <PrivateRoute children={<FileUploadPage />} />,
        },
        {
          path: "all-files",
          element: <PrivateRoute children={<FoldersPage />} />,
        },
        {
          path: "all-files/:productCategoryId",
          element: <PrivateRoute children={<FilesPage />} />,
        },
        {
          path: "grafana",
          element: <PrivateRoute children={<Grafana />} />,
        },
        {
          path: "account-details",
          element: <PrivateRoute children={<AccountDetails />} />,
        },
      ],
    },
    {
      path: "/search_user",
      element: <SearchUserLayout />,
      children: [
        { element: <Navigate to="/search_user/chat_type" />, index: true },
        {
          path: "chat_type",
          element: <PrivateRoute children={<ApplicationSelection />} />,
        },
        { path: "chat", element: <PrivateRoute children={<Chat />} /> },
        {
          path: "chat/:convoId",
          element: <PrivateRoute children={<Chat />} />,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/files/:applicationId",
      element: <ApplicationFiles />,
    },
    {
      path: "/users",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/users/list" />, index: true },
        { path: "list", element: <UserPage /> },
        { path: "add", element: <UserForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/configurations",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/configurations/list" />, index: true },
        { path: "list", element: <ConfigPage /> },
        { path: "add", element: <ConfigForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/prompt_config",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/prompt_config/list" />, index: true },
        { path: "list", element: <PromptConfigPage /> },
        { path: "add", element: <ConfigPromptForm /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/upload",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/upload/file" />, index: true },
        { path: "file", element: <UploadPage /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/sandbox",
      children: [
        { element: <Navigate to="chat_type" />, index: true },
        {
          path: "chat_type",
          element: (
            <PrivateRoute redirect="/login" children={<ModeSelectPage />} />
          ),
        },
        {
          path: "chat",
          element: <PrivateRoute redirect="/login" children={<Sandbox />} />,
        },
        {
          path: "chat/:convoId",
          element: <PrivateRoute redirect="/login" children={<Sandbox />} />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

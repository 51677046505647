import { useRef, useState, useEffect } from "react";
import { isEmpty, isEqual } from "lodash";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { fetchEventSource } from "@microsoft/fetch-event-source";
// @mui
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";

import useResponsive from "src/hooks/useResponsive";
import Iconify from "src/components/iconify/Iconify";
import loginService from "src/services/loginService";
import SandboxInstructions from "./components/SandboxInstructions";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Rating from "../../components/Rating";

import { BASEURL } from "../../constants/constants";
import searchService from "../../services/search";

const cookies = new Cookies();

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  height: "100%",
  overflow: "hidden",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.primaryBackground,
}));

const ChatContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "56px 0px 24px 0px",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.primaryBackground,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 370px)",
    },
  }),
}));

const StyledGrid = styled("div")(({ theme }) => ({
  width: "70%",
  maxHeight: "160px",
  overflowY: "scroll",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridTemplateRows: "repeat(2,auto)",
  gap: "16px",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    paddingBottom: "16px",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
}));

const ThinLinedBox = styled("div")(({ theme }) => ({
  height: "100%",
  maxHeight: "180px",
  display: "flex",
  alignItems: "center",
  padding: "12px 16px",
  borderRadius: "12px",
  transition: "background 100ms ease-in-out",
  cursor: "pointer",
  border: `1px solid ${theme?.palette?.colorTheme?.theme4?.primaryBorder}`,
  "&.selected": {
    backgroundColor: theme?.palette?.colorTheme?.theme4?.secondaryBackground,
  },
  "&:hover": {
    backgroundColor: theme?.palette?.colorTheme?.theme4?.secondaryBackground,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minHeight: "54px",
    display: "flex",
    padding: "8px 16px",
    borderRadius: "12px",
  },
}));

const SuggestionText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 300,
  color: "#cbcbcb",
  letterSpacing: "0.1px",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.down("lg")]: {
    fontSize: "12px",
  },
}));

const ChatTextField = styled(TextField)(({ theme }) => ({
  width: "calc(100% - 48px)",
  margin: "32px 24px",
  borderRadius: "13px",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.ternaryBackground,
  "& .MuiInputBase-root": {
    padding: "0 16px 0 0",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    fontSize: "17px",
    fontWeight: 400,
    padding: "16px 24px",
    color: "#8E8E9E",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "24px 0",
    "& .MuiInputBase-input": {
      padding: "16px 24px",
    },
  },
}));

const StyledSpinner = styled("span")(({ theme }) => ({
  width: "40px",
  height: "40px",
  display: "inline-block",
  position: "relative",
  "&::after, &::before": {
    content: '""',
    boxSizing: "border-box",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "2px solid #FFF",
    position: "absolute",
    left: "0",
    top: "0",
    animation: "animloader 2s linear infinite",
  },
  "&::after": {
    animationDelay: "1s",
  },
}));

const StyledCommentPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    padding: "10px",
    background: "#FFF",
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
  },
}));

const ScrollableContainer = styled(Stack)(() => ({
  width: "100%",
  flex: 1,
  overflowY: "scroll",
}));

const StyledMessageContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "space-between",
  overflowY: "scroll",
  "& div.message": {
    width: "100%",
    fontFamily: "Helvetica",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",

    "&.message-user": {
      // background: theme?.palette?.colorTheme?.theme1?.bg1,
      color: "#E1E1E1",
      letterSpacing: "0.625px",
      padding: "8px 16px",
    },
    "&.message-bot": {
      // background: theme?.palette?.colorTheme?.theme1?.bg2,
      color: "#A1A1AC",
      letterSpacing: "1px",
      padding: "16px",
      paddingBottom: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  "& div.message-inner-container": {
    display: "flex",
    maxWidth: "1400px",
    margin: "auto",
  },
  "& div.message-avatar": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "36px",
    height: "36px",
    borderRadius: "7px",
    marginRight: "20px",
    color: "#FFF",
    background: "#343540",
    "&.bot": { background: "#009e4d" },
    "& img": { width: "24px", height: "26.5px" },
    [theme.breakpoints.down("md")]: {
      width: "32px",
      height: "32px",
      marginRight: "16px",
      "& img": { width: "21", height: "23.5" },
    },
  },
  "& span.avatar-initials": {
    fontFamily: "Helvetica",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  "& div.message-content": {
    width: "calc(100% - 56px)",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 50px)",
    },
  },
  "& .message-text": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    "& p": {
      margin: "10px 0",
      wordBreak: "break-word",
    },
  },
}));

const ScrollIconButton = styled(IconButton)({
  position: "absolute",
  bottom: "20%",
  left: "50%",
  padding: "4px",
  transform: "translateX(-50%)",
  background: "#ececf1",
  "&:hover": {
    background: "#ececf1",
  },
});

const RegenerateButton = styled(Button)({
  fontWeight: 400,
  alignSelf: "center",
  background: "#009e4d",
  "&:hover": {
    background: "#009e4d",
  },
});

let messageId = 1;
const Sandbox = () => {
  const mdUp = useResponsive("up", "lg");
  const scrollableRef = useRef(null);
  const shouldAutoScrollRef = useRef(true);
  let { convoId } = useParams();
  const { state } = useLocation();

  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNav, setOpenNav] = useState(mdUp ? true : false);
  const [userQuery, setUserQuery] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [chatConfig, setChatConfig] = useState([]);
  const [actingMessageId, setActingMessageId] = useState(null);
  const [commentAnchorEl, setCommentAnchorEl] = useState(null);
  const [comment, setComment] = useState("");
  const [queryInProgress, setQueryInProgress] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [conversId, setConversId] = useState([]);
  const [moduleId, setModuleId] = useState(state?.id ?? 2);
  const [personaOptions, setPersonaOptions] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showInstruction, setShowInstruction] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const [networkErrorCount, setNetworkErrorCount] = useState(0);
  const [instructionTemplate, setInstructionTemplate] = useState("");

  useEffect(() => {
    const auth = JSON.parse(localStorage?.getItem("auth") ?? "");
    setName(auth?.name ?? "");
    handleFetchPersonaOptions();
    searchService
      .getResources()
      .then((response) => {
        const instructionTemplate =
          response?.resources?.instructions?.find((instruction) =>
            isEqual(instruction?.moduleId, state?.id)
          )?.content ?? "";
        setInstructionTemplate(instructionTemplate);
        setShowInstruction(true);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (!isEmpty(convoId)) {
      setConversId(convoId);
      handleFetchConversation(convoId);
    } else {
      setConversId();
      setChatConfig([]);
    }
  }, [convoId]);

  useEffect(() => {
    if (!isEmpty(selectedPersona)) {
      setUserQuery("");
      setChatConfig([]);
      handleFetchModuleChatSuggestions();
    }
  }, [selectedPersona]);

  useEffect(() => {
    if (shouldAutoScrollRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }, [chatConfig]);

  const handleFetchConversation = (convoId) => {
    searchService.getChatDetails(convoId).then((chatDetailsRes) => {
      let updatedChatConfig = [];
      chatDetailsRes.data.conversation.Questions.forEach((question) => {
        updatedChatConfig.push(
          {
            id: question.id,
            text: question.question,
            sender: "user",
          },
          {
            id: question.Answers[0]?.id || "",
            question: question?.question,
            text: question.Answers[0]?.answer || "",
            sender: "bot",
            source: question.Answers[0]?.source || "",
            rating: question.Answers[0]?.rating || "",
            commentAdded: question.Answers[0]?.comment !== null || false,
            comment: question.Answers[0]?.comment || null,
          }
        );
      });

      setChatConfig(updatedChatConfig);
    });
  };

  const handleFetchModuleChatSuggestions = () => {
    searchService
      .getModuleSuggestions({ moduleId, persona: selectedPersona })
      .then((response) => {
        setSuggestions(response ?? []);
      });
  };

  const handleScroll = () => {
    // Check if the user has scrolled to the bottom
    const isAtBottom =
      scrollableRef.current.scrollHeight -
        scrollableRef.current.scrollTop -
        scrollableRef.current.clientHeight <=
      2;

    // Enable or disable auto-scrolling based on the user's scroll position
    setShowScrollDown(!isAtBottom);
    shouldAutoScrollRef.current = isAtBottom;
  };

  const handleFetchPersonaOptions = () => {
    searchService
      .getModulePersona({ moduleId })
      .then((response) => {
        setPersonaOptions(response ?? []);
        setSelectedPersona(response?.find((item) => item)?.persona);
      })
      .catch((error) =>
        setSnackbarMessage(error?.message ?? "Something went wrong")
      );
  };

  const handleOnFormChange = ({ name, value }) => {
    switch (name) {
      case "name":
        setName(value);
        break;
      case "persona":
        setSelectedPersona(value);
        break;
      default:
        break;
    }
  };

  const handleOnChange = (event) => {
    const { value } = event?.target;
    setUserQuery(value);
  };

  const suggestionSelected = (item) => {
    if (selectedSuggestion === item?.description) {
      let updatedUserQuery = `${userQuery?.replace(
        `${item?.description}`,
        ""
      )}`;
      setSelectedSuggestion();
      setUserQuery(updatedUserQuery);
    } else {
      setSelectedSuggestion(item?.description);
      setUserQuery(item?.description);
    }
  };

  const handleKeyDown = (event) => {
    if (!queryInProgress && event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleAskQuery(event);
    }
  };

  const handleAskQuery = () => {
    setUserQuery(selectedSuggestion ? `${selectedSuggestion}` : "");
    setQueryInProgress(true);
    setShowInstruction(false);
    let updatedChatConfig = [
      ...chatConfig,
      {
        id: messageId++,
        text: userQuery,
        sender: "user",
        persona: selectedPersona,
      },
      {
        sender: "bot",
        text: "",
        question: userQuery,
        isDummy: true,
      },
    ];
    setChatConfig(updatedChatConfig);
    try {
      fetchEventSource(`${BASEURL}/conversation/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
        },
        body: JSON.stringify({
          moduleId: moduleId,
          question: userQuery,
          convoId: conversId ? parseInt(conversId) : null,
          persona: selectedPersona,
        }),
        openWhenHidden: true,
        onmessage(event) {
          const data = JSON.parse(event?.data ?? "") || undefined;

          if (data && data?.text) {
            updatedChatConfig.splice(updatedChatConfig.length - 1, 1);
            updatedChatConfig = [
              ...updatedChatConfig,
              {
                id: data?.answerId,
                question: userQuery,
                text: data?.text,
                sender: data?.sender,
                rating: data?.rating,
                source: data?.source,
                commentAdded: data?.commentAdded,
                comment: "",
                convoId: data?.convoId,
              },
            ];

            convoId = convoId ? convoId : data?.convoId;
            setConversId(convoId);
            setChatConfig(updatedChatConfig);
            setNetworkErrorCount(0);
          }
        },
        onclose() {
          setQueryInProgress(false);
        },
        onerror(error) {
          setNetworkErrorCount((prevCount) => prevCount + 1);
          setSnackbarMessage("Something went wrong !!!");
          setOpenSnackbar(true);
          setQueryInProgress(false);
          showErrorResponse(updatedChatConfig);
          throw error;
        },
      });
    } catch (error) {
      setNetworkErrorCount((prevCount) => prevCount + 1);
      showErrorResponse(updatedChatConfig);
      setQueryInProgress(false);
    }
  };

  const addComment = async (_e, message) => {
    let updatedChatConfig = chatConfig.map((item) => ({
      ...item,
      commentAdded: item.id === message.id ? true : item.commentAdded,
      comment: item.id === message.id ? comment : item.comment,
    }));

    try {
      const commentRes = await searchService.addComment({
        id: message.id,
        comment: comment,
      });
      console.log(commentRes);
      if (commentRes?.status === 200) {
        handleCloseComment();
        setSnackbarMessage("Comment Added!");
        setOpenSnackbar(true);
        setChatConfig(updatedChatConfig);
        setComment("");
      } else {
        setSnackbarMessage("Something went wrong !!!");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage(err?.message);
      setOpenSnackbar(true);
    }
  };

  const updateResponseRating = async (_ev, message, rating) => {
    let updatedChatConfig = chatConfig.map((item) => ({
      ...item,
      rating: item.id === message.id ? rating : item.rating,
    }));
    try {
      const rateRes = await searchService.rateResponse({
        id: message.id,
        review: rating,
      });
      if (rateRes?.status === 200) {
        setChatConfig(updatedChatConfig);
        // cookies.set("chat_data", updatedChatConfig);
      } else {
        setSnackbarMessage("Something went wrong !!!");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage(err?.message);
      setOpenSnackbar(true);
    }
  };

  const copyText = (_e, message) => {
    const copiedText = `Question:\n${message?.question ?? ""}\n\nAnswer:\n${
      message?.text ?? ""
    }\n\n${window?.location?.origin}/login`;

    navigator.clipboard.writeText(copiedText);
    setSnackbarMessage("Text Copied!");
    setOpenSnackbar(true);
  };

  const showCommentBox = (_e, message) => {
    setActingMessageId(message.id);
    setCommentAnchorEl(_e.currentTarget);
    console.log(message);
    if (message.comment !== null) {
      setComment(message.comment);
    } else setComment("");
    // setOpenResponseComment(true);
  };

  const showErrorResponse = (updatedChatConfig) => {
    updatedChatConfig.splice(updatedChatConfig.length - 1, 1);
    updatedChatConfig = [
      ...updatedChatConfig,
      {
        // ...searchRes.data.answer
        id: null,
        question: userQuery,
        text: "Network Error! Please try again.",
        sender: "bot",
        rating: 0,
        source: [],
        commentAdded: false,
        comment: "",
        convoId: convoId,
      },
    ];
    setChatConfig(updatedChatConfig);
  };

  const handleScrollToBottom = () => {
    scrollableRef.current.scrollTo({
      top: scrollableRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleRegenerateOnClick = () => {
    setNetworkErrorCount(0);
    handleAskQuery();
  };

  const viewResponseSources = (ev, message) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseComment = () => {
    setActingMessageId(null);
    setCommentAnchorEl(null);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage("");
    setOpenSnackbar(false);
  };

  const renderPersonaTitle = (persona) => {
    const personaArr = persona?.split("_");
    let transformedText = "";
    personaArr?.forEach((text) => {
      transformedText = `${transformedText}${text
        ?.charAt(0)
        ?.toUpperCase()}${text?.substr(1)?.toLowerCase()} `;
    });
    return transformedText;
  };

  const openResponseComment = Boolean(commentAnchorEl);

  const open = Boolean(anchorEl);

  return (
    <StyledRoot>
      <Helmet>
        <title> Argus - Sandbox</title>
      </Helmet>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <LinearProgress />
      <Header
        openNav={openNav}
        onToggleNav={() => setOpenNav(!openNav)}
        onShowInstruction={
          !isEmpty(instructionTemplate)
            ? () => setShowInstruction(!showInstruction)
            : null
        }
      />
      <Sidebar
        open={openNav}
        name={name}
        persona={selectedPersona}
        personaOptions={personaOptions}
        queryInProgress={queryInProgress}
        onChange={handleOnFormChange}
        onClose={() => setOpenNav(false)}
      />
      <ChatContainer open={openNav}>
        {showInstruction && (
          <SandboxInstructions
            instructionTemplate={instructionTemplate}
            onClose={() => setShowInstruction(false)}
          />
        )}
        <Typography
          fontSize={12}
          fontWeight={700}
          color="#ffffff"
          px={3}
          py={2.5}
          lineHeight={2}
        >
          THREAD
        </Typography>

        <StyledMessageContainer>
          <ScrollableContainer ref={scrollableRef} onScroll={handleScroll}>
            {chatConfig?.map((message, index) => (
              <div key={index} className={`message message-${message?.sender}`}>
                <div className="message-inner-container">
                  <div className={`message-avatar ${message?.sender ?? ""}`}>
                    {isEqual(message?.sender, "bot") ? (
                      <img
                        src="/assets/icons/chat-icon.svg"
                        alt="schneider logo"
                      />
                    ) : (
                      <span className="avatar-initials">
                        {loginService.getInitials() || "Q"}
                      </span>
                    )}
                  </div>
                  <div className="message-content">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      {isEqual(message?.sender, "bot") ? (
                        !message?.isDummy ? (
                          <Markdown
                            className="message-text"
                            remarkPlugins={[remarkGfm]}
                          >
                            {message?.text}
                          </Markdown>
                        ) : (
                          <div className="message-text">
                            <StyledSpinner className="loader" />
                          </div>
                        )
                      ) : (
                        <Markdown
                          className="message-text"
                          remarkPlugins={[remarkGfm]}
                        >
                          {`${
                            message?.persona
                              ? `${renderPersonaTitle(message?.persona)}: `
                              : ""
                          }${message?.text}`}
                        </Markdown>
                      )}
                    </div>
                    {isEqual(message?.sender, "bot") && !message?.isDummy && (
                      <div
                        style={{
                          marginTop: "28px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography component="legend">
                            Rate the response
                          </Typography>
                          <Rating
                            id="response-rating"
                            name="response-rating"
                            value={message.rating}
                            onChange={(_ev, newValue) =>
                              updateResponseRating(_ev, message, newValue)
                            }
                          />
                        </div>
                        <div
                          className="message-action"
                          style={{
                            marginLeft: "12px",
                          }}
                        >
                          <IconButton onClick={(ev) => copyText(ev, message)}>
                            <ContentCopyRoundedIcon />
                          </IconButton>

                          <IconButton
                            onClick={(ev) => showCommentBox(ev, message)}
                          >
                            <MapsUgcRoundedIcon
                              style={{
                                color: message.commentAdded ? "green" : "",
                              }}
                            />
                          </IconButton>
                          <StyledCommentPopover
                            id={`response-comment_${message.id}`}
                            open={
                              actingMessageId === message.id &&
                              openResponseComment
                            }
                            anchorEl={commentAnchorEl}
                            onClose={handleCloseComment}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              sx={{
                                width: "100%",
                                margin: "auto",
                                display: "flex",
                                background: "rgba(145, 158, 171, 0.2)",
                                fontFamily: "Helvetica",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "0.3px",
                                "& .MuiInputBase-root": {
                                  color: "#000",
                                },
                              }}
                              id="response-comment-input"
                              placeholder="Add Comment"
                              variant="outlined"
                              multiline
                              rows={5}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                            <Button
                              disabled={comment.trim()?.length === 0}
                              sx={{
                                padding: "5px 15px",
                                marginTop: "15px",
                                borderRadius: "25px",
                                background: "rgba(145, 158, 171, 0.24)",
                                color: "rgba(0, 0, 0, 0.7)",
                                transition: "all 0.5s",
                                "&:hover": {
                                  background: "#202123",
                                  color: "#E1E1E1",
                                  transform: "scale(1.08)",
                                },
                              }}
                              onClick={(e) => addComment(e, message)}
                            >
                              Submit
                            </Button>
                          </StyledCommentPopover>
                          {message?.source?.length > 0 && (
                            <>
                              <IconButton
                                style={{
                                  color: "#8E8E9E",
                                  fontFamily: "Helvetica",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "0.4px",
                                }}
                                aria-describedby={`response-source_${message.id}`}
                                onClick={(ev) =>
                                  viewResponseSources(ev, message)
                                }
                              >
                                <DifferenceOutlinedIcon
                                  style={{ color: "#637381" }}
                                />
                                {/* <span>Source</span> */}
                              </IconButton>
                              <Popover
                                id={`response-source_${message.id}`}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <List
                                  dense={true}
                                  sx={{
                                    maxHeight: "180px",
                                    minWidth: "210px",
                                    maxWidth: "420px",
                                    overflow: "auto",
                                  }}
                                >
                                  {message?.source?.map((item, sourceIndex) => (
                                    <ListItem
                                      key={sourceIndex}
                                      sx={{
                                        "&:not(:last-child)": {
                                          borderBottom: "1px solid #999",
                                        },
                                      }}
                                    >
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  ))}
                                </List>
                              </Popover>
                            </>
                            // </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </ScrollableContainer>
          <Stack width="100%" alignItems="center">
            {isEmpty(chatConfig) && !queryInProgress && (
              <StyledGrid>
                {suggestions?.map((suggestion) => (
                  <ThinLinedBox
                    className={
                      userQuery?.includes(suggestion?.description ?? "") &&
                      "selected"
                    }
                    onClick={() => suggestionSelected(suggestion)}
                  >
                    <SuggestionText title={suggestion?.suggestion ?? ""}>
                      {suggestion?.suggestion ?? ""}
                    </SuggestionText>
                  </ThinLinedBox>
                ))}
              </StyledGrid>
            )}
            {networkErrorCount > 2 ? (
              <Stack alignItems="center" gap={2} my={3}>
                <Typography fontSize={12} color="#fff">
                  There was an error generating a response
                </Typography>
                <RegenerateButton
                  variant="contained"
                  startIcon={<Iconify icon="charm:refresh" width={14} />}
                  onClick={handleRegenerateOnClick}
                >
                  Regenerate
                </RegenerateButton>
              </Stack>
            ) : (
              <ChatTextField
                name="userQuery"
                placeholder="Enter your message"
                type="text"
                multiline={true}
                maxRows={5}
                value={userQuery}
                onKeyDown={handleKeyDown}
                onChange={handleOnChange}
                InputProps={{
                  endAdornment: (
                    <>
                      {!isEmpty(userQuery?.trim()) && !queryInProgress && (
                        <InputAdornment position="end" className="first-button">
                          <IconButton
                            edge="end"
                            onClick={() => setUserQuery("")}
                          >
                            <CloseOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      <InputAdornment position="end">
                        {queryInProgress ? (
                          <StyledSpinner className="loader" />
                        ) : (
                          <IconButton onClick={handleAskQuery}>
                            <Iconify icon="ic:round-send" width={27} />
                          </IconButton>
                        )}
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            )}
          </Stack>
        </StyledMessageContainer>
        {showScrollDown && !isEmpty(chatConfig) && (
          <ScrollIconButton onClick={handleScrollToBottom}>
            <Iconify
              icon="tabler:arrow-down"
              color="#202225"
              width={mdUp ? 24 : 20}
            />
          </ScrollIconButton>
        )}
      </ChatContainer>
    </StyledRoot>
  );
};

export default Sandbox;

import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "src/services/dashboard";

export const fetchAllModuleAction = createAsyncThunk(
  "dashboard/module/fetch",
  async (params) => await dashboardService.getModules(params)
);

export const updateModuleAction = createAsyncThunk(
  "dashboard/module/update",
  async (payload) => await dashboardService.updateModule(payload)
);

export const createModuleAction = createAsyncThunk(
  "dashboard/module/create",
  async (payload) => await dashboardService.createModule(payload)
);

export const fetchModuleDetailAction = createAsyncThunk(
  "dashboard/module/details",
  async (moduleId) => await dashboardService.getModuleDetails(moduleId)
);

import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import {
  Box,
  List,
  Divider,
  ListItemText,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import searchService from "../../services/search";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  isSearchUser: PropTypes.bool,
};

export default function NavSection({
  data = [],
  isSearchUser = false,
  setNavData,
  themename,
  ...other
}) {
  const today = new Date();
  const tempDate = new Date();
  const yesterday = new Date(tempDate.setDate(tempDate.getDate() - 1));
  const lastWeek = new Date(tempDate.setDate(tempDate.getDate() - 7));
  // console.log(today, yesterday, lastWeek);

  const structuredObjects = {
    Today: [],
    Yesterday: [],
    "Previous 7 Days": [],
    Older: [],
  };

  if (data && data?.length > 0) {
    data?.forEach((chatHistoryItem) => {
      const chatDate = new Date(chatHistoryItem.createdAt);

      if (chatHistoryItem.Questions[0]?.question) {
        if (
          chatDate.getDate() === today.getDate() &&
          chatDate.getMonth() === today.getMonth() &&
          chatDate.getFullYear() === today.getFullYear()
        ) {
          structuredObjects["Today"].push(chatHistoryItem);
        } else if (
          chatDate.getDate() === yesterday.getDate() &&
          chatDate.getMonth() === yesterday.getMonth() &&
          chatDate.getFullYear() === yesterday.getFullYear()
        ) {
          structuredObjects["Yesterday"].push(chatHistoryItem);
        } else if (
          chatDate.getDate() >= lastWeek.getDate() &&
          chatDate.getMonth() >= lastWeek.getMonth() &&
          chatDate.getFullYear() >= lastWeek.getFullYear()
        ) {
          structuredObjects["Previous 7 Days"].push(chatHistoryItem);
        } else {
          structuredObjects["Older"].push(chatHistoryItem);
        }
      }
    });
  }

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {Object.keys(structuredObjects).map(
          (dateGroup) =>
            structuredObjects[dateGroup].length > 0 && (
              <>
                <div
                  style={{
                    color: themename === "dark" ? "#d9d9d9" : "#343434",
                    fontSize: "13px",
                    padding: "12px 16px 4px",
                  }}
                >
                  {dateGroup}
                </div>
                {structuredObjects[dateGroup].map(
                  (item) =>
                    item.Questions[0]?.question && (
                      <NavItem
                        themename={themename}
                        isSearchUser={isSearchUser}
                        key={item.id}
                        item={item}
                        setNavData={setNavData}
                      />
                    )
                )}
              </>
            )
        )}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  isSearchUser: PropTypes.bool,
};

function NavItem({ themename, item, isSearchUser, setNavData }) {
  const path = `/search_user/chat/${item.id}`;
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const chatType = state?.chatType;
  const mId = state?.id;
  const [title, setTitle] = useState(
    item.convoName === null ? item.Questions[0]?.question : item.convoName
  );
  const [tempTitle, setTempTitle] = useState(
    item.convoName === null ? item.Questions[0]?.question : item.convoName
  );
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [snackOpen, setSnackOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", () => {
      // console.log('doc clicked');
      setEditMode(false);
    });

    return () => {
      document.removeEventListener("click", null);
    };
  }, []);

  const toggleEditMode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(tempTitle);
    setTitle(tempTitle);
    setEditMode(!editMode);
  };

  const updateChatTitle = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("update title");
    try {
      let chatDeleteRes = await searchService.updateChatTitle(item.id, {
        convoName: title,
      });
      console.log(chatDeleteRes);
      if (chatDeleteRes?.status === 200) {
        setTitle(title);
        setTempTitle(title);
        setMsg("Chat Title updated successfully.");
        setAlertSeverity("success");
        setSnackOpen(true);
        setEditMode(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteChat = (e) => {
    e.preventDefault();
    console.log("delete title");
    setOpen(true);
  };

  const handleChatDelete = async () => {
    try {
      let chatDeleteRes = await searchService.deleteChat(item.id);
      console.log(chatDeleteRes);
      if (chatDeleteRes?.status === 200) {
        handleClose();
        setMsg("Chat deleted successfully.");
        setAlertSeverity("success");
        setSnackOpen(true);
        navigate("/search_user/chat");
        setNavData((prevData) =>
          prevData?.filter((chat) => item?.id !== chat?.id)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={msg}
      >
        <Alert severity={alertSeverity}>{msg}</Alert>
      </Snackbar>
      <StyledNavItem
        component={RouterLink}
        to={path}
        state={{ chatType, id: mId, moduleId: state?.moduleId }}
        sx={{
          "&.active": {
            color: "text.primary",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
        draggable={!editMode}
      >
        <StyledNavItemIcon>
          <SmsOutlinedIcon fontSize="small" />
        </StyledNavItemIcon>
        {!editMode ? (
          <ListItemText
            disableTypography
            primary={title}
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          />
        ) : (
          <TextField
            autoFocus
            sx={{
              "& input": {
                color: "#FFF",
                padding: "2px 6px",
              },
              "& fieldset": {
                border: "2px solid #FFF !important",
              },
            }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
        {location.pathname === path && isSearchUser && (
          <div
            className="icon-contaier"
            style={{
              display: "flex",
              marginLeft: "8px",
              minWidth: "44px",
              justifyContent: "space-between",
            }}
          >
            {!editMode ? (
              <>
                <EditOutlinedIcon
                  onClick={(ev) => toggleEditMode(ev)}
                  fontSize="small"
                />
                <DeleteOutlineOutlinedIcon
                  onClick={(ev) => deleteChat(ev)}
                  fontSize="small"
                />
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete Chat</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to delete this chat?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleChatDelete} autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                <CheckOutlinedIcon
                  onClick={(ev) => updateChatTitle(ev)}
                  fontSize="small"
                />
                <CloseOutlinedIcon
                  onClick={(ev) => toggleEditMode(ev)}
                  fontSize="small"
                />
              </>
            )}
          </div>
        )}
        {/* {info && info} */}
      </StyledNavItem>
    </>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Grid, Typography, Snackbar, FormControl } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import configService from "src/services/configService";

const ConfigForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    isKb: true,
    isProduction: true,
    isContext: false,
  });
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [disable, setDisable] = useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log("name", event.target.name);
    console.log("value", event.target.value);
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisable(true);
    console.log(formData);
    try {
      const data = await configService.createConfig(formData);
      console.log(data);
      navigate("/configurations/list", { replace: true });
      setMsg("Configuration added successfully");
      setOpen(true);
    } catch (error) {
      setDisable(false);
      console.log(error);
      setMsg(error?.message);
      setOpen(true);
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Configurations | Connect </title>
      </Helmet>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Typography variant="h4" gutterBottom>
        Add Display Configuration
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 6, minWidth: 200 }}>
          <Grid container spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isProduction"
                  checked={formData.isProduction}
                  onChange={handleChange}
                />
              }
              label="Production"
            />
          </Grid>
          <Grid container spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isKb"
                  checked={formData.isKb}
                  onChange={handleChange}
                />
              }
              label="Knowledge Base"
            />
          </Grid>
          <Grid container spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="isContext"
                  checked={formData.isContext}
                  onChange={handleChange}
                />
              }
              label="Context"
            />
          </Grid>
          <Grid style={{ marginTop: "1rem" }} item xs={12} sm={6}>
            <Button
              disabled={disable}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default ConfigForm;

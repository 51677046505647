import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox from "@mui/material/Checkbox";

const CheckBox = ({ name, disabled, size, label, checked, onChange }) => {
  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <MuiCheckbox
          name={name}
          size={size}
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

export default CheckBox;

import { useEffect, useState, useContext } from "react";
import useResponsive from "../../hooks/useResponsive";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";
import SideNav from "./SideNav";
import { LinearProgress, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { useResourceSelector } from "src/pages/dashboard/slices";
import Instructions from "src/pages/sandbox/components/SandboxInstructions";
import { AppStartupContext } from "src/context/AppStartup";
import { fetchAllResourcesAction } from "src/pages/dashboard/slices";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 48;
const APP_BAR_DESKTOP = 56;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  justifyContent: "flex-end",
});

const Main = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE,
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    ...(open && {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up("md")]: {
        width: "calc(100% - 280px)",
      },
    }),
  })
);

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const aboveTablet = useResponsive("up", "md");
  const { theme: themename } = useContext(AppStartupContext);
  const resources = useResourceSelector();

  const [open, setOpen] = useState(pathname === "/search_user" ? false : true);
  const [navData, setNavData] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState();
  const [openInstructions, setOpenInstructions] = useState(false);
  const [initialPitch, setInitialPitch] = useState([]);

  useEffect(() => {
    dispatch(fetchAllResourcesAction());
  }, []);

  useEffect(() => {
    if (pathname === "/search_user") {
      setOpen(false);
    } else if (pathname.includes("/search_user/chat") && aboveTablet) {
      setOpen(true);
    }
  }, [pathname, aboveTablet]);

  const chatClicked = () => {
    navigate("/search_user/chat", {
      state: {
        chatType: state?.chatType,
        id: state?.id,
        moduleId: state?.moduleId,
      },
    });
  };

  const variant =
    pathname?.includes("/search_user/chat/") || pathname === "/search_user/chat"
      ? resources?.moulesV2?.includes(state?.moduleId)
        ? "v2"
        : "v1"
      : "v1";
  const instructionTemplate =
    resources?.instructions?.find((item) => item?.type === "CHAT_INSTRUCTION")
      ?.content ?? "";
  return (
    <StyledRoot>
      <LinearProgress />
      <Header
        openNav={open}
        resources={resources}
        selectedModule={selectedModule}
        setOpenInstructions={setOpenInstructions}
        onOpenNav={() => setOpen(!open)}
      />
      <SideNav
        variant={variant}
        openNav={open}
        chatHistory={navData}
        moduleList={moduleList}
        selectedModule={selectedModule}
        chatClicked={chatClicked}
        setNavData={setNavData}
        setSelectedModule={setSelectedModule}
        onCloseNav={() => setOpen(false)}
        setInitialPitch={setInitialPitch}
      />
      <Main
        style={{
          background: true ? "#FFF" : "#343540",
          position: "relative",
        }}
        open={open}
      >
        {openInstructions ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0",
              background: themename === "dark" ? "#212121" : "#ffffff",
            }}
          >
            <Instructions
              style={{ height: "calc(100% - 100px" }}
              instructionTemplate={instructionTemplate}
              onClose={() => setOpenInstructions(false)}
            />
          </div>
        ) : (
          <Outlet
            context={{
              initialPitch,
              selectedModule,
              setModuleList,
              setOpen,
              setNavData,
            }}
          />
        )}
      </Main>
    </StyledRoot>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllModuleAction,
  updateModuleAction,
  createModuleAction,
  fetchModuleDetailAction,
} from "./moduleAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
  meta: {},
};

const loadingActionState = [
  fetchAllModuleAction.pending,
  updateModuleAction.pending,
  createModuleAction.pending,
  fetchModuleDetailAction.pending,
];
const rejectedActionState = [
  fetchAllModuleAction.rejected,
  updateModuleAction.rejected,
  createModuleAction.rejected,
  fetchModuleDetailAction.rejected,
];

const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    resetModule: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllModuleAction.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload?.data ?? [];
        state.meta = action?.payload?.meta ?? {};
      })
      .addCase(updateModuleAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(createModuleAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(fetchModuleDetailAction.fulfilled, (state, action) => {
        state.status = "success";
        state.details = action.payload;
      });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetModule } = moduleSlice.actions;
export {
  fetchAllModuleAction,
  updateModuleAction,
  createModuleAction,
  fetchModuleDetailAction,
};
export default moduleSlice.reducer;

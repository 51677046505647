import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./common.css";
import { Button, Snackbar } from "@mui/material";
import userService from "src/services/userService";

const UploadPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFileUpload = async () => {
    try {
      console.log("File upload logic here:", selectedFile);
      const formData = new FormData();
      formData.append("file", selectedFile);
      const data = await userService.uploadFile(formData);
      setMsg(data?.data?.msg);
      setOpen(true);
    } catch (error) {
      console.log(error);
      setMsg("Error while uploading the file");
      setOpen(true);
    }
    // Implement the file upload logic here.
    // You can use libraries like axios, fetch, or FormData to send the file to the server.
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      />
      <Stack direction="row" spacing={2}>
        <Fab
          className="upload_file"
          variant="extended"
          color="primary"
          aria-label="add"
          onChange={handleFileChange}
          type="file"
        >
          <input type="file" onChange={handleFileChange}  multiple />
          <UploadFileIcon sx={{ mr: 1 }} />
          File Upload
        </Fab>
      </Stack>
      <Button
        className="upload-button"
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleFileUpload}
      >
        Submit
      </Button>
    </>
  );
};
export default UploadPage;

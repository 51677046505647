import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import useResponsive from "src/hooks/useResponsive";
import loginService from "src/services/loginService";
import Iconify from "../../../../components/iconify";

const HEADER_MOBILE = 48;
const HEADER_DESKTOP = 56;
const cookies = new Cookies();

const StyledRoot = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: theme?.palette?.colorTheme?.theme1?.bg1,
  boxShadow: "none",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 370px)`,
      marginLeft: `370px`,
    },
  }),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: "space-between",
  minHeight: HEADER_MOBILE,
  boxShadow: "0px 2px 16px 6px rgba(0, 0, 0, 0.5)",
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 4.5),
  },
}));

const HeadlineText = styled("div")(({ theme }) => ({
  fontSize: "22px",
  fontWeight: 400,
  color: "#CBCBCB",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {
    fontSize: "18px",
  },
}));

const MainLogo = styled("img")(() => ({
  height: "28px",
  width: "auto",
  objectFit: "cover",
  marginRight: "12px",
}));

const TextWrapper = styled("p")(() => ({
  margin: 0,
  letterSpacing: 0,
  lineHeight: "normal",
  whiteSpace: "nowrap",
}));

const TextWrapperBold = styled("span")(() => ({
  fontWeight: 700,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#A1A1AC",
  fontFamily: "Helvetica",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  minWidth: "48px",
  "& span.button-text": {
    marginRight: "12px",
  },
  "& .MuiButton-endIcon": {
    marginLeft: "0",
    "& svg": {
      color: "#F60808",
      width: "24px",
      height: "24px",
    },
  },
}));

const Header = ({ openNav, onToggleNav, onShowInstruction }) => {
  const navigate = useNavigate();
  const mdUp = useResponsive("up", "md");

  const handleOnNavigate = () => {
    onToggleNav();
    navigate("/sandbox/chat_type");
  };

  const handleOnLogout = () => {
    cookies.remove("chat_data");
    loginService.dologout();
    setTimeout(() => {
      navigate("/login");
    }, 500);
  };

  return (
    <StyledRoot position="fixed" open={openNav}>
      <StyledToolbar>
        <Stack flexDirection="row" alignItems="center">
          {onToggleNav && (
            <IconButton id="hamburger-btn" onClick={onToggleNav}>
              <Iconify icon="mingcute:menu-fill" width={27} />
            </IconButton>
          )}
          <HeadlineText onClick={handleOnNavigate}>
            <MainLogo src="/assets/logo-light.png" alt="argus logo" />
            {mdUp && (
              <TextWrapper>
                {"ArgusQuery "}
                <TextWrapperBold>Sandbox</TextWrapperBold>
              </TextWrapper>
            )}
          </HeadlineText>
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          {onShowInstruction && (
            <IconButton id="info-btn" onClick={onShowInstruction}>
              <Iconify
                icon="fluent:info-12-filled"
                color="#a9a9a9"
                width={24}
              />
            </IconButton>
          )}
          <StyledButton
            endIcon={
              <Iconify icon="mingcute:power-line" color="#F60808" width={24} />
            }
            onClick={handleOnLogout}
          >
            {mdUp && <span className="button-text">Sign Out</span>}
          </StyledButton>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};

export default Header;

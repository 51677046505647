import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isEqual, isEmpty } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  useResourceSelector,
  useRoleSelector,
  useRegionsSelector,
  useApplicationSelector,
  fetchAllRolesAction,
  fetchAllApplicationAction,
  fetchAllRegionsAction,
  createUserAction,
  updateUserAction,
  useUserSelector,
} from "../../slices";
import { regex } from "../../config";

import Iconify from "src/components/iconify/Iconify";
import Loader from "src/pages/dashboard/components/Loader";
import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";
import TextField from "src/components/elements/Textfield";
import PhoneNumberField from "../../components/PhoneNumberField";
import Select from "src/components/elements/Select";

const StyledRoot = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "40px 32px 32px 32px",
  overflow: "scroll",
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 14,
  fontWeight: 700,
  color: "#005544",
  lineHeight: "22px",
  padding: "12px",
  borderBottom: "4px solid #005544",
}));

const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": { fontSize: 14, width: 300 },
  ".MuiFormHelperText-root": { margin: 0, position: "absolute", bottom: -18 },
});

const StyledSelect = styled(Select)({
  ".MuiSelect-outlined": { fontSize: 14, width: 252 },
});

const StyledPhoneNumberField = styled(PhoneNumberField)({
  ".react-international-phone-input": {
    width: 256,
  },
});

const BackButton = styled(Button)({
  fontWeight: 400,
  color: "#40414E",
});

const SubmitButton = styled(Button)({
  minWidth: 130,
  fontSize: 14,
  fontWeight: 400,
  backgroundColor: "#005544",
  boxShadow: "none",
  "&:hover": { backgroundColor: "#005544", filter: "opacity(0.8)" },
});

const nameValidatorPattern = new RegExp(regex.nameValidator);
const emailValidatorPattern = new RegExp(regex.emailValidator);
const initialFormState = {
  roleId: "",
  name: "",
  email: "",
  password: "",
  mobile: "",
  regions: [],
  applications: [],
};

const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const users = useUserSelector();
  const roles = useRoleSelector();
  const regions = useRegionsSelector();
  const application = useApplicationSelector();
  const resources = useResourceSelector();

  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState(initialFormState);
  const [hidePassword, setHidePassword] = useState(true);
  const [invalid, setInvalid] = useState({});

  useEffect(() => {
    if (userId) {
      const selectedUser = users?.data?.find(
        (user) => user?.id === parseInt(userId)
      );

      if (!selectedUser) {
        handleGoBack();
        return;
      }

      const draftFormState = {
        id: selectedUser?.id,
        roleId: selectedUser?.roleId,
        name: selectedUser?.name,
        email: selectedUser?.email,
        password: selectedUser?.password,
        mobile: selectedUser?.mobile,
        regions: selectedUser?.Region?.map((region) => region?.id) ?? [],
        applications: selectedUser?.Application?.map((app) => app?.id) ?? [],
      };
      setFormState(draftFormState);
    }
  }, []);

  useEffect(() => {
    const promises = [
      dispatch(fetchAllRolesAction()),
      dispatch(fetchAllApplicationAction()),
      dispatch(fetchAllRegionsAction()),
    ];
    Promise.allSettled(promises).then(() => setLoading(false));
  }, []);

  const toggleInvalidState = (name, isInvalid, errorMessage) => {
    const draftInvalid = { ...invalid };
    if (isInvalid) {
      draftInvalid[name] = errorMessage;
    } else {
      delete draftInvalid[`${name}`];
    }
    setInvalid(draftInvalid);
  };

  const handleOnBlur = (event) => {
    const { name, value } = event?.target;

    const draftFormState = { ...formState };
    switch (name) {
      case "name": {
        if (isEmpty(value) || !nameValidatorPattern.test(value?.trim())) {
          toggleInvalidState(name, true, "Please enter a valid name");
          return;
        }
        draftFormState[name] = value?.trim();
        break;
      }
      case "email": {
        if (isEmpty(value) || !emailValidatorPattern.test(value)) {
          toggleInvalidState(name, true, "Please enter a valid Email");
          return;
        }
        draftFormState[name] = value?.trim();
        break;
      }
      case "password": {
        if (isEmpty(value)) {
          toggleInvalidState(name, true, "Please enter a valid value");
          return;
        }
        break;
      }
      case "mobile": {
        if (isEmpty(value) || value?.length > 13 || isNaN(value)) {
          toggleInvalidState(name, true, "Please enter a valid value");
          return;
        }
        break;
      }
      default:
        break;
    }
    toggleInvalidState(name, false);
    setFormState(draftFormState);
  };

  const handleOnChange = (event) => {
    const { name, value } = event?.target ?? {};

    const draftFormState = { ...formState };
    draftFormState[name] = value;

    if (isEqual(name, "mobile") && value?.length > 13) return;

    setFormState(draftFormState);
  };

  const handleOnSubmit = async () => {
    try {
      if (!isEmpty(invalid) || isRequiredFieldEmpty(formState)) return;
      setLoading(true);
      const role = roles?.data?.find((role) => role?.id === formState?.roleId);
      const payload = {
        ...formState,
        userType: renderUserType(role?.role_key),
      };

      if (isEqual(role?.role_key, "RA")) {
        payload["applications"] = application?.data
          ?.filter((app) =>
            app?.Region?.some((item) => formState?.regions?.includes(item?.id))
          )
          ?.map((item) => item?.id);
      } else if (isEqual(role?.role_key, "CA")) {
        payload["regions"] = regions?.data?.map((item) => item?.id) ?? [];
        payload["applications"] =
          application?.data?.map((item) => item?.id) ?? [];
      }

      if (!!userId) {
        await dispatch(updateUserAction(payload)).then((response) => {
          if (!response?.error) {
            navigate("/dashboard/users");
          }
        });
      } else {
        await dispatch(createUserAction(payload)).then((response) => {
          if (!response?.error) {
            navigate("/dashboard/users");
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => navigate("/dashboard/users");

  const isRequiredFieldEmpty = (formState) =>
    Object.keys(formState)?.some((state) => !formState[state]);

  const renderUserType = (role) =>
    ({
      SU: "searchUser",
      CA: "customerAdmin",
      SA: "superAdmin",
      RA: "regionAdmin",
    }[role] ?? "");

  const renderRoleOptions = (roles) => {
    let validRoles = [...roles];
    const userDetails = JSON.parse(localStorage?.getItem("auth") ?? "");
    const userRole =
      validRoles?.find((role) => role?.id === userDetails?.roleId) ?? {};

    if (userRole?.role_key === "CA") {
      validRoles = validRoles?.filter((role) => role?.role_key !== "SA");
    } else if (userRole?.role_key === "RA") {
      validRoles = validRoles?.filter(
        (role) => !["SA", "CA"]?.includes(role?.role_key)
      );
    }

    return validRoles?.map((role) => ({
      label: role?.role_name,
      value: role?.id,
    }));
  };

  const renderApplications = (application) =>
    application
      ?.filter((item) =>
        item?.Region?.some((region) => formState?.regions?.includes(region?.id))
      )
      ?.map((item) => ({
        label: `${item?.ProductCategory?.[0]?.categoryName ?? ""} ${
          item?.ModuleConfig?.module_name
        }`,
        value: item?.id,
      }));

  const renderRegions = (regions) =>
    regions?.map((item) => ({ label: item?.region, value: item?.id }));

  const userRole =
    roles?.data?.find((role) => role?.id === formState?.roleId) ?? {};

  const inputFieldProps = [
    {
      id: "role",
      field: "select",
      labelText: "Select User Role",
      name: "roleId",
      placeholder: "Select User Role",
      options: renderRoleOptions(roles?.data ?? []),
    },
    {
      id: "name",
      field: "input",
      labelText: "Full Name",
      name: "name",
      type: "text",
      placeholder: "Type Full Name",
    },
    {
      id: "email",
      labelText: "Email Address",
      field: "input",
      name: "email",
      type: "email",
      placeholder: "Type Email Address",
    },
    {
      id: "password",
      labelText: "Password",
      field: "input",
      name: "password",
      type: !hidePassword ? "text" : "password",
      placeholder: "Type Password",
      disabled: !!userId,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!!userId}
              icon={!hidePassword ? <VisibilityOff /> : <Visibility />}
              onClick={() => setHidePassword(!hidePassword)}
            />
          </InputAdornment>
        ),
      },
    },
    {
      id: "mobile",
      labelText: "Phone Number",
      field: "mobile",
      name: "mobile",
      type: "mobile",
      placeholder: "Type Phone Number",
    },
    userRole?.role_key !== "CA" && {
      id: "region",
      field: "select",
      labelText: "Select Teams",
      name: "regions",
      placeholder: "Select Teams",
      options: renderRegions(regions?.data ?? []),
      multiple: true,
    },
    !["CA", "RA"]?.includes(userRole?.role_key) && {
      id: "applications",
      field: "select",
      labelText: "Select Application",
      name: "applications",
      placeholder: "Select Application",
      disabled: isEmpty(formState?.regions),
      options: renderApplications(application?.data ?? []),
      multiple: true,
    },
  ];

  return (
    <StyledRoot>
      {loading && <Loader />}
      <Stack flexDirection="column" alignItems="flex-start" gap={1} mb={5}>
        <BackButton
          variant="text"
          startIcon={<Iconify icon="ep:back" />}
          content="Go back"
          onClick={handleGoBack}
        />
        <StyledTitle>Create User</StyledTitle>
      </Stack>
      <Stack
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        px={3.75}
        gap={5}
      >
        <Stack width={500} flexDirection="column" alignItems="flex-end" gap={4}>
          {inputFieldProps?.map((props) => (
            <Stack
              key={props?.id}
              flexDirection="row"
              alignItems="center"
              gap={3}
            >
              <Typography fontSize={14} fontWeight={400}>
                {props?.labelText}
              </Typography>
              {isEqual(props?.field, "input") && (
                <StyledTextField
                  {...props}
                  size="small"
                  value={formState?.[props?.name] ?? ""}
                  error={!isEmpty(invalid?.[props?.name])}
                  helperText={invalid?.[props?.name]}
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                />
              )}
              {isEqual(props?.field, "mobile") && (
                <StyledPhoneNumberField
                  name={props?.name}
                  value={formState?.[props?.name] ?? ""}
                  onChange={handleOnChange}
                />
              )}
              {isEqual(props?.field, "select") && (
                <StyledSelect
                  {...props}
                  value={formState?.[props?.name]}
                  onChange={handleOnChange}
                />
              )}
            </Stack>
          ))}
        </Stack>
        <Stack width="100%" alignItems="flex-end">
          <SubmitButton
            size="medium"
            content="Submit"
            onClick={handleOnSubmit}
          />
        </Stack>
      </Stack>
    </StyledRoot>
  );
};

export default CreateUser;

import React from "react";
import SideNavV1 from "./v1";
import SideNavV2 from "./v2";

const SideNav = ({ variant, ...props }) => {
  const renderVariant = () =>
    ({ v1: <SideNavV1 {...props} />, v2: <SideNavV2 {...props} /> }[variant]);

  return renderVariant();
};

export default SideNav;

//@mui
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Button from "../../../../components/elements/Button";

const StyledContainer = styled(Box)(() => ({
  maxWidth: 500,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 24,
  paddingTop: 32,
  borderRadius: 12,
  backgroundColor: "#ffffff",
  transform: "translate(-50%, -50%)",
  border: "none",
}));

const Popup = ({ open, message, onClose, onConfirm }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledContainer>
        <Typography>{message}</Typography>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          pt={3}
        >
          <Button variant="outlined" content="Cancel" onClick={onClose} />
          <Button content="Confirm" onClick={onConfirm} />
        </Stack>
      </StyledContainer>
    </Modal>
  );
};

export default Popup;

import { createSlice } from "@reduxjs/toolkit";
import { fetchAllRolesAction } from "./roleAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
};

const loadingActionState = [fetchAllRolesAction.pending];
const rejectedActionState = [fetchAllRolesAction.rejected];

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    resetRole: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAllRolesAction.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload;
    });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetRole } = roleSlice.actions;
export { fetchAllRolesAction };
export default roleSlice.reducer;

const Grafana = () => {
  return (
    <iframe
      frameBorder="0"
      src="https://g-11562a57de.grafana-workspace.us-west-2.amazonaws.com/login"
      width="100%"
      height="100%"
      title="Argus_Query_Grafana"
    />
  );
};

export default Grafana;

import { useState } from "react";
import { isEqual, isEmpty } from "lodash";
//@mui
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import Iconify from "../../../../components/iconify";

import { roleOptions, regex } from "../../config";
import userService from "../../../../services/userService";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 560,
  height: "98%",
  padding: "24px 0",
  borderRadius: 12,
  backgroundColor: "#ffffff",
  transform: "translate(-50%, -50%)",
  border: "none",
}));

const FormContainer = styled(Stack)(() => ({
  flex: 1,
  overflowY: "scroll",
  flexDirection: "column",
  marginTop: 32,
  gap: 20,
  padding: "0 32px",
  "& .MuiFormHelperText-root": {
    margin: 0,
  },
}));

const LoaderContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
}));

const nameValidatorPattern = new RegExp(regex.nameValidator);
const emailValidatorPattern = new RegExp(regex.emailValidator);

const AddUserPopup = ({ open, onSubmit, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalid, setInvalid] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  const toggleInvalidState = (name, isInvalid, errorMessage) => {
    const draftInvalid = { ...invalid };
    if (isInvalid) {
      draftInvalid[name] = errorMessage;
    } else {
      delete draftInvalid[`${name}`];
    }
    setInvalid(draftInvalid);
  };

  const handleOnBlur = (event) => {
    const { name, value } = event?.target;

    switch (name) {
      case "name": {
        if (isEmpty(value) || !nameValidatorPattern.test(value?.trim())) {
          toggleInvalidState(name, true, "Please enter a valid name");
          return;
        }
        setName(value?.trim());
        break;
      }
      case "email": {
        if (isEmpty(value) || !emailValidatorPattern.test(value)) {
          toggleInvalidState(name, true, "Please enter a valid mail");
          return;
        }
        setEmail(value?.trim());
        break;
      }
      case "username":
      case "password": {
        if (isEmpty(value)) {
          toggleInvalidState(name, true, "Please enter a valid username");
          return;
        }
        break;
      }
      case "confirmPassword": {
        if (isEmpty(value) || !isEqual(value, password)) {
          toggleInvalidState(name, true, "Please enter correct password");
          return;
        }
        break;
      }
      default:
        break;
    }
    toggleInvalidState(name, false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event?.target;
    switch (name) {
      case "role":
        setRole(value);
        break;
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "mobile":
        setMobile(value);
        break;
      default:
        break;
    }
  };

  const getRoleId = (userType) =>
    ({ searchUser: 1, customerAdmin: 2 }[userType]);

  const handleOnAddUser = () => {
    if (
      isEmpty(role) ||
      isEmpty(name) ||
      isEmpty(username) ||
      isEmpty(email) ||
      isEmpty(password) ||
      isEmpty(mobile)
    )
      return;

    const payload = {
      name,
      email,
      password,
      mobile,
      username,
      userType: role,
      roleId: getRoleId(role),
    };

    setIsLoading(true);
    userService
      .createUser(payload)
      .then((response) => {
        onSubmit();
        onClose();
      })
      .catch((errorResponse) =>
        setErrorMessage(errorResponse?.message ?? "Something went wrong!")
      )
      .finally(() => setIsLoading(false));
  };

  const formFields = [
    {
      label: "Role",
      name: "role",
      type: "select",
      placeholder: "Select Role",
      options: roleOptions,
      value: role,
    },
    {
      label: "Full Name",
      name: "name",
      type: "text",
      placeholder: "Enter User’s Full Name",
      value: name,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      placeholder: "xyz@email.com",
      value: email,
    },
    {
      label: "Username",
      name: "username",
      type: "text",
      placeholder: "Enter Your Username",
      value: username,
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      placeholder: "Enter Your Password",
      value: password,
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: "text",
      placeholder: "Confirm password",
      value: confirmPassword,
    },
    {
      label: "Phone no.",
      name: "mobile",
      type: "number",
      placeholder: "Enter Your Phone no.",
      value: mobile,
    },
  ];

  return (
    <>
      <Snackbar
        open={!isEmpty(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Modal
        open={open}
        aria-labelledby="adduser-modal-title"
        aria-describedby="adduser-modal-description"
      >
        <StyledContainer>
          {isLoading && (
            <LoaderContainer>
              <Iconify icon="eos-icons:bubble-loading" width={32} />
            </LoaderContainer>
          )}
          <Typography fontSize={18} fontWeight={600} color="#000000" px={4}>
            Add User
          </Typography>
          <FormContainer>
            {formFields?.map((field, index) => (
              <Stack key={index} flexDirection="column" gap={1}>
                <Typography fontSize={14} fontWeight={600} color="#000000">
                  {field?.label ?? ""}
                </Typography>
                {isEqual(field?.type, "select") ? (
                  <Select
                    name={field?.name}
                    value={field?.value}
                    onChange={handleOnChange}
                  >
                    {field?.options?.map((role, index) => (
                      <MenuItem key={index} value={role?.value}>
                        {role?.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    name={field?.name}
                    type={field?.type}
                    placeholder={field?.placeholder}
                    value={field?.value}
                    onBlur={handleOnBlur}
                    error={!isEmpty(invalid[field?.name])}
                    helperText={invalid[field?.name] ?? ""}
                    onChange={handleOnChange}
                  />
                )}
              </Stack>
            ))}
          </FormContainer>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={3}
            pt={4}
            px={4}
            bgcolor="#ffffff"
          >
            <Button
              variant="contained"
              fullWidth={true}
              size="large"
              onClick={handleOnAddUser}
            >
              Add User
            </Button>
            <Button
              variant="outlined"
              fullWidth={true}
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </StyledContainer>
      </Modal>
    </>
  );
};

export default AddUserPopup;

import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

const Pagination = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      {...(onRowsPerPageChange && {
        rowsPerPageOptions: [5, 10, 15, 20],
        onRowsPerPageChange: onRowsPerPageChange,
      })}
    />
  );
};

export default Pagination;

import Cookies from "universal-cookie";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";
import axios from "axios";

const axios1 = new Axios();
const baseUrl = BASEURL;
const cookies = new Cookies();

class UserService {
  getUsers(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );
    const option = {
      url: `${baseUrl}/admin/users?${queryString}`,
    };

    return axios1.get(option);
  }

  deleteUser(userId) {
    const option = {
      url: `${baseUrl}/admin/user/${userId}`,
    };

    return axios1.delete(option);
  }

  createUser(data) {
    const option = {
      url: `${baseUrl}/admin/users`,
      data,
    };

    return axios1.post(option);
  }

  updateUser(data) {
    const option = {
      url: `${baseUrl}/admin/users`,
      data,
    };

    return axios1.put(option);
  }

  uploadMultipleFiles(formData, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/admin/fileUploads`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
          },
          data: payload,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  uploadFile(formData) {
    // let option = {
    //   url: `${baseUrl}/admin/fileUpload`,
    //   formData,
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "Authorization": `Bearer ${cookies.get("jwt_session") || undefined}`,
    //   },
    // };

    // return axios1.post(option);
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}/admin/fileUpload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }); axios
    //     .post(`${baseUrl}/admin/fileUpload`, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         Authorization: `Bearer ${cookies.get("jwt_session") || undefined}`,
    //       },
    //     })
    //     .then((response) => {
    //       // Handle success response here.
    //       console.log("File uploaded successfully!", response);
    //       return response;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       return error;
    //     });
  }

  contactUs(data) {
    const option = {
      url: `${baseUrl}/subscribe`,
      data,
    };

    return axios1.post(option);
  }
}

const userService = new UserService();
export default userService;

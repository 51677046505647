import Cookies from "universal-cookie";
import * as jose from "jose";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const cookies = new Cookies();
const axios = new Axios();
const baseUrl = BASEURL;

class LoginService {
  loginUser({ email, password, userType }) {
    console.log("this", this);
    const option = {
      url: `${baseUrl}/login`,
      data: { email, password, userType },
    };

    return axios.post(option);
  }

  signUpUser(data) {
    console.log("this", this);
    const option = {
      url: `${baseUrl}/signUp`,
      data,
    };

    return axios.post(option);
  }

  resetPassword(data) {
    const option = {
      url: `${baseUrl}/reset-password`,
      data,
    };

    return axios.post(option);
  }

  updatePassword(data) {
    const option = {
      url: `${baseUrl}/update-password`,
      data,
    };

    return axios.put(option);
  }

  sentOtp(data) {
    const option = {
      url: `${baseUrl}/send-otp`,
      data,
    };

    return axios.post(option);
  }

  verifyOtp(data) {
    const option = {
      url: `${baseUrl}/verify-otp`,
      data,
    };

    return axios.post(option);
  }

  doLogin(token) {
    this.token = token;
    cookies.set("jwt_session", token);
  }

  dologout() {
    console.log(this);
    cookies.remove("jwt_session");
  }

  getparamsFromCookie(value) {
    this.value = value;
    const token = cookies.get("jwt_session");
    const decodedToken = jose.decodeJwt(token);
    // decode(token, { complete: true });
    let data;
    if (decodedToken) {
      switch (value) {
        case "username":
          data = decodedToken.name;
          break;
        case "email":
          data = decodedToken.email;
          break;
        case "userId":
          data = decodedToken.userId;
          break;
        case "accountId":
          data = decodedToken.accountId;
          break;
        case "avatar":
          data = decodedToken.avatar;
          break;
        case "userType":
          data = decodedToken.userType;
          break;
        case "jwt_session":
          data = token;
          break;
        default:
          data = "";
      }
    }

    return data;
  }

  getInitials() {
    const token = cookies.get("jwt_session");
    const decodedToken = jose.decodeJwt(token);
    const name = decodedToken.name;
    const ini = `${name?.split(" ")[0][0]}${
      name.split(" ")[1] ? name.split(" ")[1][0] : ""
    }`;

    return ini;
  }
}
const loginService = new LoginService();
export default loginService;

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllRegionsAction,
  createNewRegionAction,
  updateRegionAction,
} from "./regionAction";

const initialState = {
  status: "idle",
  error: null,
  data: [],
  meta: {},
};

const loadingActionState = [
  updateRegionAction.pending,
  fetchAllRegionsAction.pending,
  createNewRegionAction.pending,
];
const rejectedActionState = [
  updateRegionAction.rejected,
  fetchAllRegionsAction.rejected,
  createNewRegionAction.rejected,
];

const regionSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    resetRegions: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllRegionsAction.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload?.data ?? [];
        state.meta = action?.payload?.meta ?? {};
      })
      .addCase(createNewRegionAction.fulfilled, (state, action) => {
        state.status = "success";
      })
      .addCase(updateRegionAction.fulfilled, (state, action) => {
        state.status = "success";
      });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetRegions } = regionSlice.actions;
export { fetchAllRegionsAction, createNewRegionAction, updateRegionAction };
export default regionSlice.reducer;

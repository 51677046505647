import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

import useResponsive from "src/hooks/useResponsive";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    width: "370px",
    background: theme?.palette?.colorTheme?.theme1?.bg1,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const StyledHeader = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme?.palette?.colorTheme?.theme4?.primaryText,
  padding: "10px 24px",
  borderBottom: `1px solid ${theme?.palette?.colorTheme?.theme4?.primaryBorder}`,
  ".MuiButtonBase-root": {
    fontWeight: 400,
    color: "#ffffff",
  },
  ".MuiIconButton-root": {
    paddingRight: 0,
  },
}));

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 24,
  gap: 16,
});

const StyledLink = styled("li")({
  fontSize: 16,
  padding: "10px",
  color: "#a9a9a9",
  textDecoration: "underline",
  cursor: "pointer",
  "&:hover": {
    color: "#ffffff",
  },
});

const HelpSidebar = ({ open, modules, onClick, onClose }) => {
  const mdUp = useResponsive("up", "md");

  return (
    <Box component="nav">
      <StyledDrawer
        open={open}
        onClose={onClose}
        variant={mdUp ? "persistent" : "temporary"}
        ModalProps={{ keepMounted: true }}
      >
        <StyledHeader>
          <Typography fontSize={16} fontWeight={600}>
            Help & Support
          </Typography>
        </StyledHeader>
        <StyledContainer onClick={onClick}>
          {modules?.map((module) => (
            <StyledLink>{module?.module_name ?? ""}</StyledLink>
          ))}
        </StyledContainer>
      </StyledDrawer>
    </Box>
  );
};

export default HelpSidebar;

import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "src/services/dashboard";
import userService from "src/services/userService";

export const fetchFilesAction = createAsyncThunk(
  "dashboard/files/fetch",
  async (params) => await dashboardService.getFiles(params)
);

export const uploadMultipleFilesAction = createAsyncThunk(
  "dashboard/files/upload",
  async (payload) => await userService.uploadMultipleFiles(payload)
);

import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

const StyledRating = styled("div")(() => ({
  display: "flex",
  flexDirection: "row-reverse",
  marginTop: "6px",
  "--stroke": "rgba(145, 158, 171, 0.8)",
  "--fill": "#ffc73a",
  input: {
    appearance: "unset",
  },
  label: {
    cursor: "pointer",
  },
  svg: {
    width: "1.4rem",
    height: "1.4rem",
    overflow: "visible",
    fill: "transparent",
    stroke: "var(--stroke)",
    strokeLinejoin: "bevel",
    strokeDasharray: "none",
    animation: "idle 4s linear infinite",
    transition: "stroke 0.2s, fill 0.5s",
  },
  "label:hover svg": {
    stroke: "var(--fill)",
  },
  "input:checked ~ label svg": {
    transition: "0s",
    animation: "idle 4s linear infinite, yippee 0.75s backwards",
    fill: "var(--fill)",
    stroke: "var(--fill)",
    strokeOpacity: 0,
    strokeDasharray: 0,
    strokeLinejoin: "miter",
    strokeWidth: "8px",
  },
  ".blink": {
    transition: "0s",
    animation: "idle 4s linear infinite, yippee 0.5s ease-in-out 2",
  },
  "@keyframes idle": {
    from: {
      strokeDashoffset: 24,
    },
  },
  "@keyframes yippee": {
    "0%": {
      transform: "scale(1)",
      fill: "var(--fill)",
      fillOpacity: 0,
      strokeOpacity: 1,
      stroke: "var(--stroke)",
      strokeDasharray: 10,
      strokeWidth: "1px",
      strokeLinejoin: "bevel",
    },
    "30%": {
      transform: "scale(0)",
      fill: "var(--fill)",
      fillOpacity: 0,
      strokeOpacity: 1,
      stroke: "var(--stroke)",
      strokeDasharray: 10,
      strokeWidth: "1px",
      strokeLinejoin: "bevel",
    },
    "30.1%": {
      stroke: "var(--fill)",
      strokeDasharray: 0,
      strokeLinejoin: "miter",
      strokeWidth: "8px",
    },
    "60%": {
      transform: "scale(1.2)",
      fill: "var(--fill)",
    },
  },
}));

const Rating = ({ id, name, value, blink, onChange }) => {
  const [enableBlinking, setEnableBlinking] = useState(false);

  useEffect(() => {
    if (!blink) return;
    setEnableBlinking(true);
    const timeoutId = setTimeout(() => {
      setEnableBlinking(!blink);
      clearTimeout(timeoutId);
    }, 1000);
  }, [blink]);

  return (
    <StyledRating id={id} class="rating">
      {[5, 4, 3, 2, 1]?.map((rating) => (
        <>
          <input
            id={`${name}-star-${rating}`}
            name={name}
            type="radio"
            value={rating}
            checked={value === rating}
            onChange={(event) => onChange(event, rating)}
          />
          <label for={`${name}-star-${rating}`}>
            <svg
              className={enableBlinking ? "blink" : ""}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                pathLength="360"
                d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
              ></path>
            </svg>
          </label>
        </>
      ))}
    </StyledRating>
  );
};

export default Rating;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { moduleTableColumn } from "../../config";
import { fDateTime } from "../../../../utils/formatTime";
import {
  useModuleSelector,
  fetchAllModuleAction,
  updateModuleAction,
  resetModule,
} from "../../slices";

import Iconify from "src/components/iconify";
import SearchField from "src/components/SearchField";
import Switch from "src/components/elements/Switch";
import Table from "src/pages/dashboard/components/Table";
import IconButton from "src/components/elements/IconButton";
import Tooltip from "src/components/elements/Tooltip";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const WrappedText = styled(Typography)({
  maxWidth: 180,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const TableContainer = styled("div")(({ theme }) => ({
  flex: 1,
  border: ".07em solid #dcdcdc",
  background: "#ffffff",
  boxShadow: "0 .14em 1.43em 0 rgba(0,0,0,.15)",
  overflowY: "scroll",
}));

const Modules = () => {
  const dispatch = useDispatch();
  const module = useModuleSelector();

  const [searchString, setSearchString] = useState("");
  const [filteredModules, setFilteredModules] = useState([]);

  useEffect(() => {
    dispatch(fetchAllModuleAction({ page: 0, size: 10 }));

    return () => dispatch(resetModule());
  }, []);

  useEffect(() => {
    const draftFilteredModules = module?.data?.filter((item) =>
      item?.module_name?.toLowerCase()?.includes(searchString?.toLowerCase())
    );

    setFilteredModules(draftFilteredModules ?? []);
  }, [module, searchString]);

  const handleOnPageChange = (event, newPage) => {
    dispatch(fetchAllModuleAction({ page: newPage, size: module?.meta?.size }));
  };

  const handleOnRowsPerPageChange = (event) => {
    dispatch(
      fetchAllModuleAction({
        page: 0,
        size: parseInt(event.target.value, 10),
      })
    );
  };

  const handleSearchOnChange = (event) => {
    const { value } = event?.target ?? {};

    setSearchString(value);
  };

  const handleOnChange = async (event) => {
    const { value, checked } = event?.target ?? {};
    await dispatch(updateModuleAction({ moduleId: value, isActive: checked }));
    await dispatch(fetchAllModuleAction());
  };

  const renderSwitchButton = (value, item) => (
    <Switch
      name="isActive"
      checked={value}
      value={item?.id}
      onChange={handleOnChange}
    />
  );

  const renderWrappedText = (text) => (
    <Tooltip title={text ?? ""}>
      <WrappedText>{text ?? ""}</WrappedText>
    </Tooltip>
  );

  moduleTableColumn?.forEach((column) => {
    if (["module_name", "modeule_desc"]?.includes(column?.id)) {
      column["value"] = renderWrappedText;
    } else if (isEqual(column?.id, "isActive")) {
      column["value"] = renderSwitchButton;
    } else if (["createdAt", "updated_at"]?.includes(column?.id)) {
      column["value"] = (value) => fDateTime(new Date());
    }
  });

  const sequencedTableData = filteredModules?.map((item, index) => ({
    ...item,
    sno:
      index + (module?.meta?.page ?? 0) * (module?.meta?.size ?? 0) < 9
        ? `0${
            index + 1 + (module?.meta?.page ?? 0) * (module?.meta?.size ?? 0)
          }`
        : index + 1 + (module?.meta?.page ?? 0) * (module?.meta?.size ?? 0),
  }));

  return (
    <StyledRoot>
      <Stack flexDirection="row" alignItems="center" gap={1} mb={4}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Typography fontSize={32} fontWeight={600} color="#0000008a">
            Activate Modules
          </Typography>
          <Typography fontSize={18} fontWeight={600} color="#0000008a">
            (Pre-populated )
          </Typography>
        </Stack>
        <Tooltip
          arrow={true}
          placement="right-start"
          title="Subscribed available ArgusQuery chat modules. [Example: Sales or Marketing query module, various add on modules]"
        >
          <IconButton
            icon={
              <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
            }
          />
        </Tooltip>
      </Stack>
      <TableContainer>
        <Stack alignItems="flex-end" p={2}>
          <SearchField
            name="searchModule"
            type="text"
            placeholder="Search by Module Name"
            value={searchString}
            onChange={handleSearchOnChange}
          />
        </Stack>
        <Table
          loading={isEqual(module?.status, "loading")}
          data={sequencedTableData}
          columns={moduleTableColumn}
          pagination={module?.meta ?? {}}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowsPerPageChange}
        />
      </TableContainer>
    </StyledRoot>
  );
};

export default Modules;

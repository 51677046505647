import PropTypes from "prop-types";
import MaterialButton from "@mui/material/Button";

const Button = ({
  variant,
  size,
  color,
  disabled,
  content,
  startIcon,
  endIcon,
  onClick,
  ...props
}) => {
  return (
    <MaterialButton
      variant={variant}
      size={size}
      color={color}
      disabled={disabled}
      disableElevation
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      {...props}
    >
      {content}
    </MaterialButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  content: PropTypes.string.isRequired,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  variant: "contained",
  size: "medium",
  disabled: false,
  content: "",
  startIcon: "",
  endIcon: "",
  onClick: () => null,
};

export default Button;

import { createSlice } from "@reduxjs/toolkit";
import { fetchAllResourcesAction } from "./resourceAction";

const initialState = {
  status: "idle",
  error: null,
  data: {},
};

const loadingActionState = [fetchAllResourcesAction.pending];
const rejectedActionState = [fetchAllResourcesAction.rejected];

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    resetResources: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAllResourcesAction.fulfilled, (state, action) => {
      state.status = "success";
      state.data = action.payload?.resources ?? {};
    });
    loadingActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state) => {
        state.status = "loading";
      });
    });
    rejectedActionState?.forEach((actionType) => {
      builder.addCase(actionType, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
    });
  },
});

export const { resetResources } = resourceSlice.actions;
export { fetchAllResourcesAction };
export default resourceSlice.reducer;

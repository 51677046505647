import React from "react";
//@mui
import { styled } from "@mui/material/styles";
import Iconify from "src/components/iconify/Iconify";

const LoaderContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#000000",
  zIndex: 3,
  opacity: 0.3,
}));

const StyledIcon = styled(Iconify)({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 40,
  height: 40,
  color: "#ffffff",
  zIndex: 3,
});

const Loader = () => {
  return (
    <>
      <LoaderContainer />
      <StyledIcon icon="eos-icons:bubble-loading" />
    </>
  );
};

export default Loader;

// @mui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
//component
import Iconify from "src/components/iconify/Iconify";
import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";

const StyledRoot = styled("div")({
  width: "calc(100% - 48px)",
  height: "calc(100% - 48px)",
  display: "flex",
  flexDirection: "column",
  margin: 24,
  borderRadius: 12,
  background: "#343540",
});

const Footer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: 16,
  padding: "24px 32px",
});

const Instructions = ({ setOpenInstructions }) => {
  return (
    <StyledRoot>
      <Stack flexDirection="row" justifyContent="space-between" p={2}>
        <Typography fontSize={28} fontWeight={600} color="#dcdcdc" px={2}>
          Instruction Title
        </Typography>
        <IconButton
          icon={
            <Iconify
              icon="radix-icons:cross-circled"
              width={24}
              color="#dcdcdc"
            />
          }
          onClick={() => setOpenInstructions(false)}
        />
      </Stack>
      <Stack flex={1}></Stack>
      <Footer>
        <Button size="small" variant="outlined" content="Skip" />
        <Button size="small" content="Next" />
      </Footer>
    </StyledRoot>
  );
};

export default Instructions;

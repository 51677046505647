import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// components
import loginService from "../../../services/loginService";
import Iconify from "../../../components/iconify";
import useResponsive from "../../../hooks/useResponsive";

// ----------------------------------------------------------------------

const StyledTextWrapper = styled("p")(({ theme }) => ({
  color: isEqual(process.env.REACT_APP_NAME, "sandbox") ? "#000000" : "#009E4D",
  fontFamily: "Helvetica",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  [theme.breakpoints.down("sm")]: {
    fontSize: "26px",
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  width: "400px",
  "&.MuiTextField-root": {
    "& label": {
      top: "-5.5px",
      fontSize: 20,
      color: isEqual(process.env.REACT_APP_NAME, "sandbox")
        ? "#000000"
        : "#009E4D",
    },
    "& .MuiInput-root": {
      "& input": {
        height: 22,
        fontSize: 16,
      },
      "&:hover:not(.Mui-disabled, .Mui-error):before": {
        borderBottomColor: isEqual(process.env.REACT_APP_NAME, "sandbox")
          ? "#000000"
          : "#009E4D",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#A9D0BC",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: isEqual(process.env.REACT_APP_NAME, "sandbox")
        ? "#000000"
        : "#009E4D",
    },
    [theme.breakpoints.down("sm")]: {
      "& label": {
        fontSize: 21,
      },
      "& .MuiInput-root": {
        "& input": {
          height: 24,
          fontSize: 17,
        },
      },
    },
    // Form Error CSS
    "&.has-error": {
      "& label": {
        color: "#FF0000",
      },
      "& .MuiInput-root": {
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottomColor: "#FF0000",
        },
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "#FF0000",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#FF0000",
      },
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const CssLoadingButton = styled(LoadingButton)(({ theme }) => ({
  // width: "max-content",
  borderRadius: "36px",
  boxShadow: "none",
  color: "#fff",
  background: isEqual(process.env.REACT_APP_NAME, "sandbox")
    ? "#000000"
    : "#009E4D",
  padding: "12px 44px",
  "& span": {
    color: "#FFF",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 24px",
    "& span": {
      fontSize: "19px",
    },
  },
  "&:hover": {
    background: isEqual(process.env.REACT_APP_NAME, "sandbox")
      ? "#000000"
      : "#009E4D",
  },
}));

export default function LoginForm() {
  const smDown = useResponsive("down", "sm");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [msg, setMsg] = useState({});
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    userType: "searchUser",
    account: "Schneider Electric",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  const handleClick = async (_data, event) => {
    try {
      event.preventDefault();
      setDisable(true);
      console.log("here", userDetails);
      console.log(userDetails.password);
      console.log(confirmPassword);
      console.log(userDetails.password === confirmPassword);
      if (
        userDetails.name &&
        userDetails.email &&
        userDetails.mobile &&
        userDetails.password &&
        userDetails.password === confirmPassword
      ) {
        userDetails.email = userDetails.email.toLowerCase();
        const loginRes = await loginService.signUpUser({
          userDetails,
        });
        console.log(loginRes);
        if (loginRes?.status === 201) {
          setMsg({ type: "success", message: loginRes.data.message });
          setOpen(true);
          navigate("/login", { replace: true });
        } else {
          setDisable(false);
          console.log("Unsuccessful Sign Up !!!");
        }
      } else {
        setDisable(false);
        setMsg({
          type: "error",
          message: "Please fill all the fields or Password did not match!",
        });
        setOpen(true);
      }
    } catch (err) {
      setDisable(false);
      setMsg({ type: "error", message: err.message });
      setOpen(true);
    }
  };

  const textInputChange = (evt) => {
    const { name, value } = evt.target;

    console.log(name, value);
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={msg?.type}
          sx={{ width: "100%" }}
        >
          {msg?.message ?? ""}
        </Alert>
      </Snackbar>
      <div style={{ marginTop: "64px" }}>
        <Stack spacing={smDown ? 4 : 8}>
          <StyledTextWrapper>Signup</StyledTextWrapper>
          <form
            onSubmit={handleSubmit(handleClick)}
            method="post"
            style={{ marginTop: "32px" }}
          >
            <Stack spacing={smDown ? 6 : 8}>
              <Stack spacing={4}>
                <CssTextField
                  {...register("name", { required: true })}
                  required
                  variant="standard"
                  name="name"
                  label="Full Name"
                  onChange={textInputChange}
                  className={errors.name && "has-error"}
                  helperText={
                    errors.name && (
                      <span className="form-error-text">
                        This field is required
                      </span>
                    )
                  }
                />

                <CssTextField
                  {...register("mobile", { required: true })}
                  required
                  type="number"
                  variant="standard"
                  name="mobile"
                  label="Mobile (include contry code)"
                  onChange={textInputChange}
                  className={errors.mobile && "has-error"}
                  helperText={
                    errors.mobile && (
                      <span className="form-error-text">
                        This field is required
                      </span>
                    )
                  }
                />

                <CssTextField
                  {...register("email", {
                    required: "This is required.",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter a valid email.",
                    },
                  })}
                  required
                  variant="standard"
                  name="email"
                  label="Email Id"
                  onChange={textInputChange}
                  className={errors.email && "has-error"}
                  helperText={
                    errors.email && (
                      <span className="form-error-text">
                        {errors.email.message}
                      </span>
                    )
                  }
                />

                <CssTextField
                  {...register("password", { required: true })}
                  required
                  variant="standard"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={textInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={errors.password && "has-error"}
                  helperText={
                    errors.password && (
                      <span className="form-error-text">
                        This field is required
                      </span>
                    )
                  }
                />

                <CssTextField
                  {...register("confirmPassword", { required: true })}
                  required
                  variant="standard"
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(evt) => setConfirmPassword(evt.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          <Iconify
                            icon={
                              showConfirmPassword
                                ? "eva:eye-fill"
                                : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={errors.password && "has-error"}
                  helperText={
                    errors.password && (
                      <span className="form-error-text">
                        Password do not match
                      </span>
                    )
                  }
                />
              </Stack>

              {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Checkbox name="remember" label="Remember me" />
            <Link variant="subtitle2" underline="hover">
              Forgot password?
            </Link>
          </Stack> */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  "& p": {
                    color: "#A9D0BC",
                    fontFamily: "Helvetica",
                    fontSize: smDown ? "24px" : "30px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    margin: smDown ? "0 24px" : "0 48px",
                  },
                  "& span": {
                    fontFamily: "Helvetica",
                    fontSize: smDown ? "19px" : "23px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    cursor: "pointer",
                    color: "#000000",
                  },
                }}
              >
                <CssLoadingButton
                  disable={disable ? "disabled" : undefined}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit(handleClick)}
                >
                  <span>Signup</span>
                </CssLoadingButton>
                <p>or</p>
                <span onClick={navigateToLogin}>Log In</span>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </div>
    </>
  );
}

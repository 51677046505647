import React, { useState } from "react";
//helper
import { extractWhitelabelSubdomain } from "../utils/helper";

export const AppStartupContext = React.createContext({
  app: extractWhitelabelSubdomain(),
});

const AppStartupProvider = ({ children }) => {
  const [theme, setTheme] = useState("dark");

  return (
    <AppStartupContext.Provider
      value={{ app: extractWhitelabelSubdomain(), theme, setTheme }}
    >
      {children}
    </AppStartupContext.Provider>
  );
};

export default AppStartupProvider;

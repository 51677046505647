import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import Iconify from "src/components/iconify/Iconify";
import TextField from "src/components/elements/Textfield";
import Button from "src/components/elements/Button";
import IconButton from "src/components/elements/IconButton";

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": { fontSize: 14, minWidth: 258 },
});

const AddButton = styled(Button)({
  width: 258,
  fontSize: 14,
  fontWeight: 400,
  boxShadow: "none",
});

const MultipleTextField = ({ name, value, onChange }) => {
  const [fieldValues, setFieldValues] = useState([]);

  useEffect(() => {
    if (value?.length > 0) {
      setFieldValues(value);
    }
  }, [value]);

  const handleOnChange = (event) => {
    const { name: id, value } = event?.target;

    const draftFieldValues = [...fieldValues];
    draftFieldValues?.forEach((fieldValue) => {
      if (fieldValue?.id === id) fieldValue["value"] = value;
    });

    setFieldValues(draftFieldValues);
    onChange({ target: { name, value: draftFieldValues } });
  };

  const handleAddField = () => {
    const draftFieldValues = [...fieldValues];
    draftFieldValues.push({
      id: `input-${draftFieldValues?.length + 1}`,
      value: "",
    });

    setFieldValues(draftFieldValues);
  };

  const handleRemoveField = (fieldId) => {
    const draftFieldValues =
      fieldValues?.filter((fieldValue) => fieldValue?.id !== fieldId) ?? [];

    setFieldValues(draftFieldValues);
  };

  return (
    <StyledRoot>
      {fieldValues?.map((fieldValue, index) => (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <StyledTextField
            id={fieldValue?.id}
            name={fieldValue?.id}
            type="text"
            size="small"
            value={fieldValue?.value}
            onChange={handleOnChange}
          />
          <IconButton
            icon={<Iconify icon="ic:round-delete" />}
            onClick={() => handleRemoveField(fieldValue?.id)}
          />
        </Stack>
      ))}
      <AddButton
        variant="outlined"
        content="Add Suggestion"
        onClick={handleAddField}
      />
    </StyledRoot>
  );
};

export default MultipleTextField;

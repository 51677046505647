import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MuiCircularProgress from "@mui/material/CircularProgress";

const StyledRoot = styled("div")(({ size }) => ({
  position: "relative",
  width: size,
  height: size,
  "&:after": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
    borderRadius: "50%",
    border: "4px solid #C9E1FD",
  },
}));
const StyledCircularProgress = styled(MuiCircularProgress)({
  position: "relative",
  zIndex: 1,
});
const StyledLabel = styled(Typography)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const CircularProgress = ({
  variant,
  value,
  size,
  color,
  thickness,
  label,
  ...props
}) => {
  return (
    <StyledRoot size={size}>
      <StyledCircularProgress
        variant={variant}
        value={value}
        size={size}
        color={color}
        thickness={thickness}
        {...props}
      />
      {label && (
        <StyledLabel fontSize={10} fontWeight={600} color={color}>
          {label}
        </StyledLabel>
      )}
    </StyledRoot>
  );
};

CircularProgress.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
};

CircularProgress.defaultProps = {
  variant: "determinate",
  color: "primary",
  size: 40,
};

export default CircularProgress;

import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OTPInput from "otp-input-react";
import { isEqual } from "lodash";
// @mui
import { Stack, TextField, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// components
import { appTypes } from "src/constants/constants";
import { AppStartupContext } from "src/context/AppStartup";
import loginService from "src/services/loginService";
import { loginUserAction } from "src/slices/Authentication/AuthSlice";
import useResponsive from "src/hooks/useResponsive";

// ----------------------------------------------------------------------
const StyledTextWrapper = styled("p")(({ theme }) => ({
  color: isEqual(process.env.REACT_APP_NAME, "sandbox") ? "#000000" : "#009E4D",
  fontFamily: "Helvetica",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  marginBottom: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: "26px",
  },
}));

const CssTextField = styled(TextField)(({ theme }) => ({
  width: "400px",
  "&.MuiTextField-root": {
    "& label": {
      top: "-5.5px",
      fontSize: 22,
      color: isEqual(process.env.REACT_APP_NAME, "sandbox")
        ? "#000000"
        : "#009E4D",
    },
    "& .MuiInput-root": {
      "& input": {
        height: 25,
        fontSize: 18,
      },
      "&:hover:not(.Mui-disabled, .Mui-error):before": {
        borderBottomColor: "#000000",
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#A9D0BC",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      "& label": {
        fontSize: 20,
      },
      "& .MuiInput-root": {
        "& input": {
          height: 22,
          fontSize: 16,
        },
      },
    },
    // Form Error CSS
    "&.has-error": {
      "& label": {
        color: "#FF0000",
      },
      "& .MuiInput-root": {
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottomColor: "#FF0000",
        },
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: "#FF0000",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#FF0000",
      },
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const CssLoadingButton = styled(LoadingButton)(({ theme }) => ({
  width: "max-content",
  borderRadius: "36px",
  boxShadow: "none",
  background: isEqual(process.env.REACT_APP_NAME, "sandbox")
    ? "#000000"
    : "#009E4D",
  padding: "12px 44px",
  "& span": {
    color: "#FFF",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  "&:hover": {
    background: isEqual(process.env.REACT_APP_NAME, "sandbox")
      ? "#000000"
      : "#009E4D",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 24px",
    marginTop: "48px!important",
    "& span": {
      fontSize: "19px",
    },
  },
}));

export default function EnterOtpForm() {
  const smDown = useResponsive("down", "sm");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appStartup = useContext(AppStartupContext);

  const [open, setOpen] = useState(true);
  const [disable, setDisable] = useState(false);
  const [msg, setMsg] = useState("OTP Sent Successfully.");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [otp, setOtp] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async () => {
    try {
      setDisable(true);
      if (otp && otp?.length === 4) {
        const auth = JSON.parse(localStorage.getItem("auth")) || {};
        const payload = { otp, email: auth?.email };
        const loginResponse = await dispatch(loginUserAction(payload));

        if (loginResponse?.error) throw new Error();

        const userDetails = loginResponse?.payload?.data ?? {};
        loginService.doLogin(userDetails?.token);
        localStorage.setItem("auth", JSON.stringify(userDetails));

        const userType = loginService.getparamsFromCookie("userType");

        if (userDetails.token) {
          setAlertSeverity("success");
          setMsg(`Welcome to ${userDetails?.name ?? ""}`);
          setOpen(true);

          const timeoutId = setTimeout(() => {
            const dashRoles = ["customerAdmin", "customeAdmin", "regionAdmin"];

            clearTimeout(timeoutId);

            if (isEqual(appStartup?.app, appTypes.sandbox)) {
              navigate("/sandbox/chat_type");
            }
            if (isEqual(userType, "searchUser")) {
              navigate("/search_user");
            }
            if (dashRoles.includes(userType)) {
              navigate("/dashboard", { replace: true });
            }
          }, 2000);
        } else {
          setDisable(false);
          setAlertSeverity("error");
          setMsg("Unsuccessful Login!");
          setOpen(true);
        }
        return;
      }
      setAlertSeverity("error");
      setMsg("Invalid Entered OTP !");
    } catch (err) {
      setAlertSeverity("error");
      setMsg("Invalid Entered OTP !");
    } finally {
      setDisable(false);
      setOpen(true);
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
      >
        <Alert severity={alertSeverity}>{msg}</Alert>
      </Snackbar>
      <div style={{ marginTop: "100px" }}>
        <Stack spacing={smDown ? 4 : 8}>
          <Stack>
            <StyledTextWrapper>Please Enter OTP</StyledTextWrapper>
            <Typography style={{ whiteSpace: "pre-line" }}>
              {`Your One Time Password(OTP) has been sent
                via mail to your registered email address.`}
            </Typography>
          </Stack>

          <Stack spacing={8}>
            <Stack spacing={4}>
              <OTPInput
                otpType="number"
                secure
                autoFocus
                OTPLength={4}
                value={otp}
                disabled={false}
                onChange={setOtp}
              />
            </Stack>
            <CssLoadingButton
              id="login-btn"
              disabled={disable}
              size={smDown ? "small" : "default"}
              type="submit"
              variant="contained"
              onClick={handleClick}
            >
              <span>Verify</span>
            </CssLoadingButton>
          </Stack>
        </Stack>
      </div>
    </>
  );
}

import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";

import Iconify from "../../components/iconify";

import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledLeftSection = styled("div")(({ theme }) => ({
  width: "50%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  // boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.paper,
  padding: "48px 0 48px 36px",
  "& img": {
    height: "100%",
    objectFit: "contain",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  margin: "0",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  padding: theme.spacing(8, 0, 8, 16),
  [theme.breakpoints.down("lg")]: {
    alignItems: "center",
    padding: theme.spacing(8, 4),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(6, 3),
  },
}));

const HeadlineText = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: '"Helvetica-Regular", Helvetica',
  color: "#000000",
  fontSize: "30px",
  fontWeight: 400,
  [theme.breakpoints.down("lg")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "19px",
  },
}));

const StyledTextWrapper = styled("p")(({ theme }) => ({
  color: "#000000",
  fontFamily: "Helvetica",
  fontSize: "40px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "36px",
  },
}));

const MainLogo = styled("img")(({ theme }) => ({
  height: "41px",
  width: "42px",
  objectFit: "cover",
  marginRight: "20px",
  [theme.breakpoints.down("sm")]: {
    height: "32px",
    width: "35px",
    marginRight: "12px",
  },
}));

const TextWrapper = styled("p")(({ theme }) => ({
  margin: 0,
  letterSpacing: 0,
  lineHeight: "normal",
  whiteSpace: "nowrap",
  fontFamily: '"Helvetica-Regular", Helvetica',
}));

const TextWrapperBold = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontFamily: '"Helvetica-Bold", Helvetica',
}));

const ContinueButton = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  color: "#000",
  padding: "0 112px",
  height: "57px",
  justifyContent: "flex-start",
  [theme.breakpoints.down("lg")]: {
    padding: "0 24px",
    justifyContent: "center",
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  color: "#ffffff",
  padding: "0 112px",
  backgroundColor: "#000000",
  "&:hover": {
    backgroundColor: "#000000",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0 24px",
    width: "100%",
  },
}));

const FootlineText = styled("p")(({ theme }) => ({
  fontFamily: '"Helvetica-Regular", Helvetica',
  color: "#000000",
  fontSize: "22px",
  fontWeight: 400,
  margin: 0,
  [theme.breakpoints.down("lg")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "17px",
  },
  "& span": {
    color: "#009E4D",
    fontWeight: 600,
    marginLeft: "8px",
    cursor: "pointer",
  },
}));
// ----------------------------------------------------------------------

export default function Onboarding() {
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);

  const navigateToSignup = () => {
    navigate("/signup");
  };

  const handleRedirectToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <Helmet>
        <title> Login | Argus </title>
      </Helmet>
      <StyledRoot>
        {mdUp && (
          <StyledLeftSection>
            <img src="/assets/illustrations/login-sidebar-bg.png" alt="login" />
          </StyledLeftSection>
        )}
        <StyledContent>
          <HeadlineText>
            <MainLogo src="/assets/logo.png" alt="argus logo" />
            <TextWrapper>
              {"ArgusQuery "}
              <TextWrapperBold>Sandbox</TextWrapperBold>
            </TextWrapper>
          </HeadlineText>
          <Stack direction="column" spacing={2} width={mdUp ? "auto" : "100%"}>
            <StyledTextWrapper>Log in</StyledTextWrapper>
            <ContinueButton
              size="large"
              color="inherit"
              variant="outlined"
              startIcon={
                <Iconify
                  icon="devicon:google"
                  color="#DF3E30"
                  width={22}
                  height={22}
                />
              }
            >
              Continue with Google
            </ContinueButton>

            <ContinueButton
              size="large"
              color="inherit"
              variant="outlined"
              startIcon={
                <Iconify
                  icon="logos:facebook"
                  color="#1877F2"
                  width={22}
                  height={22}
                />
              }
            >
              Continue with Facebook
            </ContinueButton>
            <ContinueButton
              size="large"
              color="inherit"
              variant="outlined"
              startIcon={
                <Iconify
                  icon="logos:google-gmail"
                  color="#1C9CEA"
                  width={22}
                  height={22}
                />
              }
            >
              Continue with email
            </ContinueButton>
          </Stack>
          <LoginButton
            size="large"
            variant="contained"
            disableElevation={true}
            onClick={handleRedirectToLogin}
          >
            Login
          </LoginButton>
          <FootlineText>
            Don't have an account yet?
            <span onClick={navigateToSignup}>Sign up.</span>
          </FootlineText>
        </StyledContent>
      </StyledRoot>
    </>
  );
}

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import {
  useRegionsSelector,
  useCategorySelector,
  useApplicationSelector,
  useUserSelector,
  fetchAllRegionsAction,
  fetchAllCategoryAction,
  fetchAllApplicationAction,
  fetchAllUsersAction,
  resetRegions,
  resetApplication,
  resetCategory,
  resetUsers,
} from "src/pages/dashboard/slices";
import { fDateTime } from "../../../../utils/formatTime";

import Chip from "src/components/elements/Chip";
import Table from "src/pages/dashboard/components/Table";
import Tooltip from "src/components/elements/Tooltip";
import IconButton from "src/components/elements/IconButton";
import Iconify from "src/components/iconify";

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  gap: 8,
  overflow: "scroll",
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

const WrappedText = styled(Typography)({
  maxWidth: 180,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});

const StyledChip = styled(Chip)({
  "&.superAdmin": {
    color: "#ffffff",
    backgroundColor: "#4169E1",
  },
  "&.customerAdmin": {},
  "&.regionAdmin": {},
  "&.searchUser": {
    color: "#8353E2",
    backgroundColor: "#F5F2FD",
  },
});

const Card = styled("div")({
  flex: 1,
  borderRadius: 8,
  overflowX: "scroll",
  boxShadow:
    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
  "& .table-container": {
    height: "100%",
  },
});

const CardTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: "#005544",
});

const ViewLink = styled(Typography)({
  fontSize: 12,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: "#2065D1",
  cursor: "pointer",
});

const StyledCardContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: 24,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Analytics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const region = useRegionsSelector();
  const category = useCategorySelector();
  const application = useApplicationSelector();
  const users = useUserSelector();

  useEffect(() => {
    dispatch(fetchAllRegionsAction({ page: 0, size: 3 }));
    dispatch(fetchAllCategoryAction({ page: 0, size: 3 }));
    dispatch(fetchAllApplicationAction({ page: 0, size: 3 }));
    dispatch(fetchAllUsersAction({ page: 0, size: 3 }));

    return () => {
      dispatch(resetRegions());
      dispatch(resetCategory());
      dispatch(resetApplication());
      dispatch(resetUsers());
    };
  }, []);

  const renderProductCategoryLink = (productCategory) =>
    productCategory?.length > 0 && (
      <Tooltip
        title={productCategory?.map((item) => item?.categoryName)?.join(" , ")}
        placement="left-start"
      >
        <WrappedText>
          {productCategory?.map((item) => item?.categoryName)?.join(" , ")}
        </WrappedText>
      </Tooltip>
    );

  const renderRegions = (regions) => (
    <Tooltip
      title={regions?.map((item) => item?.region)?.join(" , ")}
      placement="left-start"
    >
      <WrappedText>
        {regions?.map((item) => item?.region)?.join(" , ")}
      </WrappedText>
    </Tooltip>
  );

  const renderApplication = (module) => module?.module_name;

  const renderUserType = (value) =>
    ({
      SU: <StyledChip className="searchUser" label="Search User" />,
      CA: <StyledChip className="customerAdmin" label="Customer Admin" />,
      RA: <StyledChip className="regionAdmin" label="Region Admin" />,
      SA: <StyledChip className="superAdmin" label="Super Admin" />,
    }[value?.role_key]);

  const renderSequencedTableData = (data) =>
    data?.map((item, index) => ({
      ...item,
      sno: index < 10 ? `0${index + 1}` : index,
    }));

  const regionTableColumn = [
    { id: "sno", label: "Sr. No.", sort: true, width: 100 },
    {
      id: "region",
      label: "Teams",
      value: (value) => <Tooltip title={value}>{value}</Tooltip>,
      sort: true,
      width: 100,
    },
    {
      id: "isActive",
      label: "Status",
      value: (value) => (value ? "Active" : "Inactive"),
      width: 100,
    },
    {
      id: "createdAt",
      label: "Created At",
      sort: true,
      value: (value) => fDateTime(value),
      width: 100,
    },
  ];

  const productCategoryTableColumn = [
    {
      id: "sno",
      label: "Sr. No.",
      sort: true,
      width: 100,
    },
    {
      id: "categoryName",
      label: "File Category",
      sort: true,
      width: 100,
    },
    { id: "Region", label: "Teams", width: 100, value: renderRegions },
  ];

  const applicationConfigTableColumn = [
    { id: "sno", label: "Sr. No.", sort: true, width: 100 },
    { id: "name", label: "Name", sort: true, width: 100 },
    {
      id: "ModuleConfig",
      label: "Module Name",
      sort: true,
      width: 100,
      value: renderApplication,
    },
    {
      id: "ProductCategory",
      label: "File Category",
      sort: true,
      width: 100,
      value: renderProductCategoryLink,
    },
    { id: "Region", label: "Teams", value: renderRegions },
    {
      id: "createdAt",
      label: "Created At",
      sort: true,
      width: 100,
      value: (value) => fDateTime(value),
    },
  ];

  const userTableColumns = [
    { id: "sno", label: "Sr. No.", sort: true, width: 100 },
    { id: "name", label: "Name", sort: true, width: 100 },
    { id: "Region", label: "Teams", width: 100, value: renderRegions },
    { id: "mobile", label: "Phone no", width: 100 },
    { id: "email", label: "Email Address", sort: true, width: 100 },
    { id: "Role", label: "Role", width: 100, value: renderUserType },
    {
      id: "createdAt",
      label: "Created At",
      sort: true,
      width: 100,
      value: (value) => fDateTime(value),
    },
    {
      id: "updatedAt",
      label: "Updated At",
      sort: true,
      width: 100,
      value: (value) => fDateTime(value),
    },
  ];

  return (
    <StyledRoot>
      <Stack flexDirection="row" alignItems="center">
        <Typography fontSize={32} fontWeight={600} color="#0000008a">
          Dashboard
        </Typography>
        <Tooltip
          arrow={true}
          placement="right-start"
          title="See all configured Teams, File Categories, and Users at a glance."
        >
          <IconButton
            icon={
              <Iconify icon="fluent:info-24-filled" width={16} color="grey" />
            }
          />
        </Tooltip>
      </Stack>
      <Stack flexDirection="column" gap={2} mt={2}>
        <StyledCardContainer flexDirection="row" alignItems="stretch" gap={2}>
          <Card>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <CardTitle>TEAMS</CardTitle>
              <ViewLink onClick={() => navigate("/dashboard/region")}>
                View All
              </ViewLink>
            </Stack>
            <Table
              loading={isEqual(region?.status, "loading")}
              data={renderSequencedTableData(region?.data ?? [])}
              columns={regionTableColumn}
            />
          </Card>
          <Card>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <CardTitle>FILE CATEGORIES</CardTitle>
              <ViewLink onClick={() => navigate("/dashboard/product-category")}>
                View All
              </ViewLink>
            </Stack>
            <Table
              loading={isEqual(category?.status, "loading")}
              data={renderSequencedTableData(category?.data ?? [])}
              columns={productCategoryTableColumn}
            />
          </Card>
        </StyledCardContainer>
        <StyledCardContainer flexDirection="row" alignItems="stretch" gap={2}>
          <Card>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <CardTitle>APPS</CardTitle>
              <ViewLink
                onClick={() => navigate("/dashboard/application-config")}
              >
                View All
              </ViewLink>
            </Stack>
            <Table
              loading={isEqual(application?.status, "loading")}
              data={renderSequencedTableData(application?.data ?? [])}
              columns={applicationConfigTableColumn}
            />
          </Card>
          <Card>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <CardTitle>USERS</CardTitle>
              <ViewLink onClick={() => navigate("/dashboard/users")}>
                View All
              </ViewLink>
            </Stack>
            <Table
              loading={isEqual(users?.status, "loading")}
              data={renderSequencedTableData(users?.data ?? [])}
              columns={userTableColumns}
            />
          </Card>
        </StyledCardContainer>
      </Stack>
    </StyledRoot>
  );
};

export default Analytics;

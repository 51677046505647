import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Stack from "@mui/material/Stack";
// @mui
import useResponsive from "src/hooks/useResponsive";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
//helpers
import {
  fetchAllResourcesAction,
  useSnackbarSelector,
  resetSnackbar,
} from "../slices";
// components
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import Instructions from "../components/Instructions";

const OutletContainer = styled("div")({
  width: "100%",
  height: "100%",
  paddingLeft: 0,
  paddingTop: 56,
  backgroundColor: "#ffffff",
});

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  height: "100%",
  overflow: "hidden",
  backgroundColor: theme?.palette?.colorTheme?.theme4?.primaryBackground,
}));

const StyledCardContainer = styled(Stack)(({ theme, open }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "block",
    overflowY: "scroll",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 270px)",
    },
  }),
}));

const Dashboard = () => {
  const mdUp = useResponsive("up", "lg");
  const [openNav, setOpenNav] = useState(mdUp ? true : false);
  const dispatch = useDispatch();
  const snackbar = useSnackbarSelector();

  const [openInstructions, setOpenInstructions] = useState(false);

  useEffect(() => {
    handleCloseSnackBar();
    dispatch(fetchAllResourcesAction());
  }, []);

  const handleCloseSnackBar = () => dispatch(resetSnackbar());

  return (
    <>
      <Helmet>
        <title> Dashboard | Argus</title>
      </Helmet>
      <SideNav open={openNav} onClose={() => setOpenNav(false)} />
      <Header
        openNav={openNav}
        onToggleNav={() => setOpenNav(!openNav)}
        setOpenInstructions={setOpenInstructions}
      />
      <Container>
        <StyledCardContainer open={openNav}>
          <OutletContainer>
            {openInstructions ? (
              <Instructions setOpenInstructions={setOpenInstructions} />
            ) : (
              <Outlet />
            )}
          </OutletContainer>
        </StyledCardContainer>
      </Container>
      <Snackbar
        open={!isEmpty(snackbar?.message)}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity={snackbar?.type ?? ""}
          sx={{ width: "100%" }}
        >
          {snackbar?.message ?? ""}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Dashboard;

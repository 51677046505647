import { useState } from "react";
//@mui
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import dashboardService from "src/services/dashboard";

import Iconify from "src/components/iconify";
import Button from "src/components/elements/Button";
import TextField from "src/components/elements/Textfield";
import IconButton from "src/components/elements/IconButton";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 24,
  paddingTop: 32,
  borderRadius: 12,
  backgroundColor: "#ffffff",
  transform: "translate(-50%, -50%)",
  border: "none",
}));

const ChangePasswordPopup = ({ open, user, onClose, onConfirm }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOnChange = (event) => {
    const { name, value } = event?.target ?? {};

    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const handleOnSubmit = () => {
    if (password !== confirmPassword || !password || !confirmPassword) return;

    const payload = { userId: user?.id, password };

    dashboardService.updatePassword(payload).then((response) => {
      if (response?.status === 201) onClose();
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledContainer>
        <Typography fontSize={14} fontWeight={600} mb={1}>
          Change Password ?
        </Typography>
        <Typography fontSize={12}>
          {`Are you sure you want to change the password of account hold by user ${
            user?.name ?? ""
          } ?`}
        </Typography>
        <Stack flexDirection="column" gap={1} mb={2} mt={3}>
          <Typography fontSize={12}>New Password</Typography>
          <TextField
            size="small"
            name="password"
            placeholder="Enter new password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    icon={
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack flexDirection="column" gap={1} mb={2}>
          <Typography fontSize={12}>Confirm Password</Typography>
          <TextField
            size="small"
            name="confirmPassword"
            placeholder="Enter confirm password"
            type="password"
            value={confirmPassword}
            onChange={handleOnChange}
          />
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          pt={3}
        >
          <Button variant="outlined" content="Cancel" onClick={onClose} />
          <Button
            content="Confirm"
            disabled={!password || !confirmPassword}
            onClick={handleOnSubmit}
          />
        </Stack>
      </StyledContainer>
    </Modal>
  );
};

export default ChangePasswordPopup;

import Axios from "../helper/axiosHelper";
import { BASEURL } from "src/constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class DashboardService {
  getRole() {
    const options = {
      url: `${baseUrl}/admin/roles`,
    };

    return axios.get(options);
  }

  getRegion(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );

    const options = {
      url: `${baseUrl}/admin/regions?${queryString}`,
    };

    return axios.get(options);
  }

  createRegion(payload) {
    const options = {
      url: `${baseUrl}/admin/regions`,
      data: payload,
    };

    return axios.post(options);
  }

  updateRegion(payload) {
    const options = {
      url: `${baseUrl}/admin/regions`,
      data: payload,
    };

    return axios.put(options);
  }

  getCategory(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );

    const options = {
      url: `${baseUrl}/admin/categories?${queryString}`,
    };

    return axios.get(options);
  }

  createCategory(payload) {
    const options = {
      url: `${baseUrl}/admin/category`,
      data: payload,
    };

    return axios.post(options);
  }

  updateCategory(payload) {
    const options = {
      url: `${baseUrl}/admin/category`,
      data: payload,
    };

    return axios.put(options);
  }

  getModules(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );

    const options = {
      url: `${baseUrl}/admin/modules?${queryString}`,
    };

    return axios.get(options);
  }

  getModuleDetails(moduleId) {
    const options = {
      url: `${baseUrl}/admin/module/${moduleId}`,
    };

    return axios.get(options);
  }

  createModule(payload) {
    const options = {
      url: `${baseUrl}/admin/module`,
      data: payload,
    };

    return axios.post(options);
  }

  updateModule(payload) {
    const options = {
      url: `${baseUrl}/admin/module`,
      data: payload,
    };

    return axios.put(options);
  }

  getApplication(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );

    const options = {
      url: `${baseUrl}/admin/application?${queryString}`,
    };

    return axios.get(options);
  }

  createApplication(payload) {
    const options = {
      url: `${baseUrl}/admin/application`,
      data: payload,
    };

    return axios.post(options);
  }

  updateApplication(payload) {
    const options = {
      url: `${baseUrl}/admin/application`,
      data: payload,
    };

    return axios.put(options);
  }

  getCategories() {
    const options = {
      url: `${baseUrl}/admin/category`,
    };

    return axios.get(options);
  }

  getFiles(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );
    const options = {
      url: `${baseUrl}/admin/files?${queryString}`,
    };

    return axios.get(options);
  }

  deleteFile(fileId, embeddings) {
    const options = {
      url: `${baseUrl}/admin/file/${fileId}?embeddings=${embeddings}`,
    };

    return axios.delete(options);
  }

  uploadFile({ formData, onUploadProgress }) {
    const options = {
      url: `${baseUrl}/admin/upload/file`,
      formData,
      onUploadProgress,
    };

    return axios.upload(options);
  }

  processFile(id) {
    const options = {
      url: `${baseUrl}/admin/file/process/${id}`,
    };

    return axios.put(options);
  }

  uploadFiles(formData) {
    const options = {
      url: `${baseUrl}/admin/fileUploads`,
      formData,
    };

    return axios.upload(options);
  }

  getReadings(data) {
    const option = {
      url: `${baseUrl}/readings`,
      data: data,
    };

    return axios.post(option);
  }

  getRegisterReadings(data) {
    const option = {
      url: `${baseUrl}/register-readings`,
      data: data,
    };
    return axios.post(option);
  }

  getConfiguration() {
    const option = {
      url: `${baseUrl}/configuration`,
    };

    return axios.get(option);
  }

  getGraphData(registerId) {
    const option = {
      url: `${baseUrl}/register-graph`,
      data: { registerId },
    };

    return axios.post(option);
  }

  updatePassword(payload) {
    const option = {
      url: `${baseUrl}/admin/change-password`,
      data: payload,
    };

    return axios.put(option);
  }

  getFileLogs(productCategoryId) {
    const option = {
      url: `${baseUrl}/admin/file-logs?categoryId=${productCategoryId}`,
    };

    return axios.get(option);
  }

  getFileUrl(docId, segment = "") {
    const option = {
      url: `${baseUrl}/admin/get-file-url?docId=${docId}&segment=${segment}`,
    };

    return axios.get(option);
  }

  getTemplates(params = {}) {
    const queryString = Object.keys(params)?.reduce(
      (agg, cur, index) =>
        `${agg}${cur}=${params[cur]}${
          index < Object.keys(params)?.length - 1 ? "&" : ""
        }`,
      ""
    );
    const option = {
      url: `${baseUrl}/admin/templates?${queryString}`,
    };

    return axios.get(option);
  }

  generateSalesPitch(data) {
    const option = {
      url: `${baseUrl}/generate-sales-pitch`,
      data,
    };

    return axios.post(option);
  }

  getTemplateHistory(applicationId) {
    const option = {
      url: `${baseUrl}/template-history?applicationId=${applicationId}`,
    };

    return axios.get(option);
  }
}

const dashboardService = new DashboardService();
export default dashboardService;

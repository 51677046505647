import Cookies from "universal-cookie";
import { isEmpty } from "lodash";

const cookies = new Cookies();

/**
 * Extract the subdomain from the URL. Returns false if not found.
 * @return {Boolean | String} Extracted subdomain, otherwise false
 */
export const extractWhitelabelSubdomain = () => {
  // Get hostname from the targetHostName set in window._customProps at the document level, fallback to hostname attached to the location object of the document
  const hostname = cookies.get("targetHostName") || window.location.hostname;
  if (isEmpty(hostname)) {
    return false;
  }

  const subDomain = hostname?.split(".")[0];

  return subDomain || false;
};

export const convertFullNameToAbbrevate = (name = "", maxLength = 20) => {
  if (name?.length > maxLength) {
    const nameArr = name?.split(".");
    const fileExtension = nameArr?.[nameArr?.length - 1] ?? "";
    const fileName = nameArr?.splice(0, nameArr?.length - 1)?.join(".") ?? "";
    const abbFileName = `${fileName?.slice(
      0,
      maxLength - 5
    )}....${fileName?.slice(fileName?.length - 4, fileName?.length)}`;
    return `${abbFileName}.${fileExtension}`;
  } else {
    return name;
  }
};

/**
 *
 * @param {number} bytes
 * @returns
 */
export const convertKiloBytes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + " " + sizes[i];
};

/**
 *
 * @param {string} queryParam
 * @returns
 */
export const getUrlQueryParams = (queryParam) => {
  const queryParams =
    new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams?.get(prop),
    }) || {};

  return queryParams?.[queryParam] ?? "";
};
